/* eslint-disable @typescript-eslint/no-use-before-define */
import moment from "moment-timezone";
import {
  all,
  call,
  fork,
  put,
  takeEvery,
  select,
  take,
} from "redux-saga/effects";
import { io } from "socket.io-client";
import { eventChannel } from "redux-saga";
import { TumekeJSModule } from "@kernel";
import { ProcessingStatus } from "@kernel-constants/maps";
import { NotificationManager } from "@kernel-helpers/react-notifications";
import {
  getAssessmentFromVideo,
  getNumPeople,
} from "@kernel-helpers/filters/VideoFilters";
import { getCompareObject, asyncGetIdToken } from "@tumeke/tumekejs";
import * as db from "@tumeke/tumekejs/lib/utils/Database";

import {
  updateAdditionalInfoFirebaseHelper,
  updateRiskComponentsFirebaseHelper,
  getVideoJsonsLegacy,
  getVideoJsons,
  deleteVideosFirebaseHelper,
  addMetadataOptionHelper,
  deleteMetadataOptionHelper,
  editMetadataOptionHelper,
  setVideoMetadataFirebaseHelper,
  getThumbnail,
  generateReport,
  getAllUserVideos,
  addFeedback,
  getAssessmentOverTime,
  getSingleVideoDoc,
  rerunAssessmentFull,
  updateVideoNotes,
  getPostureThumbnail,
  updateVideoName,
  updateAssessmentName,
  downloadVideoRequest,
  getJobJointData,
  getExternalJobJointData,
  externalGetVideoDoc,
  downloadRiskJointsCsvRequest,
  getLinksData,
  updateLinkHelper,
  deleteLinkHelper,
  sendLinkHelper,
  externalGenerateGPTRecommendations,
} from "@kernel-helpers/DatabaseHelpers";

import { Config } from "@kernel-config";
import { Mixpanel } from "@kernel-helpers/Mixpanel";
import { ReduxState } from "@kernel-store/reducers";
import {
  GET_VIDEO_LIST_REQUEST,
  GET_VIDEO_SUCCESS,
  UPLOAD_RISK_COMPONENTS,
  UPLOAD_ADDITIONAL_INFO,
  GET_JOINT_DATA,
  GET_JOINT_DATA_LEGACY,
  DELETE_VIDEOS_REQUEST,
  ADD_METADATA_OPTION,
  DELETE_METADATA_OPTION,
  EDIT_METADATA_OPTION,
  SET_VIDEO_METADATA,
  GET_ALL_METADATA_FIELDS,
  ADD_SINGLE_VIDEO_LISTENER,
  REMOVE_VIDEO_LISTENERS,
  GENERATE_REPORT_REQUEST,
  GENERATE_REPORT_ERROR,
  GENERATE_CSV_REQUEST,
  GENERATE_CSV_ERROR,
  EXTERNAL_GENERATE_GPT_RECOMMENDATIONS_REQUEST,
  GET_THUMBNAIL_REQUEST,
  GET_THUMBNAILS_REQUEST,
  SUBMIT_VIDEO_FEEDBACK,
  GET_SINGLE_VIDEO_DOC_REQUEST,
  COMPARE_VIDEOS_REQUEST,
  COMPARE_VIDEOS_ERROR,
  RERUN_VIDEO_REQUEST,
  SAVE_VIDEO_NOTES_REQUEST,
  UPDATE_VIDEO_NAME,
  UPDATE_ASSESSMENT_NAME,
  DOWNLOAD_VIDEO_REQUEST,
  GET_VIDEO_DOC_EXTERNAL,
  DOWNLOAD_RISK_JOINTS_CSV_REQUEST,
  GET_LINKS,
  UPDATE_LINK,
  DELETE_LINK,
  SEND_LINK,
  GET_VIDEO_JOINT_URLS,
} from "@kernel-store/actions";

import { processFilterObject } from "@kernel-store/dashboard/saga";
import {
  getVideoListRequest,
  addNewVideo,
  allVideosLoaded,
  loadingVideos,
  getJointDataSuccess,
  getAllMetadataFieldsSuccess,
  addSingleVideoListener,
  deleteVideosSuccess,
  generateReportError,
  generateReportSuccess,
  generateCSVError,
  generateCSVSuccess,
  externalGenerateGPTRecommendationsSuccess,
  externalGenerateGPTRecommendationsError,
  getThumbnailRequest,
  getThumbnailSuccess,
  setVideoMetadataSuccess,
  compareVideosSuccess,
  saveVideoNotesSuccess,
  downloadVideoError,
  downloadVideoSuccess,
  getThumbnailsRequest,
  setWebsocketFailed,
  addNewSkinnyVideo,
  setAllInvisible,
  setVideosVisibility,
  downloadRiskJointsCsvError,
  downloadRiskJointsCsvSuccess,
  getLinksSuccess,
  addVideoJointUrls,
} from "./actions";

import { getImprovementThumbnailSuccess } from "../improvements/actions";

import {
  setCompanyMetadataSuccess,
  setDecryptedAESKeysSuccess,
} from "../auth/actions";

import { setWebAppLogo } from "../settings/actions";

export function* watchGetVideoListRequest() {
  yield takeEvery(GET_VIDEO_LIST_REQUEST, getVideoListSaga);
}

export function* watchGetJointDataLegacyRequest() {
  yield takeEvery(GET_JOINT_DATA_LEGACY, getJointDataLegacySaga);
}

export function* watchGetJointDataRequest() {
  yield takeEvery(GET_JOINT_DATA, getJointDataSaga);
}

export function* watchUploadInputsRequest() {
  yield takeEvery(UPLOAD_ADDITIONAL_INFO, updateAdditionalInputsSaga);
}

export function* watchUploadRiskComponentsRequest() {
  yield takeEvery(UPLOAD_RISK_COMPONENTS, updateRiskComponentsSaga);
}

export function* watchGenerateReportError() {
  yield takeEvery(GENERATE_REPORT_ERROR, generateReportErrorSaga);
}

export function* watchGenerateCSVError() {
  yield takeEvery(GENERATE_CSV_ERROR, generateCSVErrorSaga);
}

export function* watchGenerateReportRequest() {
  yield takeEvery(GENERATE_REPORT_REQUEST, generateReportSaga);
}

export function* watchGenerateCSVRequest() {
  yield takeEvery(GENERATE_CSV_REQUEST, generateCSVSaga);
}

export function* watchExternalGenerateGptRecommendationsRequest() {
  yield takeEvery(
    EXTERNAL_GENERATE_GPT_RECOMMENDATIONS_REQUEST,
    externalGenerateGptRecommendationsSaga,
  );
}

export function* watchDownloadVideoRequest() {
  yield takeEvery(DOWNLOAD_VIDEO_REQUEST, downloadVideoSaga);
}

export function* watchDownloadRiskJointsCsvRequest() {
  yield takeEvery(DOWNLOAD_RISK_JOINTS_CSV_REQUEST, downloadRiskJointsCsvSaga);
}

export function* watchDeleteVideoRequest() {
  yield takeEvery(DELETE_VIDEOS_REQUEST, deleteVideoSaga);
}

export function* watchAddMetadataOptionRequest() {
  yield takeEvery(ADD_METADATA_OPTION, addMetadataOptionSaga);
}

export function* watchGetExternalVideoDoc() {
  yield takeEvery(GET_VIDEO_DOC_EXTERNAL, getExternalVideoDocSaga);
}

export function* watchDeleteMetadataOptionRequest() {
  yield takeEvery(DELETE_METADATA_OPTION, deleteMetadataOptionSaga);
}

export function* watcheditMetadataOptionRequest() {
  yield takeEvery(EDIT_METADATA_OPTION, editMetadataOptionSaga);
}

export function* watchsetVideoMetadataRequest() {
  yield takeEvery(SET_VIDEO_METADATA, setVideoMetadataSaga);
}

export function* watchGetThumbnailRequest() {
  yield takeEvery(GET_THUMBNAIL_REQUEST, getThumbnailRequestSaga);
}

export function* watchGetThumbnailsRequest() {
  yield takeEvery(GET_THUMBNAILS_REQUEST, getThumbnailsRequestSaga);
}

export function* watchAddSingleVideoListener() {
  yield takeEvery(ADD_SINGLE_VIDEO_LISTENER, singleVideoListenerSaga);
}

export function* watchgetAllMetadataFieldsRequest() {
  yield takeEvery(GET_ALL_METADATA_FIELDS, getAllMetadataFieldsSaga);
}

export function* watchSubmitVideoFeedbackRequest() {
  yield takeEvery(SUBMIT_VIDEO_FEEDBACK, getSubmitVideoFeedbackRequestSaga);
}

export function* watchGetSingleVideoRequest() {
  yield takeEvery(GET_SINGLE_VIDEO_DOC_REQUEST, getSingleVideoSaga);
}

export function* watchCompareVideosRequest() {
  yield takeEvery(COMPARE_VIDEOS_REQUEST, compareVideosRequestSaga);
}

export function* watchCompareVideosError() {
  yield takeEvery(COMPARE_VIDEOS_ERROR, compareVideosErrorSaga);
}

export function* watchRerunVideoRequest() {
  yield takeEvery(RERUN_VIDEO_REQUEST, rerunVideoRequestSaga);
}

export function* watchSaveVideoNotesRequest() {
  yield takeEvery(SAVE_VIDEO_NOTES_REQUEST, saveVideoNotesSaga);
}

export function* watchAddNewVideo() {
  yield takeEvery(GET_VIDEO_SUCCESS, getVideoSuccessSaga);
}

export function* watchUpdateVideoName() {
  yield takeEvery(UPDATE_VIDEO_NAME, updateVideoNameSaga);
}

export function* watchUpdateAssessmentName() {
  yield takeEvery(UPDATE_ASSESSMENT_NAME, updateAssessmentNameSaga);
}

export function* watchGetVideoJointUrls() {
  yield takeEvery(GET_VIDEO_JOINT_URLS, getVideoJointUrlsSaga);
}

export function* watchGetLinks() {
  yield takeEvery(GET_LINKS, getLinks);
}

export function* watchUpdateLink() {
  yield takeEvery(UPDATE_LINK, updateLink);
}

export function* watchDeleteLink() {
  yield takeEvery(DELETE_LINK, deleteLink);
}

export function* watchSendLink() {
  yield takeEvery(SEND_LINK, sendLink);
}

const handleVideoError = (e: any) => {
  if (e.response) {
    // TODO Change this
    NotificationManager.warning(
      "Error",
      e.response.data.message,
      3000,
      null,
      null,
      "",
    );
    return;
  }
  NotificationManager.warning(
    "Error",
    "Unknown server error. Please try again",
    3000,
    null,
    null,
    "",
  );
};

class Poller {
  videoId: string;

  intervalCode: ReturnType<typeof setInterval> | undefined;

  ondata: any;

  constructor(videoId: string) {
    this.videoId = videoId;
  }

  start() {
    this.intervalCode = setInterval(async () => {
      const data = await db.getVideoDoc(this.videoId);
      this.ondata(data);
      return data;
    }, 2000);
  }

  close() {
    console.log("CLOSED CHANNEL");
    clearInterval(this.intervalCode);
  }
}
const allRegisteredListeners: any = {};

/*
const getUserListAsync = async (companyId, group_id) => {
  // todo company id not needed
  const id = undefined;
  const group = await getGroup(id);
  return group.users;
};
*/

const registerNewVideoListener = (videoId: string, isExternal?: boolean) =>
  eventChannel((emit) => {
    const handleNewVideo = async (snapshotDoc: any) => {
      console.log(`[Listener] handling new video: ${snapshotDoc.id}`);
      const videoData = {
        ...snapshotDoc.data,
        tasks: snapshotDoc.tasks,
        metadata: snapshotDoc.metadata,
        user_id: snapshotDoc.user_id,
        key: snapshotDoc.id,
        id: snapshotDoc.id,
        visible: true,
      };
      if (videoData === undefined) {
        return;
      }

      emit({
        action: "ADD",
        data: {
          ...videoData,
          key: snapshotDoc.id,
          visible: true,
        },
      });
    };

    const channel = io(`${Config.TUMEKE_SERVER_WEBSOCKET_API}/videos`);

    channel.on("connect", async () => {
      if (isExternal) {
        channel.emit("externalSubscribeToVideoDoc", {
          shortKey: videoId,
        });
      } else {
        const authToken: string = await asyncGetIdToken("web");
        channel.emit("subscribeToVideoDoc", {
          vId: videoId,
          cognitoAuthToken: authToken,
        });
      }
    });
    channel.on("disconnect", () => {
      console.log("Video channel closed");
    });

    const poller = new Poller(videoId);

    poller.ondata = (resp: any) => {
      handleNewVideo(resp);
    };

    let usingPoller = false;

    channel.on("videoDocToClient", (response: any) => {
      if (response?.errorCode === "RECOMPUTE_FAILED" && response?.requestId) {
        const requestId = TumekeJSModule.getSession("videoRequestId");
        if (requestId === response?.requestId) {
          NotificationManager.error(
            "Failed to recompute the video. Try with other parameters",
            "Error",
            5000,
            null,
            null,
            "",
          );
        }
      }
      const processedVideoDoc = {
        ...response.data.data,
        ...response.data,
        key: response.data.id,
      };
      handleNewVideo(processedVideoDoc);
    });

    channel.on("errorToClient", (data: any) => {
      if (data.message === "Bad auth") {
        channel.close();
        return;
      }
      emit({ action: "ERROR", data: {} });
      usingPoller = true;
      poller.start();
    });

    channel.on("error", () => {
      emit({ action: "ERROR", data: {} });
      usingPoller = true;
      poller.start();
    });

    const unsubscribe = () => {
      console.log("Video channel closed");
      delete allRegisteredListeners[videoId];
      if (usingPoller) {
        poller.close();
      } else {
        channel.close();
      }
    };

    return unsubscribe;
  });

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
function* getJointDataLegacySaga({ payload }: any) {
  const { videoId, chunk }: { videoId: string; chunk: number } = payload;
  const state: ReduxState = yield select();
  const { uid }: { uid: string } = state.videos.videoList[videoId];
  const { personId }: { personId: number } = state.videos;
  let data = null;
  try {
    data = (yield call(
      getVideoJsonsLegacy,
      uid,
      videoId,
      personId,
      state.videos.videoList[videoId].jointMetadata,
      chunk,
    )) as unknown as any;
  } catch (e) {
    if (
      state.videos.videoList[videoId].videoLoc !== undefined &&
      state.videos.videoList[videoId].videoLoc !== ""
    ) {
      NotificationManager.warning(
        "Warning",
        "Joint data not avail. for this video",
        3000,
        null,
        null,
        "",
      );
    }

    return;
  }

  yield put(getJointDataSuccess(videoId, data, chunk));
}

function* getJointDataSaga({ payload }: any) {
  const {
    videoId,
    assessmentId,
    clipId,
    chunk,
  }: {
    videoId: string;
    assessmentId: string;
    clipId: number;
    chunk: number;
  } = payload;
  const state: ReduxState = yield select();
  const { personId }: { personId: number } = state.videos;
  let data = null;
  try {
    let { url } = state.videos.videoList[videoId].jointUrls;
    if (videoId === "example" && window && window.location) {
      url = `${window.location.origin}/assets/example/video.bin`;
    }
    const numPeople =
      getNumPeople(state.videos.videoList, videoId, assessmentId, clipId) || 1;
    data = (yield call(
      getVideoJsons,
      url,
      numPeople,
      personId,
      state.videos.videoList[videoId].jointMetadata,
      chunk,
    )) as unknown as any;
  } catch (e) {
    if (
      state.videos.videoList[videoId].videoLoc !== undefined &&
      state.videos.videoList[videoId].videoLoc !== ""
    ) {
      NotificationManager.warning(
        "Warning",
        "Joint data not avail. for this video",
        3000,
        null,
        null,
        "",
      );
    }

    return;
  }

  yield put(getJointDataSuccess(videoId, data, chunk));
}

function* getSingleVideoSaga({ payload }: any) {
  const { videoId } = payload;
  const state: ReduxState = yield select();
  console.log("[getSingleVideoSaga]", payload);
  const video = (yield call(getSingleVideoDoc, videoId)) as unknown as any;

  // TODO: Create a config list of all the params that need
  // to be refreshed.
  if (Object.prototype.hasOwnProperty.call(state.videos.videoList, videoId)) {
    yield put(
      addNewVideo(
        {
          ...state.videos.videoList[videoId],
          assessmentMetadata: video.assessmentMetadata,
          tasks: video.tasks,
          jointMetadata: video.jointMetadata,
          summaryStats: video.summaryStats,
          notes: video.notes,
          videoName: video.videoName,
        },
        true,
      ),
    );
    return;
  }
  console.log("[getSingleVideoSaga]", {
    ...video,
    visible: true,
  });
  yield put(
    addNewVideo(
      {
        ...video,
        visible: true,
      },
      true,
    ),
  );
}

function* getVideoSuccessSaga({ payload }: any) {
  const videoData = payload.videoObj;
  const { key } = payload.videoObj;

  const state: ReduxState = yield select();
  const currentVideoObj = state.videos.videoList[key];

  if (
    Object.prototype.hasOwnProperty.call(videoData, "tasks") &&
    videoData.tasks.length > 0
  ) {
    // Only pull all thumbnails if postures exists.
    // If postures exists then the user has requested ALL
    // the data wrt a video
    if (
      Object.prototype.hasOwnProperty.call(videoData, "thumbnailLoc") &&
      !currentVideoObj.thumbnailLoc.startsWith("https")
    ) {
      yield put(getThumbnailRequest(key));
    }

    // TODO ADD LOGIC FOR POSTURE THUMBNAILS?????
  }
}

function* deleteVideoSaga({ payload }: any) {
  const { videoIds, userId } = payload;
  const state: ReduxState = yield select();
  // const currentUid = state.authUser.user.id;
  const userIds: any[] = [];
  videoIds.forEach((videoId: string) => {
    const video = state.videos.videoList[videoId];

    if (
      video.processingStatus !== ProcessingStatus.COMPLETED &&
      video.processingStatus !== ProcessingStatus.ERROR
    ) {
      NotificationManager.warning(
        "Cannot delete a video that is processing",
        "Delete error",
        5000,
        null,
        null,
        "",
      );
      return;
    }
    if (video.uid !== userId) {
      NotificationManager.warning(
        "You've selected videos that belong to a different user. You can only delete your own videos",
        "Delete error",
        5000,
        null,
        null,
        "",
      );
      return;
    }
    userIds.push(video.uid);
  });
  try {
    yield call(deleteVideosFirebaseHelper, userIds, videoIds);
  } catch (e) {
    handleVideoError(e);
    return;
  }
  yield put(
    getVideoListRequest(
      state.authUser.user.uid,
      state.authUser.user.companyId,
      state.authUser.user.groupId,
    ),
  );
  yield put(deleteVideosSuccess(videoIds));
}

function* saveVideoNotesSaga({ payload }: any) {
  const { videoId, notes, assessmentId, notesKey } = payload;
  if (notes.length > 10000) {
    NotificationManager.warning(
      "Keep notes under 10000 characters",
      "Note save error",
      5000,
      null,
      null,
      "",
    );
    return;
  }
  try {
    yield call(updateVideoNotes, videoId, assessmentId, notes, notesKey);
  } catch (e) {
    handleVideoError(e);
    return;
  }
  yield put(saveVideoNotesSuccess(videoId, assessmentId, notes, notesKey));
}

function* addMetadataOptionSaga({ payload }: any) {
  const { fieldId, optionName, parentOptionId, fieldName } = payload;
  // const state: ReduxState = yield select();
  let ret;
  try {
    ret = (yield call(
      addMetadataOptionHelper,
      fieldId,
      optionName,
      parentOptionId,
    )) as unknown as any;
  } catch (e) {
    handleVideoError(e);
    return;
  }
  NotificationManager.success(
    `"${optionName}" added as option`,
    "Option added",
    3000,
    null,
    null,
    "filled",
  );
  yield put(setCompanyMetadataSuccess(ret.metadata));
  yield call(mixpanelTrackEvent, "RS - Event - Add Metadata Option", {
    Field: fieldName,
  });
}

function* deleteMetadataOptionSaga({ payload }: any) {
  const { optionId, optionName } = payload;
  let ret;
  try {
    ret = (yield call(deleteMetadataOptionHelper, optionId)) as unknown as any;
  } catch (e) {
    handleVideoError(e);
    return;
  }

  NotificationManager.success(
    `"${optionName}" deleted as option`,
    "Option deleted",
    3000,
    null,
    null,
    "filled",
  );

  yield put(setCompanyMetadataSuccess(ret.metadata));
  yield call(mixpanelTrackEvent, "RS - Event - Delete Metadata Option");
}

function* editMetadataOptionSaga({ payload }: any) {
  const { optionId, optionName, fieldName } = payload;
  let ret;
  try {
    ret = (yield call(
      editMetadataOptionHelper,
      optionId,
      optionName,
    )) as unknown as any;
  } catch (e) {
    handleVideoError(e);
    return;
  }

  NotificationManager.success(
    `"${optionName}" changed successfully`,
    "Option edit",
    3000,
    null,
    null,
    "filled",
  );

  yield put(setCompanyMetadataSuccess(ret.metadata));
  yield call(mixpanelTrackEvent, "RS - Event - Edit Metadata Option", {
    Field: fieldName,
  });
}

function* setVideoMetadataSaga({ payload }: any) {
  const { videoId, fieldId, optionId, fieldName } = payload;
  try {
    // const state: ReduxState = yield select();
    // const {uid} = state.authUser.user;
    const ret = (yield call(
      setVideoMetadataFirebaseHelper,
      videoId,
      fieldId,
      optionId,
    )) as unknown as any;
    yield put(setVideoMetadataSuccess(videoId, ret.metadata));
    yield call(mixpanelTrackEvent, "RS - Event - Set Metadata Option", {
      Field: fieldName,
    });
  } catch (error) {
    console.log(error);
  }
}

function* getAllMetadataFieldsSaga() {
  // const { videoId } = payload;
  const data = {};
  try {
    // const state: ReduxState = yield select();
    // const companyObj = state.authUser.company;
    // data = yield call(getAllMetadataFieldsFirebaseHelper, companyObj['meta_data'], videoId);
  } catch (err) {
    console.log(`Metadata Error: ${err}`);
  }
  yield put(getAllMetadataFieldsSuccess(data));
}

function* getSubmitVideoFeedbackRequestSaga({ payload }: any) {
  const { rating, feedbackText } = payload;
  const state: ReduxState = yield select();
  const { id } = state.authUser.user;
  yield call(addFeedback, id, rating, feedbackText);
}

function* updateRiskComponentsSaga({ payload }: any) {
  const { videoId, assessmentId, postureId } = payload;
  const state: ReduxState = yield select();

  const assessmentObj = getAssessmentFromVideo(
    state.videos.videoList,
    videoId,
    assessmentId,
  );

  // const assessmentObj = state.videos.videoList[videoId]['tasks'][0]['assessments'].filter(x => x.id == assessmentId)[0];
  let riskComponents;
  for (let i = 0; i < assessmentObj.posture_assessments.length; i += 1) {
    const posture = assessmentObj.posture_assessments[i];
    if (posture.id === postureId) {
      riskComponents = posture.riskAssessment.riskComponents;
    }
  }
  try {
    yield call(
      updateRiskComponentsFirebaseHelper,
      videoId,
      assessmentId,
      postureId,
      riskComponents,
    );
  } catch (e) {
    handleVideoError(e);
    return;
  }

  yield call(mixpanelTrackEvent, "RS - Event - Update Model Detected Inputs");
}

export async function mixpanelTrackEvent(
  event: string,
  props?: any,
): Promise<void> {
  await Mixpanel.track(event, props);
}

function* closeChannelIfNecessary(listener: any, currentVideoId: string) {
  while (true) {
    const { payload } = yield take(REMOVE_VIDEO_LISTENERS);
    const { videoId } = payload;

    if (videoId) {
      if (videoId === currentVideoId) {
        listener.close();
      } else {
        return;
      }
    }

    listener.close();
  }
}

function* updateAdditionalInputsSaga({ payload }: any) {
  const { videoId, assessmentId, postureId } = payload;
  const state: ReduxState = yield select();

  const assessmentObj = getAssessmentFromVideo(
    state.videos.videoList,
    videoId,
    assessmentId,
  );
  // const assessmentObj = state.videos.videoList[videoId]['tasks'][0]['assessments'].filter(x => x.id == assessmentId)[0];
  let additionalInputs;
  for (let i = 0; i < assessmentObj.posture_assessments.length; i += 1) {
    const posture = assessmentObj.posture_assessments[i];
    if (posture.id === postureId) {
      additionalInputs = posture.riskAssessment.additionalInputs;
      delete additionalInputs.shortRest;
    }
  }
  try {
    const requestId = TumekeJSModule.getSession("videoRequestId") as string;
    yield call(
      updateAdditionalInfoFirebaseHelper,
      videoId,
      assessmentId,
      postureId,
      additionalInputs,
      assessmentObj.data.assessmentMetadata,
      requestId || undefined,
    );
  } catch (e) {
    handleVideoError(e);
    return;
  }

  yield call(mixpanelTrackEvent, "RS - Event - Update Manual Inputs");
}

// TODO(znoland): create single assessment Listener when new assessment is created
// function* singleAssessmentListenerSaga({ payload }) {}

function* singleVideoListenerSaga({ payload }: any) {
  const { videoId, listenerType, isExternal, assessmentId } = payload;
  let state: ReduxState = yield select();
  if (Object.prototype.hasOwnProperty.call(allRegisteredListeners, videoId)) {
    yield put(setVideosVisibility([videoId], true));
    console.log("[Listener] Listener already registered");
    return;
  }
  // If the listener is meant to listen for processing
  // updates to a video (to update video list) then don't
  // install. Only install listeners meant to check for
  // updates once manual/model detected inputs are adjusted
  if (listenerType === "PROCESSING_LIST_UPDATE") {
    return;
  }
  allRegisteredListeners[videoId] = true;
  const listener = registerNewVideoListener(videoId, isExternal);
  // const {dashboardData} = state.dashboard;

  yield fork(closeChannelIfNecessary, listener, videoId);
  while (true) {
    try {
      const newPayload = (yield take(listener)) as unknown as any;
      state = yield select();
      if (newPayload.action === "ADD") {
        let visible = true;
        if (isExternal) {
          const assessmentObjNew = getAssessmentFromVideo(
            { [videoId]: newPayload.data },
            videoId,
            assessmentId,
          );
          const assessmentObjOld = getAssessmentFromVideo(
            state.videos.videoList,
            videoId,
            assessmentId,
          );
          // Trigger update only if the condition is met
          if (assessmentObjOld?.data?.notes !== assessmentObjNew?.data?.notes) {
            yield put(
              addNewVideo(
                {
                  ...newPayload.data,
                  id: videoId,
                  visible: true,
                  key: videoId,
                },
                true,
              ),
            );
            NotificationManager.success(
              "GPT recommendations successfully updated",
              "GPT recommendations updated",
              5000,
              null,
              null,
              "filled",
            );
          }

          continue;
        }
        /*
         * Here we are processing videos coming in from websockets.
         * If it's a video that we already have a record of, just carry
         * over the old visibility value. We don't want to change
         * the visibility just because we have a change to the video doc.
         * If it's a new video we don't have a prior record of then
         * set it to be automatically visible.
         */
        if (
          Object.prototype.hasOwnProperty.call(
            state.videos.videoList,
            newPayload.data.key,
          )
        ) {
          visible = state.videos.videoList[newPayload.data.key].visible;
        } else {
          visible = true;
        }
        let jointUrls = {};
        try {
          jointUrls = yield call(getJobJointData, videoId);
        } catch (e) {
          console.log("Joint data error", e);
        }
        yield put(
          addNewVideo(
            {
              ...newPayload.data,
              jointUrls,
              visible,
            },
            true,
          ),
        );
      } else if (newPayload.action === "ERROR") {
        yield put(setWebsocketFailed(true));
      }
    } catch (err) {
      console.log(`[Listener] Error: ${err}`);
    }
  }
}

function* generateReportErrorSaga() {
  yield 0;
  NotificationManager.warning(
    "Reports only available for new videos",
    "Report generation error",
    5000,
    null,
    null,
    "",
  );
}

function* generateCSVErrorSaga() {
  yield 0;
  NotificationManager.warning(
    "CSV not available for this video",
    "CSV generation error",
    5000,
    null,
    null,
    "",
  );
}

function* rerunVideoRequestSaga({ payload }: any) {
  const { videoId, history } = payload;
  try {
    yield call(rerunAssessmentFull, videoId);
  } catch (e) {
    NotificationManager.warning(
      "Rerun error",
      "Please try again later",
      5000,
      null,
      null,
      "",
    );
    return;
  }
  history.push("/app/videos/videos");
}

/*
async function parseBlobHelper(blob: any): Promise<string> {
  const result: string = await blob.text();
  return result;
}
*/

function* generateReportSaga({ payload }: any) {
  const state: ReduxState = yield select();
  const {
    videoId,
    assessmentId,
    subjectId,
    postureId,
    reportType,
    reportPages,
  } = payload;
  let response = null;

  try {
    const requestId = TumekeJSModule.getSession("notificationRequestId");
    response = (yield call(
      generateReport,
      videoId,
      assessmentId,
      subjectId,
      postureId,
      reportType,
      reportPages,
      state.settings.locale || "en",
      requestId,
    )) as unknown as any;
  } catch (e) {
    yield put(generateReportError());
    return;
  }
  if (reportType === "worksheet") {
    yield call(mixpanelTrackEvent, "RS - Event - Generate Worksheet");
  } else {
    yield call(mixpanelTrackEvent, "RS - Event - Generate Report");
  }
  if (response.url) {
    const link = document.createElement("a");
    link.href = response.url;
    link.setAttribute("download", "report.pdf");
    document.body.appendChild(link);
    link.click();
    yield put(generateReportSuccess());
  }
}

function* downloadVideoSaga({ payload }: any) {
  const { videoId, email } = payload;
  const state: ReduxState = yield select();
  const { decryptedAESKeys } = state.authUser.user;
  if (!decryptedAESKeys) {
    NotificationManager.warning(
      "Download warning",
      "Still verifying identity... please try again in a few seconds",
      5000,
      null,
      null,
      "",
    );
    yield put(downloadVideoError());
    return;
  }
  try {
    yield call(
      downloadVideoRequest,
      videoId,
      JSON.stringify(decryptedAESKeys),
      email,
    );
  } catch (e) {
    yield put(downloadVideoError());
    NotificationManager.error(
      "Video is not ready to download yet. Please try again in a few minutes",
      "Warning",
      5000,
      null,
      null,
      "",
    );
    return;
  }
  NotificationManager.primary(
    `We are processing this request. Please check the bell at the top right
      of the page in 2-3 minutes, or your email inbox for a downloadable link.`,
    "Video Download Started",
    15000,
    null,
    null,
    "filled",
  );
  yield put(downloadVideoSuccess());
  yield call(mixpanelTrackEvent, "RS - Event - Download Video");
}

function* downloadRiskJointsCsvSaga({ payload }: any) {
  const { videoId, assessmentId } = payload;
  try {
    yield call(downloadRiskJointsCsvRequest, videoId, assessmentId);
  } catch (e) {
    yield put(downloadRiskJointsCsvError());
    NotificationManager.error(
      "Error in downloading Risk Joints CSV. Please try again, or contact support if this persists",
      "Error",
      5000,
      null,
      null,
      "",
    );
    return;
  }
  NotificationManager.primary(
    `We are processing this request. Please check the bell at the top right
      of the page in 2-3 minutes, or your email inbox for a downloadable link.`,
    "Risk Joints CSV Download Started",
    15000,
    null,
    null,
    "filled",
  );
  yield put(downloadRiskJointsCsvSuccess());
  yield call(mixpanelTrackEvent, "RS - Action - Download Joints CSV");
}

function* getExternalVideoDocSaga({ payload }: any) {
  const { videoKey, callback } = payload;
  let videoListObj: any;
  try {
    videoListObj = (yield call(
      externalGetVideoDoc,
      videoKey,
    )) as unknown as any;
    const snapshotDoc = videoListObj.video;
    let jointUrls;
    try {
      jointUrls = (yield call(
        getExternalJobJointData,
        videoKey,
      )) as unknown as any;
      console.log("JOB JOINT URLS", jointUrls);
    } catch (e) {
      console.log("Joint data error", e);
    }
    yield put(
      addNewVideo(
        {
          ...snapshotDoc.data,
          tasks: snapshotDoc.tasks,
          metadata: snapshotDoc.metadata,
          user_id: snapshotDoc.user_id,
          logo: snapshotDoc.logo,
          id: videoKey,
          visible: true,
          key: videoKey,
          jointUrls,
        },
        true,
      ),
    );
    yield put(setDecryptedAESKeysSuccess(videoListObj.aes, callback));
    yield put(setWebAppLogo(snapshotDoc.logo));
  } catch (e) {
    console.log("ERROR", e);
    yield put(
      addNewVideo(
        {
          id: videoKey,
          key: videoKey,
          errorMsg: "Some error",
          processingStatus: 4,
        },
        false,
      ),
    );
  }
}

function* generateCSVSaga({ payload }: any) {
  const { videoId, subjectId } = payload;
  try {
    yield call(getAssessmentOverTime, videoId, subjectId);
  } catch (e) {
    yield put(generateCSVError());
    return;
  }
  NotificationManager.success(
    `We are processing this request. Please check your inbox in 2-3 minutes with a link to the file`,
    "CSV Request Success",
    3000,
    null,
    null,
    "filled",
  );
  yield put(generateCSVSuccess());
}

function* externalGenerateGptRecommendationsSaga({ payload }: any) {
  const { videoKey, assessmentId } = payload;
  try {
    (yield call(
      externalGenerateGPTRecommendations,
      videoKey,
    )) as unknown as any;
  } catch (e) {
    yield put(externalGenerateGPTRecommendationsError());
    return;
  }

  yield put(addSingleVideoListener(videoKey, "", true, assessmentId));

  NotificationManager.success(
    `We are processing this request. Please wait 1-3 minutes`,
    "Generate GPT Request Success",
    3000,
    null,
    null,
    "filled",
  );
  yield put(externalGenerateGPTRecommendationsSuccess());
}

const getThumbnailName = async (videoId: string) => {
  // todo get thumbnail from server
  const realLoc = await getThumbnail(videoId);
  return realLoc.urls;
};

const getPostureThumbnailHelper = async (videoId: string, frame: any) => {
  // todo get thumbnail from server
  const realLoc = await getPostureThumbnail(videoId, frame);
  return realLoc.url;
};

function* getThumbnailRequestSaga({ payload }: any) {
  const { videoId, frameId, postureId } = payload;
  const state: ReduxState = yield select();
  const { videoList } = state.videos;

  if (postureId === undefined) {
    // If thumbnail already exists no need to get it again
    if (videoList[videoId].thumbnailLoc.startsWith("https://")) {
      return;
    }
    let thumbnailRealLoc = null;
    try {
      thumbnailRealLoc = (yield call(
        getThumbnailName,
        videoId,
      )) as unknown as any;
    } catch (e) {
      return;
    }
    yield put(getThumbnailSuccess(videoId, thumbnailRealLoc[0].url, undefined));
  } else {
    let thumbnailRealLoc = "";
    try {
      thumbnailRealLoc = yield call(
        getPostureThumbnailHelper,
        videoId,
        frameId,
      );
    } catch (e) {
      return;
    }
    yield put(getThumbnailSuccess(videoId, thumbnailRealLoc, postureId));
  }
}

function* getThumbnailsRequestSaga({ payload }: any) {
  const { videoIds, isImprovement } = payload;

  const state: ReduxState = yield select();
  const { videoList } = state.videos;
  // const shouldRequestThumbnails = false;
  // If any of the videoids DON't have a
  // thumbnail already, fire off the request
  const finalList = [];
  for (let i = 0; i < videoIds.length; i += 1) {
    if (
      !(
        videoList[videoIds[i]] &&
        videoList[videoIds[i]].thumbnailLoc.startsWith("https://")
      ) ||
      isImprovement
    ) {
      finalList.push(videoIds[i]);
    }
  }

  console.log("[getThumbnailsRequestSaga]", finalList);
  if (finalList.length === 0) {
    return;
  }

  // Only send 32 thumbnail requests at a time to
  // avoid overwhelming the URL
  let i = 0;
  while (i < finalList.length) {
    const slice = finalList.slice(i, i + 32);
    let videoIdStr = slice[0];
    for (let j = 1; j < slice.length; j += 1) {
      videoIdStr += `,${slice[j]}`;
    }
    let thumbnailRealLocs = null;
    try {
      thumbnailRealLocs = (yield call(
        getThumbnailName,
        videoIdStr,
      )) as unknown as any;
    } catch (e) {
      return;
    }
    for (let j = 0; j < thumbnailRealLocs.length; j += 1) {
      if (isImprovement) {
        yield put(
          getImprovementThumbnailSuccess(
            thumbnailRealLocs[j].videoId,
            thumbnailRealLocs[j].url,
          ),
        );
      } else {
        yield put(
          getThumbnailSuccess(
            thumbnailRealLocs[j].videoId,
            thumbnailRealLocs[j].url,
          ),
        );
      }
    }
    i += 32;
  }
}

function* compareVideosErrorSaga() {
  yield 0;
  NotificationManager.warning(
    "Please try again. If issues persists, contact support.",
    "Error Comparing Videos",
    5000,
    null,
    null,
    "",
  );
}

function* compareVideosRequestSaga({ payload }: any) {
  const { videoIds } = payload;
  const state: ReduxState = yield select();
  const videoDatas: any[] = [];
  const assessmentDatas: any[] = [];
  const assessmentIds: any[] = [];
  const { videoList } = state.videos;
  Object.keys(videoList).forEach((videoKey) => {
    if (videoIds.includes(videoKey)) {
      videoDatas.push(videoList[videoKey]);
      const primaryTask =
        videoList[videoKey].tasks.find((task: any) =>
          task.assessments.some((assessment: any) => assessment.is_primary),
        ) || videoList[videoKey].tasks[0];
      const assessmentObj =
        primaryTask.assessments.find(
          (assessment: any) => assessment.is_primary,
        ) || primaryTask.assessments[0];
      assessmentIds.push(assessmentObj.id);
      assessmentObj.videoId = videoKey;
      assessmentDatas.push(assessmentObj);
    }
  });
  let data: any = null;
  // try {

  data = getCompareObject(assessmentDatas);

  const orderedData: { [key: string]: any[] } = {};
  Object.keys(data).forEach((key) => {
    orderedData[key] = [];
    for (let i = 0; i < data[key].length; i += 1) {
      orderedData[key].push(
        data[key].filter((val: any) => val.videoId === videoIds[i])[0],
      );
    }
  });
  Object.keys(orderedData).forEach((bodyPart) => {
    Object.keys(orderedData[bodyPart]).forEach((key: any) => {
      orderedData[bodyPart][key].videoName =
        videoList[orderedData[bodyPart][key].videoId].videoName;
    });
  });

  yield put(compareVideosSuccess(videoIds, assessmentIds, orderedData));
}

/*
const waitTime = (delay: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
*/

function* updateVideoNameSaga({ payload }: any) {
  const { videoId, name } = payload;
  try {
    yield call(updateVideoName, videoId, name);
  } catch (e: any) {
    if (e.response && e.response.data) {
      NotificationManager.warning(
        e.response.data.message,
        "Warning",
        5000,
        null,
        null,
        "",
      );
      return;
    }
    NotificationManager.error(
      "Unknown error. If issue persists please contact support",
      "Error changing name",
      5000,
      null,
      null,
      "",
    );

    return;
  }
  NotificationManager.success(
    "Successfully updated",
    "Video name",
    3000,
    null,
    null,
    "filled",
  );
}

function* updateAssessmentNameSaga({ payload }: any) {
  const { videoId, assessmentId, name } = payload;
  try {
    yield call(updateAssessmentName, videoId, assessmentId, name);
  } catch (e: any) {
    if (e.response && e.response.data) {
      NotificationManager.warning(
        e.response.data.message,
        "Warning",
        5000,
        null,
        null,
        "",
      );
      return;
    }
    NotificationManager.error(
      "Unknown error. If issue persists please contact support",
      "Error changing name",
      5000,
      null,
      null,
      "",
    );

    return;
  }
  NotificationManager.success(
    "Successfully updated",
    "Assessment name",
    3000,
    null,
    null,
    "filled",
  );
}

function* getVideoListSaga({ payload }: any) {
  const { pageSizeArg, pageOffsetArg, search } = payload;
  const state: ReduxState = yield select();

  const filterObject = processFilterObject(state.dashboard.filterObject);

  const { videosLoading } = state.videos;

  if (videosLoading) {
    return;
  }
  yield put(loadingVideos());
  yield put(setAllInvisible());

  let videoList: any = null;
  let videoListObj: any = null;
  let videoCount: any = null;
  let totalVideoDurationMins: any = null;
  let videoLimitCount: any = null;

  let pageSize = pageSizeArg;
  let pageOffset = pageOffsetArg;
  if (pageSize === undefined) {
    pageSize = state.videos.pageMetadata.pageSize;
  }
  if (pageOffset === undefined) {
    pageOffset = (state.videos.pageMetadata.currentPage - 1) * pageSize;
  }

  try {
    videoListObj = (yield call(
      getAllUserVideos,
      filterObject,
      "",
      "",
      Intl.DateTimeFormat().resolvedOptions().timeZone || moment.tz.guess(),
      pageSize,
      pageOffset,
      search,
    )) as unknown as any;
    videoList = videoListObj.videos;
    videoCount = videoListObj.videoCount;
    totalVideoDurationMins = videoListObj.totalVideoDurationMins;
    videoLimitCount = videoListObj.videoLimitCount;
  } catch (e) {
    // TODO Handle error here
    return;
  }

  const firstVideos = videoList.map((a: any) => a.id);
  yield put(getThumbnailsRequest(firstVideos));

  for (let i = 0; i < videoList.length; i += 1) {
    const videoData = videoList[i];
    (videoList[i].metadata || []).sort(
      (a: any, b: any) => a.option_id < b.option_id,
    );
    if (
      videoData.processingStatus !== ProcessingStatus.COMPLETED &&
      videoData.processingStatus !== ProcessingStatus.ERROR
    ) {
      yield put(addSingleVideoListener(videoList[i].id));
    } else {
      yield put(
        addNewSkinnyVideo({
          ...videoData,
          metadata: videoList[i].metadata,
          user_id: videoList[i].user_id,
          key: videoList[i].id,
        }),
      );
    }
  }
  yield put(setVideosVisibility(firstVideos, true));
  console.log("All videos loaded");
  yield put(
    allVideosLoaded(videoCount, totalVideoDurationMins, videoLimitCount),
  );
}

function* getVideoJointUrlsSaga({ payload }: any) {
  const { videoId } = payload;
  const state: ReduxState = yield select();
  let { jointUrls } = state.videos.videoList[videoId];
  if (jointUrls) {
    return;
  }
  try {
    jointUrls = (yield call(getJobJointData, videoId)) as unknown as any;
  } catch (e) {
    console.log("Joint data error", e);
  }
  if (jointUrls) {
    yield put(addVideoJointUrls(videoId, jointUrls));
  }
}

function* getLinks() {
  let linksList = [];
  try {
    const linksObj = (yield call(getLinksData)) as unknown as any;
    linksList = linksObj?.items || [];
    yield put(getLinksSuccess(linksList));
  } catch (e: any) {
    handleVideoError(e);
  }
}

function* updateLink({ payload }: any) {
  const { linkId, values }: { linkId: number; values: any } = payload;
  try {
    const linkObj = (yield call(
      updateLinkHelper,
      linkId,
      values,
    )) as unknown as any;
    const state: ReduxState = yield select();
    const { linksList }: { linksList: any[] } = state.videos;
    const newLinksList = linksList.map((link: any) => {
      if (link.id === linkId) {
        return linkObj;
      }
      return link;
    });
    yield put(getLinksSuccess(newLinksList));
  } catch (e: any) {
    handleVideoError(e);
  }
}

function* deleteLink({ payload }: any) {
  const { linkId }: { linkId: number } = payload;
  try {
    (yield call(deleteLinkHelper, linkId)) as unknown as any;
    const state: ReduxState = yield select();
    const { linksList }: { linksList: any[] } = state.videos;
    const newLinksList = linksList.filter((link: any) => link.id !== linkId);
    yield put(getLinksSuccess(newLinksList));
  } catch (e: any) {
    handleVideoError(e);
  }
}

function* sendLink({ payload }: any) {
  const {
    linkId,
    value,
    type,
  }: { linkId: number; value: string; type: string } = payload;
  try {
    (yield call(sendLinkHelper, linkId, value, type)) as unknown as any;
    NotificationManager.success(
      "Message sent successfully",
      "Message sent",
      3000,
      null,
      null,
      "",
    );
  } catch (e: any) {
    handleVideoError(e);
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetVideoListRequest),
    fork(watchUploadInputsRequest),
    fork(watchGetJointDataLegacyRequest),
    fork(watchGetJointDataRequest),
    fork(watchUploadRiskComponentsRequest),
    fork(watchDeleteVideoRequest),
    fork(watchAddMetadataOptionRequest),
    fork(watchDeleteMetadataOptionRequest),
    fork(watcheditMetadataOptionRequest),
    fork(watchsetVideoMetadataRequest),
    fork(watchgetAllMetadataFieldsRequest),
    fork(watchAddSingleVideoListener),
    fork(watchGenerateReportRequest),
    fork(watchGenerateReportError),
    fork(watchGenerateCSVRequest),
    fork(watchGenerateCSVError),
    fork(watchExternalGenerateGptRecommendationsRequest),
    fork(watchSubmitVideoFeedbackRequest),
    fork(watchGetSingleVideoRequest),
    fork(watchCompareVideosRequest),
    fork(watchCompareVideosError),
    fork(watchRerunVideoRequest),
    fork(watchSaveVideoNotesRequest),
    fork(watchAddNewVideo),
    fork(watchUpdateVideoName),
    fork(watchUpdateAssessmentName),
    fork(watchDownloadVideoRequest),
    fork(watchGetThumbnailRequest),
    fork(watchGetThumbnailsRequest),
    fork(watchGetExternalVideoDoc),
    fork(watchDownloadRiskJointsCsvRequest),
    fork(watchGetVideoJointUrls),
    fork(watchGetLinks),
    fork(watchUpdateLink),
    fork(watchDeleteLink),
    fork(watchSendLink),
  ]);
}
