import { AnyAction } from "@reduxjs/toolkit";
import {
  MENU_SET_CLASSNAMES,
  MENU_CONTAINER_ADD_CLASSNAME,
  MENU_CLICK_MOBILE_MENU,
  MENU_CHANGE_DEFAULT_CLASSES,
  MENU_CHANGE_HAS_SUB_ITEM_STATUS,
} from "@kernel-store/actions";

export const changeSelectedMenuHasSubItems = (
  hasSubItems: boolean,
): AnyAction => ({
  type: MENU_CHANGE_HAS_SUB_ITEM_STATUS,
  payload: { hasSubItems },
});

export const changeDefaultClassnames = (
  strCurrentClasses: string,
): AnyAction => ({
  type: MENU_CHANGE_DEFAULT_CLASSES,
  payload: { strCurrentClasses },
});

export const addContainerClassname = (
  classname: string,
  strCurrentClasses: string,
): AnyAction => {
  const newClasses: string =
    // !strCurrentClasses.indexOf(classname) > -1
    strCurrentClasses.indexOf(classname) > -1
      ? `${strCurrentClasses} ${classname}`
      : strCurrentClasses;
  return {
    type: MENU_CONTAINER_ADD_CLASSNAME,
    payload: { newClasses },
  };
};

export const clickOnMobileMenu = (strCurrentClasses: string): AnyAction => {
  const currentClasses = strCurrentClasses
    ? strCurrentClasses
        .split(" ")
        .filter((x) => x !== "" && x !== "sub-show-temporary")
    : [];
  let nextClasses = "";
  if (currentClasses.includes("main-show-temporary")) {
    nextClasses = currentClasses
      .filter((x) => x !== "main-show-temporary")
      .join(" ");
  } else {
    nextClasses = `${currentClasses.join(" ")} main-show-temporary`;
  }
  return {
    type: MENU_CLICK_MOBILE_MENU,
    payload: { containerClassnames: nextClasses, menuClickCount: 0 },
  };
};

export const setContainerClassnames = (
  clickIndex: number,
  strCurrentClasses: string,
  selectedMenuHasSubItems: boolean,
): AnyAction => {
  let newClickIndex: number = clickIndex;
  const currentClasses = strCurrentClasses
    ? strCurrentClasses.split(" ").filter((x) => x !== "")
    : "";
  let nextClasses = "";
  if (!selectedMenuHasSubItems) {
    if (
      currentClasses.includes("menu-default") &&
      (newClickIndex % 4 === 0 || newClickIndex % 4 === 3)
    ) {
      newClickIndex = 1;
    }
    if (currentClasses.includes("menu-sub-hidden") && newClickIndex % 4 === 2) {
      newClickIndex = 0;
    }
    if (
      currentClasses.includes("menu-hidden") &&
      (newClickIndex % 4 === 2 || newClickIndex % 4 === 3)
    ) {
      newClickIndex = 0;
    }
  }

  if (newClickIndex % 4 === 0) {
    if (
      currentClasses.includes("menu-default") &&
      currentClasses.includes("menu-sub-hidden")
    ) {
      nextClasses = "menu-default menu-sub-hidden";
    } else if (currentClasses.includes("menu-default")) {
      nextClasses = "menu-default";
    } else if (currentClasses.includes("menu-sub-hidden")) {
      nextClasses = "menu-sub-hidden";
    } else if (currentClasses.includes("menu-hidden")) {
      nextClasses = "menu-hidden";
    }
    newClickIndex = 0;
  } else if (newClickIndex % 4 === 1) {
    if (
      currentClasses.includes("menu-default") &&
      currentClasses.includes("menu-sub-hidden")
    ) {
      nextClasses = "menu-default menu-sub-hidden main-hidden sub-hidden";
    } else if (currentClasses.includes("menu-default")) {
      nextClasses = "menu-default sub-hidden";
    } else if (currentClasses.includes("menu-sub-hidden")) {
      nextClasses = "menu-sub-hidden main-hidden sub-hidden";
    } else if (currentClasses.includes("menu-hidden")) {
      nextClasses = "menu-hidden main-show-temporary";
    }
  } else if (newClickIndex % 4 === 2) {
    if (
      currentClasses.includes("menu-default") &&
      currentClasses.includes("menu-sub-hidden")
    ) {
      nextClasses = "menu-default menu-sub-hidden sub-hidden";
    } else if (currentClasses.includes("menu-default")) {
      nextClasses = "menu-default main-hidden sub-hidden";
    } else if (currentClasses.includes("menu-sub-hidden")) {
      nextClasses = "menu-sub-hidden sub-hidden";
    } else if (currentClasses.includes("menu-hidden")) {
      nextClasses = "menu-hidden main-show-temporary sub-show-temporary";
    }
  } else if (newClickIndex % 4 === 3) {
    if (
      currentClasses.includes("menu-default") &&
      currentClasses.includes("menu-sub-hidden")
    ) {
      nextClasses = "menu-default menu-sub-hidden sub-show-temporary";
    } else if (currentClasses.includes("menu-default")) {
      nextClasses = "menu-default sub-hidden";
    } else if (currentClasses.includes("menu-sub-hidden")) {
      nextClasses = "menu-sub-hidden sub-show-temporary";
    } else if (currentClasses.includes("menu-hidden")) {
      nextClasses = "menu-hidden main-show-temporary";
    }
  }
  if (currentClasses.includes("menu-mobile")) {
    nextClasses += " menu-mobile";
  }
  return {
    type: MENU_SET_CLASSNAMES,
    payload: {
      containerClassnames: nextClasses,
      menuClickCount: newClickIndex,
    },
  };
};
