import cloneDeep from "lodash/cloneDeep";

import { IRiskScoreInfo } from "./interfaces";
import { strToArr } from "./NioshUtils";
import config from "../configs/libertymutual.json";

const { Units, CouplingStatus, TableTypes } = config;

const tableTypes: string[] = ["", "Lift", "Lower", "Push", "Pull", "Carry"];

export interface IAdditionalInput {
  female: boolean;
  frequency: number;
  coupling?: number;
  initialForce: number;
  sustainedForce?: number;
  horizontalDistance?: number;
  [x: string]: any;
}

export interface IRiskComponent {
  startHandHeight: number;
  endHandHeight?: number;
  startHandDistance?: number;
  endHandDistance?: number;
  [x: string]: any;
}

export interface IAssessmentResult {
  mal?: IRiskScoreInfo;
  malSustained?: IRiskScoreInfo;
  frequencyMultiplier?: IRiskScoreInfo;
  horizontalDistanceMultiplier?: IRiskScoreInfo;
  verticalHeightMultiplier?: IRiskScoreInfo;
  verticalDistanceMultiplier?: IRiskScoreInfo;
  [x: string]: any;
}

export interface ILibertyMutualMetadata {
  [x: string]: any;
}

export class LibertyMutual {
  initialized: boolean;

  assessmentResult: IAssessmentResult;

  riskComponents: IRiskComponent;

  additionalInputs: IAdditionalInput;

  libertyMutualMetadata: ILibertyMutualMetadata;

  constructor(oldObject: LibertyMutual | null) {
    this.initialized = true;
    if (!oldObject) {
      this.assessmentResult = {};
      this.riskComponents = LibertyMutual.getRiskComponents();
      this.additionalInputs = LibertyMutual.getAdditionalInputs();
      this.libertyMutualMetadata = {};
      return;
    }
    this.assessmentResult = cloneDeep(oldObject.assessmentResult);
    this.additionalInputs = cloneDeep(oldObject.additionalInputs);
    this.riskComponents = cloneDeep(oldObject.riskComponents);
    this.libertyMutualMetadata = cloneDeep(oldObject.libertyMutualMetadata);

    this.computeAssessment();
  }

  static getAdditionalInputs = (): IAdditionalInput => {
    const inputs: IAdditionalInput = {
      female: true,
      frequency: 1,
      coupling: CouplingStatus.GOOD.Number,
      initialForce: 0,
      horizontalDistance: 0,
    };
    return inputs;
  };

  static getRiskComponents = (): IRiskComponent => {
    const riskComponents: IRiskComponent = {
      startHandHeight: 0,
      endHandHeight: 0,
      startHandDistance: 0,
      endHandDistance: 0,
    };
    return riskComponents;
  };

  getAdditionalInput = (
    info: string,
    units: number = Units.STANDARD,
  ): number | undefined => {
    if (info === "horizontalDistance") {
      return (
        Math.round(
          (this.lengthConvert(this.additionalInputs[info] || 0, units) || 0) *
            100,
        ) / 100
      );
    }
    if (info === "initialForce" || info === "sustainedForce") {
      return (
        Math.round(
          (this.weightConvert(this.additionalInputs[info] || 0, units) || 0) *
            100,
        ) / 100
      );
    }
    return this.additionalInputs[info];
  };

  getRiskComponent = (info: string): number | undefined =>
    Math.round(this.lengthConvert(this.riskComponents[info]) || 0 * 100) / 100;

  getAssessmentResult = (
    info: string,
    units: number = Units.STANDARD,
  ): IRiskScoreInfo | undefined => {
    if (info === "mal" || info === "malSustained") {
      if (units === Units.METRIC) {
        return {
          ...this.assessmentResult[info],
          Score:
            Math.round(
              Number(this.assessmentResult[info]?.Score || 0) * 0.453592 * 100,
            ) / 100,
        } as IRiskScoreInfo;
      }
    }

    return {
      ...this.assessmentResult[info],
      Score: Math.round((this.assessmentResult[info]?.Score || 0) * 100) / 100,
    };
  };

  lengthConvert = (
    val: number,
    units: number = Units.STANDARD,
  ): number | undefined => {
    if (units === Units.STANDARD) {
      return val;
    }

    if (units === Units.METRIC) {
      return 0.3048 * val;
    }
    return undefined;
  };

  weightConvert = (
    val: number,
    units: number = Units.STANDARD,
  ): number | undefined => {
    if (units === Units.STANDARD) {
      return val;
    }

    if (units === Units.METRIC) {
      return 0.453592 * val;
    }

    return undefined;
  };

  updateAdditionalInfo = (
    typeOfInput: string,
    bodyGroup: any,
    newValue: number,
    units: number = Units.STANDARD,
  ): void => {
    if (typeOfInput === "startEnd") {
      this.libertyMutualMetadata.startEnd = newValue;
      return;
    }
    if (units === Units.METRIC) {
      if (typeOfInput === "initialForce" || typeOfInput === "sustainedForce") {
        this.additionalInputs[typeOfInput] = (1 / 0.453592) * newValue;
      } else if (typeOfInput === "horizontalDistance") {
        this.additionalInputs.horizontalDistance = (1 / 0.3048) * newValue;
      } else {
        this.additionalInputs[typeOfInput] = newValue;
      }
    } else {
      this.additionalInputs[typeOfInput] = newValue;
    }
    this.computeAssessment();
  };

  updateRiskComponents = (
    bodyGroup: string | number,
    typeOfInput: string | number,
    newValue: any,
    units: number = Units.STANDARD,
  ): void => {
    if (units === Units.METRIC) {
      this.riskComponents[typeOfInput] = (1 / 0.3048) * newValue;
    } else {
      this.riskComponents[typeOfInput] = newValue;
    }

    this.computeAssessment();
  };

  unsafeAssessment = (): void => {
    const { female } = this.additionalInputs;
    const { tableType } = this.libertyMutualMetadata;
    this.assessmentResult.mal = LibertyMutual.getRiskScoreInfo(
      "mal",
      -1,
      this.additionalInputs.initialForce,
    );
    this.assessmentResult.malSustained = LibertyMutual.getRiskScoreInfo(
      "malSustained",
      -1,
      this.additionalInputs.sustainedForce || 0,
    );
    this.assessmentResult.frequencyMultiplier = LibertyMutual.getRiskScoreInfo(
      "frequencyMultiplier",
      -1,
      undefined,
      tableType,
      female,
    );
    this.assessmentResult.horizontalDistanceMultiplier =
      LibertyMutual.getRiskScoreInfo(
        "horizontalDistanceMultiplier",
        -1,
        undefined,
        tableType,
        female,
      );
    if ([TableTypes.LIFT.Number, TableTypes.LOWER.Number].includes(tableType)) {
      this.assessmentResult.verticalHeightMultiplier =
        LibertyMutual.getRiskScoreInfo(
          "verticalHeightMultiplier",
          -1,
          undefined,
          tableType,
          female,
        );
    } else {
      this.assessmentResult.verticalDistanceMultiplier =
        LibertyMutual.getRiskScoreInfo(
          "verticalDistanceMultiplier",
          -1,
          undefined,
          tableType,
          female,
        );
    }
  };

  static computeRange = (
    map: any,
    component: string | number,
    score: number,
  ): string => {
    let key: string | undefined;
    Object.keys(map[component]).some((rangeKey: any) => {
      const array = strToArr(rangeKey);
      if (Number.isNaN(array[0]) && score <= array[1]) {
        key = rangeKey;
        return true;
      }
      if (Number.isNaN(array[1]) && score >= array[0]) {
        key = rangeKey;
        return true;
      }
      if (score >= array[0] && score < array[1]) {
        key = rangeKey;
        return true;
      }
      return false;
    });
    if (key === undefined) {
      throw new Error(`No range found: ${component}`);
    }
    return key;
  };

  static getRiskScoreInfo = (
    component: string,
    score: number,
    compareScore?: number,
    tableType?: number,
    female?: boolean,
  ): IRiskScoreInfo => {
    let realComponent = component;
    if (!["mal", "malSustained"].includes(component)) {
      realComponent += `${tableTypes[tableType!]}${female ? "Female" : "Male"}`;
    }
    const riskComponent = (config as any).ComponentValues[realComponent] as {
      [key: string]: IRiskScoreInfo;
    };
    if (!riskComponent) {
      throw new Error(
        `LibertyMutial.ComponentValues[${realComponent}] doesn't exist in config`,
      );
    }
    if (["mal", "malSustained"].includes(realComponent)) {
      if (score === -1) {
        return {
          ...riskComponent.unsafe,
          Score: score,
        };
      }
      if ((compareScore as number) > score) {
        return {
          ...riskComponent.unacceptable,
          Score: score,
        };
      }
      return {
        ...riskComponent.acceptable,
        Score: score,
      };
    }

    const range = LibertyMutual.computeRange(
      config.ComponentValues,
      realComponent,
      score,
    );

    const ranges = (config as any).ComponentValues
      ? (config as any).ComponentValues[realComponent]
      : undefined;
    if (!ranges || !ranges[range]) {
      throw new Error(
        `LibertyMutual.ComponentValues[${realComponent}][${range}] doesn't exist in config`,
      );
    }

    return {
      ...ranges[range],
      Score: score,
    };
  };

  computeAssessment = (): void => {
    const { female, frequency, coupling, horizontalDistance } =
      this.additionalInputs;
    const { tableType } = this.libertyMutualMetadata;
    const {
      startHandHeight,
      endHandHeight,
      startHandDistance,
      endHandDistance,
    } = this.riskComponents;
    let mal: number = 0;
    let malSustained: number = 0;
    let cv: number = 0;
    let cvSustained: number = 0;
    // Lift and Lower
    if ([TableTypes.LIFT.Number, TableTypes.LOWER.Number].includes(tableType)) {
      const hOrigin: number = (startHandDistance || 0.00001) * 0.3048; // to meters
      const hDest: number = (endHandDistance || 0) * 0.3048; // to meters
      const vOrigin: number = (startHandHeight || 0.00001) * 0.3048; // to meters
      const vDest: number = (endHandHeight || 0) * 0.3048; // to meters
      let rl: number = 0;
      let hSf: number = 0;
      let vrmSf: number = 0;
      let dvSf: number = 0;
      let fSf: number = 0;
      let h: number = Math.max(hOrigin, hDest);
      const hMin = female ? 0.2 : 0.25;
      if (h < hMin) {
        h = hMin;
      }
      /* Ignore min boundaries
      const hMin = female ? 0.2 : 0.248;
      const h: number = Math.max(hOrigin || hMax, hDest || hMax);
      if (h < hMin) {
        this.unsafeAssessment();
        return;
      }
      */
      const hMax = female ? 0.68 : 0.73;
      if (h > hMax) {
        this.unsafeAssessment();
        return;
      }
      const f = (frequency || 0.00001) / 60;
      if (f > 20) {
        this.unsafeAssessment();
        return;
      }
      const vrm: number = (vOrigin + vDest) / 2;
      let dv: number = Math.abs(vOrigin - vDest);
      /* Ignore min boundaries
      if (dv < 0.25) {
        this.unsafeAssessment();
        return;
      }
      */
      const maxVrm: number = vrm + dv / 2;
      if (maxVrm > (female ? 1.96 : 2.14)) {
        this.unsafeAssessment();
        return;
      }
      if (dv === 0.0) {
        dv = 0.01;
      }
      if (female) {
        hSf = 1.2602 - h / 0.7686;
        vrmSf = 0.9877 + vrm / 13.69 - vrm ** 2 / 9.221;
        dvSf = 0.8199 - Math.log(dv) / 7.696;
        fSf = 0.6767 - Math.log(f) / 12.59 - Math.log(f) ** 2 / 228.2;
      } else {
        hSf = 1.3532 - h / 0.7079;
        vrmSf = 0.7746 + vrm / 1.912 - vrm ** 2 / 3.296;
        dvSf = 0.8695 - Math.log(dv) / 10.62;
      }
      if (tableType === TableTypes.LIFT.Number && female) {
        cv = 0.26;
        rl = 34.9;
      } else if (tableType === TableTypes.LIFT.Number && !female) {
        cv = 0.276;
        rl = 82.6;
        fSf = 0.6259 - Math.log(f) / 9.092 - Math.log(f) ** 2 / 125.0;
      } else if (tableType === TableTypes.LOWER.Number && female) {
        cv = 0.307;
        rl = 37.0;
      } else if (tableType === TableTypes.LOWER.Number && !female) {
        cv = 0.304;
        rl = 95.9;
        fSf = 0.5773 - Math.log(f) / 10.8 - Math.log(f) ** 2 / 255.9;
      }
      mal = rl * hSf * vrmSf * dvSf * fSf;

      this.assessmentResult.frequencyMultiplier =
        LibertyMutual.getRiskScoreInfo(
          "frequencyMultiplier",
          fSf,
          undefined,
          tableType,
          female,
        );
      this.assessmentResult.horizontalDistanceMultiplier =
        LibertyMutual.getRiskScoreInfo(
          "horizontalDistanceMultiplier",
          hSf,
          undefined,
          tableType,
          female,
        );
      this.assessmentResult.verticalHeightMultiplier =
        LibertyMutual.getRiskScoreInfo(
          "verticalHeightMultiplier",
          vrmSf,
          undefined,
          tableType,
          female,
        );
      // Push and Pull
    } else if (
      [TableTypes.PUSH.Number, TableTypes.PULL.Number].includes(tableType)
    ) {
      let v: number = (startHandHeight || 0) * 0.3048; // to meters
      let vSustained: number = (startHandHeight || 0) * 0.3048; // to meters
      const vMin = female ? 0.1 : 0.5;
      const vMinSustained = female ? 0.1 : 0.62;
      /* Old max boundaries logic
      let vMax = female ? 1000 : 1.44;
      let vMaxSustained = female ? 1000 : 1.44;
      if (!female && tableType === TableTypes.PULL.Number) {
        vMax = 2.31;
        vMaxSustained = 1.72;
      }
      */
      if (v < vMin) {
        v = vMin;
      }
      if (vSustained < vMinSustained) {
        vSustained = vMinSustained;
      }
      /* Old max boundaries logic
      if (v > vMax) {
        v = vMax;
      }
      if (vSustained > vMaxSustained) {
        vSustained = vMaxSustained;
      }
      */
      /* Ignore min boundaries
      const vMin = female ? 0.58 : 0.63;
      if (v < vMin) {
        this.unsafeAssessment();
        return;
      }
      */
      const vMax = female ? 1.33 : 1.44;
      if (v > vMax) {
        this.unsafeAssessment();
        return;
      }
      if (vSustained > vMax) {
        this.unsafeAssessment();
        return;
      }
      let dh: number = (horizontalDistance || 0) * 0.3048; // to meters
      const dhMin = 2.1;
      if (dh < dhMin) {
        dh = dhMin;
      }
      /* Ignore min boundaries
      const dhMin = 2.1;
      if (dh < dhMin) {
        this.unsafeAssessment();
        return;
      }
      */
      const dhMax = 61;
      if (dh > dhMax) {
        this.unsafeAssessment();
        return;
      }
      const f = (frequency || 0.00001) / 60;
      if (f > 10) {
        this.unsafeAssessment();
        return;
      }
      let rl: number = 0;
      let rlSustained: number = 0;
      let vSf: number = 0;
      let vSfSustained = 0;
      let dhSf: number = 0;
      let dhSfSustained: number = 0;
      let fSf: number = 0;
      let fSfSustained: number = 0;
      if (
        (tableType === TableTypes.PUSH.Number ||
          tableType === TableTypes.PULL.Number) &&
        female
      ) {
        cv = tableType === TableTypes.PUSH.Number ? 0.214 : 0.234;
        cvSustained = tableType === TableTypes.PUSH.Number ? 0.286 : 0.298;
        rl = 36.9;
        rlSustained = 25.5;
        vSf = -0.5304 + v / 0.3361 - v ** 2 / 0.6915;
        vSfSustained = -0.6539 + vSustained / 0.2941 - vSustained ** 2 / 0.5722;
        dhSf = 1.0286 - dh / 72.22 + dh ** 2 / 9782;
        dhSfSustained = 1.0391 - dh / 52.91 + dh ** 2 / 7975;
        fSf = 0.7251 - Math.log(f) / 13.19 - Math.log(f) ** 2 / 197.3;
        fSfSustained = 0.6086 - Math.log(f) / 11.95 - Math.log(f) ** 2 / 304.4;
      } else if (tableType === TableTypes.PUSH.Number && !female) {
        cv = 0.231;
        cvSustained = 0.267;
        rl = 70.3;
        rlSustained = 65.3;
        vSf = 1.2737 - v / 1.335 + v ** 2 / 2.576;
        vSfSustained = 2.294 - vSustained / 0.3345 + vSustained ** 2 / 0.6887;
        dhSf = 1.079 - Math.log(dh) / 9.392;
        dhSfSustained = 1.1035 - Math.log(dh) / 7.17;
        fSf = 0.6281 - Math.log(f) / 13.07 - Math.log(f) ** 2 / 379.5;
        fSfSustained = 0.4896 - Math.log(f) / 10.2 - Math.log(f) ** 2 / 403.9;
      } else if (tableType === TableTypes.PULL.Number && !female) {
        cv = 0.238;
        cvSustained = 0.257;
        rl = 69.8;
        rlSustained = 61.0;
        vSf = 1.7186 - v / 0.6888 + v ** 2 / 2.025;
        vSfSustained = 2.1977 - vSustained / 0.385 + vSustained ** 2 / 0.9047;
        dhSf = 1.079 - Math.log(dh) / 9.392;
        dhSfSustained = 1.1035 - Math.log(dh) / 7.17;
        fSf = 0.6281 - Math.log(f) / 13.07 - Math.log(f) ** 2 / 379.5;
        fSfSustained = 0.4896 - Math.log(f) / 10.2 - Math.log(f) ** 2 / 403.9;
      }
      mal = rl * vSf * dhSf * fSf;
      malSustained = rlSustained * vSfSustained * dhSfSustained * fSfSustained;

      this.assessmentResult.frequencyMultiplier =
        LibertyMutual.getRiskScoreInfo(
          "frequencyMultiplier",
          fSf,
          undefined,
          tableType,
          female,
        );
      this.assessmentResult.horizontalDistanceMultiplier =
        LibertyMutual.getRiskScoreInfo(
          "horizontalDistanceMultiplier",
          dhSf,
          undefined,
          tableType,
          female,
        );
      this.assessmentResult.verticalDistanceMultiplier =
        LibertyMutual.getRiskScoreInfo(
          "verticalDistanceMultiplier",
          vSf,
          undefined,
          tableType,
          female,
        );
      // Carry
    } else if (tableType === TableTypes.CARRY.Number) {
      let v: number = (startHandHeight || 0) * 0.3048; // to meters
      const vMin = female ? 0.71 : 0.78;
      if (v < vMin) {
        v = vMin;
      }
      /* Ignore min boundaries
      const vMin = female ? 0.71 : 0.78;
      if (v < vMin) {
        this.unsafeAssessment();
        return;
      }
      */
      const vMax = female ? 1.03 : 1.1;
      if (v > vMax) {
        this.unsafeAssessment();
        return;
      }
      let dh: number = (horizontalDistance || 0) * 0.3048; // to meters
      const dhMin = 2.1;
      if (dh < dhMin) {
        dh = dhMin;
      }
      /* Ignore min boundaries
      const dhMin = 2.1;
      if (dh < dhMin) {
        this.unsafeAssessment();
        return;
      }
      */
      const dhMax = 10;
      if (dh > dhMax) {
        this.unsafeAssessment();
        return;
      }
      const f = (frequency || 0.00001) / 60;
      if (f > 10) {
        this.unsafeAssessment();
        return;
      }
      let rl: number = 0;
      let vSf: number = 0;
      let dhSf: number = 0;
      let fSf: number = 0;
      if (female) {
        cv = 0.231;
        rl = 28.6;
        vSf = 1.1645 - v / 4.437;
        dhSf = 1.0101 - dh / 207.8;
        fSf = 0.6224 - Math.log(f) / 16.33;
      } else {
        cv = 0.278;
        rl = 74.9;
        vSf = 1.5505 - v / 1.417;
        dhSf = 1.1172 - Math.log(dh) / 6.332;
        fSf = 0.5149 - Math.log(f) / 7.958 - Math.log(f) ** 2 / 131.1;
      }
      mal = rl * vSf * dhSf * fSf;

      this.assessmentResult.frequencyMultiplier =
        LibertyMutual.getRiskScoreInfo(
          "frequencyMultiplier",
          fSf,
          undefined,
          tableType,
          female,
        );
      this.assessmentResult.horizontalDistanceMultiplier =
        LibertyMutual.getRiskScoreInfo(
          "horizontalDistanceMultiplier",
          dhSf,
          undefined,
          tableType,
          female,
        );
      this.assessmentResult.verticalDistanceMultiplier =
        LibertyMutual.getRiskScoreInfo(
          "verticalDistanceMultiplier",
          vSf,
          undefined,
          tableType,
          female,
        );
    }

    if ([TableTypes.LIFT.Number, TableTypes.LOWER.Number].includes(tableType)) {
      if (coupling === CouplingStatus.POOR.Number) {
        mal *= 0.925;
      } else if (coupling === CouplingStatus.WITHOUT.Number) {
        mal *= 0.84;
      }
    }
    const z = 0.675;
    const mal75 = mal * (1 - cv * z) * 2.20462; // to pounds;
    const mal75Sustained = malSustained * (1 - cvSustained * z) * 2.20462; // to pounds;

    this.assessmentResult.mal = LibertyMutual.getRiskScoreInfo(
      "mal",
      mal75,
      this.additionalInputs.initialForce,
    );
    this.assessmentResult.malSustained = LibertyMutual.getRiskScoreInfo(
      "malSustained",
      mal75Sustained,
      this.additionalInputs.sustainedForce,
    );
  };
}
