import { IStorageAdapter } from "@kernel-adapters/storage";
import { DashboardReduxState } from "./reducer";

export const persistStore = async (
  state: DashboardReduxState,
  storageAdapter: IStorageAdapter,
): Promise<DashboardReduxState> => {
  const onboardingFinished: string | null = await storageAdapter.getItem(
    "onboardingFinished",
  );
  const savedDashboardFilters: string | null = await storageAdapter.getItem(
    "savedDashboardFilters",
  );

  return {
    ...state,
    onboardingFinished:
      onboardingFinished !== null ? JSON.parse(onboardingFinished) : [],
    savedDashboardFilters:
      savedDashboardFilters !== null ? JSON.parse(savedDashboardFilters) : [],
  };
};
