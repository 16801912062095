/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  all,
  call,
  fork,
  put,
  takeEvery,
  select,
  take,
} from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import { io } from "socket.io-client";

import { deleteNotification } from "@kernel-helpers/DatabaseHelpers";
import { Config } from "@kernel-config";
import { asyncGetIdToken } from "@tumeke/tumekejs";
import {
  DELETE_NOTIFICATION_RECORD,
  START_NOTIFICATION_LISTENER,
  REMOVE_NOTIFICATION_LISTENER,
  GENERATE_REPORT_SUCCESS,
} from "@kernel-store/actions";
import { ReduxState } from "@kernel-store/reducers";
import {
  generateReportSuccess,
  setWebsocketFailed,
} from "@kernel-store/videos/actions";
import { TumekeJSModule } from "@kernel";
import { addNotificationRecord, setListenerActive } from "./actions";

export function* startNotificationListenerWatch() {
  yield takeEvery(START_NOTIFICATION_LISTENER, startNotificationListenerSaga);
}

export function* deleteNotificationRecordWatch() {
  yield takeEvery(DELETE_NOTIFICATION_RECORD, deleteNotificationRecordSaga);
}

const registerNotificationsListener = (userId: string) =>
  eventChannel((emit) => {
    const handleNewNotification = (notifDocs: any[]) => {
      for (let i = 0; i < notifDocs.length; i += 1) {
        const doc = {
          ...notifDocs[i],
          ...notifDocs[i].data,
        };
        emit({ action: "ADD", data: doc, key: notifDocs[i].id });
      }
    };
    const channel = io(`${Config.TUMEKE_SERVER_WEBSOCKET_API}/notifications`);

    channel.on("connect", async () => {
      const authToken: string = await asyncGetIdToken("web");
      channel.emit("subscribeToNotifications", {
        senderUserId: userId,
        cognitoAuthToken: authToken,
      });
    });

    channel.on("disconnect", () => {
      console.log("Notification channel closed");
    });

    channel.on("errorToClient", (data: any) => {
      if (data.message === "Bad auth") {
        channel.close();
        return;
      }
      emit({ action: "ERROR" });
    });
    channel.on("error", () => {
      emit({ action: "ERROR" });
    });

    channel.on("allNotificationsToClient", (data: any) => {
      handleNewNotification(data.notifications);
    });

    channel.on("notificationToClient", (data: any) => {
      handleNewNotification([data.notification]);
    });

    channel.on("reportUrlToClient", (response: any) => {
      if (
        response.requestId &&
        TumekeJSModule.getSession("notificationRequestId") !==
          response.requestId
      ) {
        return;
      }
      const downloadLink = document.createElement("a");
      downloadLink.href = response.url;
      downloadLink.setAttribute("target", "downloadIframe");
      downloadLink.setAttribute("download", "report.pdf");
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      emit({
        action: GENERATE_REPORT_SUCCESS,
      });
    });

    const unsubscribe = () => {
      channel.close();
    };
    return unsubscribe;
  });

function* closeNotificationChannel(listener: any) {
  while (true) {
    yield take(REMOVE_NOTIFICATION_LISTENER);
    listener.close();
  }
}

function* deleteNotificationRecordSaga({ payload }: any) {
  console.log(JSON.stringify(payload));
  yield call(deleteNotification, payload.notificationId);
}

function* startNotificationListenerSaga() {
  let state: ReduxState = yield select();
  if (state.notifications.listenerActive) {
    return;
  }
  yield put(setListenerActive());
  console.log("Starting notification listener saga");
  const userId = state.authUser.user.id;
  const listener = registerNotificationsListener(userId);
  yield fork(closeNotificationChannel, listener);
  while (true) {
    state = yield select();
    try {
      const payload = (yield take(listener)) as unknown as any;
      if (payload.action === "ADD") {
        yield put(addNotificationRecord(payload.data, payload.key));
      } else if (payload.action === "ERROR") {
        yield put(setWebsocketFailed(true));
      } else if (payload.action === "GENERATE_REPORT_SUCCESS") {
        yield put(generateReportSuccess());
      }
    } catch (err) {
      console.log(`Error: ${err}`);
    }
  }
}

export default function* rootSaga() {
  yield all([
    fork(startNotificationListenerWatch),
    fork(deleteNotificationRecordWatch),
  ]);
}
