import { AnyAction } from "@reduxjs/toolkit";
import { TumekeJSModule, EAdapterNames } from "@kernel";
import { IStorageAdapter } from "@kernel-adapters/storage";
import {
  GET_COMPANIES_DATA_REQUEST,
  GET_COMPANIES_DATA_SUCCESS,
  GET_COMPANIES_DATA_ERROR,
  GET_COMPANIES_FILTERS_REQUEST,
  GET_COMPANIES_FILTERS_SUCCESS,
  GET_COMPANIES_FILTERS_ERROR,
  SET_COMPANIES_FILTER_FIELD,
  CLEAR_COMPANIES_FILTERS,
  SAVE_POLICES_REQUEST,
  SAVE_POLICES_REQUEST_SUCCESS,
  SAVE_POLICES_REQUEST_ERROR,
} from "@kernel-store/actions";

export type TCompaniesFilter = {
  assessment_types: any;
  assessors: any;
  start_date: any;
  end_date: any;
  metadata_array: any;
  groups: any;
  subject1_risk_levels: any;
};

export const EMPTY_FILTER: TCompaniesFilter = {
  assessment_types: [],
  assessors: [],
  start_date: null,
  end_date: null,
  metadata_array: [],
  groups: [],
  subject1_risk_levels: [],
};

export type CompaniesReduxState = {
  companiesData: any;
  companiesCount: number;
  companiesDataLoading: boolean;
  companiesFilters: any;
  companiesDataDirty: boolean;
  filterObject: TCompaniesFilter;
  refresh: boolean;
};

export const INIT_STATE: CompaniesReduxState = {
  companiesData: [],
  companiesCount: 0,
  companiesDataLoading: false,
  companiesFilters: {},
  companiesDataDirty: false,
  filterObject: EMPTY_FILTER,
  refresh: false,
};

const getUnvisited = (metadataSort: any[]) => {
  const arr = metadataSort.filter((m) => !m.visited);
  return arr;
};

const visit = (node: any, newMetadata: any[], metadata: any[]): void => {
  if (node.visited) {
    return;
  }
  const newNode = node;
  newNode.temp = true;
  if (node.data[2] != null) {
    const parentNode = metadata.filter((m) => m.data[1] === node.data[2])[0];
    visit(parentNode, newMetadata, metadata);
  }
  newNode.temp = false;
  newNode.visited = true;
  newMetadata.push(node);
};

const sortMetadataArray = (metadata: any[]): any[] => {
  const metadataSort = [];
  const newMetadata = [];
  for (let i = 0; i < metadata.length; i += 1) {
    metadataSort.push({
      data: metadata[i],
      visited: false,
    });
  }
  newMetadata.push(
    metadataSort.filter((m) => m.data[0] === "Assessment Version" || m.data[0] === "Attempt Number")[0],
  );
  newMetadata[0].visited = true;
  let unvisited = getUnvisited(metadataSort);
  while (unvisited.length > 0) {
    const node = unvisited[0];
    visit(node, newMetadata, metadataSort);
    unvisited = getUnvisited(metadataSort);
  }
  const ret = [];
  for (let i = 0; i < newMetadata.length; i += 1) {
    ret.push(newMetadata[i].data);
  }
  return ret;
};

export default (
  state: CompaniesReduxState = INIT_STATE,
  action: AnyAction = { type: undefined },
) => {
  switch (action.type) {
    case SAVE_POLICES_REQUEST:
      return { ...state, companiesDataLoading: true };
    case SAVE_POLICES_REQUEST_ERROR:
      return { ...state, companiesDataLoading: false };
    case SAVE_POLICES_REQUEST_SUCCESS:
      return {
        ...state,
        companiesDataLoading: false,
        companiesData: state.companiesData.map((company: any) => {
          if (company.id === action.payload.companyId) {
            return action.payload.companyData;
          }
          return company;
        }),
      };

    case GET_COMPANIES_DATA_REQUEST:
      return { ...state, companiesDataLoading: true };
    case GET_COMPANIES_DATA_SUCCESS:
      return {
        ...state,
        companiesData: action.payload.companiesData.items,
        companiesCount: action.payload.companiesData.count,
        companiesDataLoading: false,
      };
    case GET_COMPANIES_DATA_ERROR:
      // TODO(znoland): consider setting data to zero to make it clear data is not available?
      return { ...state, companiesDataLoading: false };

    case GET_COMPANIES_FILTERS_REQUEST:
      return { ...state };

    case GET_COMPANIES_FILTERS_SUCCESS: {
      const metadataOptionList = sortMetadataArray(
        action.payload.companiesFilters.metadataOptionList,
      );
      return {
        ...state,
        companiesFilters: {
          ...action.payload.companiesFilters,
          metadataOptionList,
        },
      };
    }
    case GET_COMPANIES_FILTERS_ERROR:
      return { ...state };

    case SET_COMPANIES_FILTER_FIELD: {
      const stogareAdapter = TumekeJSModule.get(
        EAdapterNames.Storage,
      ) as IStorageAdapter;
      stogareAdapter.setItem(
        "filterObject",
        JSON.stringify({
          ...state.filterObject,
          [action.payload.field]: action.payload.value,
        }),
      );
      return {
        ...state,
        refresh: !state.refresh,
        filterObject: {
          ...state.filterObject,
          [action.payload.field]: action.payload.value,
        },
        companiesDataLoading: true,
      };
    }
    case CLEAR_COMPANIES_FILTERS:
      return {
        ...state,
        filterObject: EMPTY_FILTER,
      };
    default:
      return { ...state };
  }
};
