import { AnyAction } from "@reduxjs/toolkit";
import { TumekeJSModule, EAdapterNames } from "@kernel";
import { IStorageAdapter } from "@kernel-adapters/storage";
import {
  GET_DASHBOARD_DATA_REQUEST,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_DASHBOARD_DATA_ERROR,
  SET_DASHBOARD_DATA_SHORT_KEY,
  GET_DASHBOARD_DATA_EXTERNAL,
  SET_DASHBOARD_FILTERS_EXTERNAL,
  GET_DASHBOARD_FILTERS_REQUEST,
  GET_DASHBOARD_FILTERS_SUCCESS,
  GET_DASHBOARD_FILTERS_ERROR,
  SET_DASHBOARD_FILTER_FIELD,
  SET_DASHBOARD_FILTER_FIELDS,
  CLEAR_DASHBOARD_FILTERS,
  SET_DASHBOARD_ONBOARDING,
  SET_DASHBOARD_ONBOARDING_FINISHED,
  SET_DASHBOARD_ONBOARDING_SKIPPED,
  SET_DASHBOARD_AGGREGATION_PARAMS,
  SAVE_DASHBOARD_FILTER,
  DELETE_DASHBOARD_FILTER,
} from "@kernel-store/actions";

export type TDashboardFilter = {
  assessment_types: any;
  assessors: any;
  start_date: any;
  end_date: any;
  metadata_array: any;
  groups: any;
  subject1_risk_levels: any;
};

export const EMPTY_FILTER: TDashboardFilter = {
  assessment_types: [],
  assessors: [],
  start_date: null,
  end_date: null,
  metadata_array: [],
  groups: [],
  subject1_risk_levels: [],
};

export type TSavedDashboardFilter = {
  name: string;
  filters: TDashboardFilter;
};

export type DashboardReduxState = {
  dashboardData: any;
  dashboardDataLoading: boolean;
  dashboardDataShortKey: string;
  dashboardFilters: any;
  dashboardDataDirty: boolean;
  dashboardFieldParam: string | null;
  dashboardGranularity: string | null;
  dashboardAggType: string | null;
  filterObject: TDashboardFilter;
  refresh: boolean;
  onboarding: string | null;
  onboardingFinished: string[];
  onboardingSkipped: boolean;
  savedDashboardFilters: TSavedDashboardFilter[];
};

export const INIT_STATE: DashboardReduxState = {
  dashboardData: {},
  dashboardDataLoading: false,
  dashboardDataShortKey: "",
  dashboardFilters: {},
  dashboardDataDirty: false,
  dashboardFieldParam: "",
  dashboardGranularity: "",
  dashboardAggType: "count_assessment",
  filterObject: EMPTY_FILTER,
  refresh: false,
  onboarding: null,
  onboardingFinished: [],
  onboardingSkipped: false,
  savedDashboardFilters: [],
};

const getUnvisited = (metadataSort: any[]) => {
  const arr = metadataSort.filter((m) => !m.visited);
  return arr;
};

const visit = (node: any, newMetadata: any[], metadata: any[]): void => {
  if (node.visited) {
    return;
  }
  const newNode = node;
  newNode.temp = true;
  if (node.data[2] != null) {
    const parentNode = metadata.filter((m) => m.data[1] === node.data[2])[0];
    visit(parentNode, newMetadata, metadata);
  }
  newNode.temp = false;
  newNode.visited = true;
  newMetadata.push(node);
};

const sortMetadataArray = (metadata: any[]): any[] => {
  const metadataSort = [];
  const newMetadata = [];
  for (let i = 0; i < metadata.length; i += 1) {
    metadataSort.push({
      data: metadata[i],
      visited: false,
    });
  }
  newMetadata.push(
    metadataSort.filter(
      (m) =>
        m.data[0] === "Assessment Version" || m.data[0] === "Attempt Number",
    )[0],
  );
  newMetadata[0].visited = true;
  let unvisited = getUnvisited(metadataSort);
  while (unvisited.length > 0) {
    const node = unvisited[0];
    visit(node, newMetadata, metadataSort);
    unvisited = getUnvisited(metadataSort);
  }
  const ret = [];
  for (let i = 0; i < newMetadata.length; i += 1) {
    ret.push(newMetadata[i].data);
  }
  return ret;
};

export default (
  state: DashboardReduxState = INIT_STATE,
  action: AnyAction = { type: undefined },
) => {
  switch (action.type) {
    case GET_DASHBOARD_DATA_REQUEST:
      return { ...state, dashboardDataLoading: true };

    case GET_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        dashboardData: action.payload.dashboardData,
        dashboardDataLoading: false,
      };
    case GET_DASHBOARD_DATA_ERROR:
      // TODO(znoland): consider setting data to zero to make it clear data is not available?
      return { ...state, dashboardDataLoading: false };

    case SET_DASHBOARD_DATA_SHORT_KEY:
      return {
        ...state,
        dashboardDataShortKey: action.payload.shortKey,
        dashboardDataLoading: false,
      };

    case GET_DASHBOARD_FILTERS_REQUEST:
      return { ...state };

    case GET_DASHBOARD_FILTERS_SUCCESS: {
      const metadataOptionList = sortMetadataArray(
        action.payload.dashboardFilters.metadataOptionList,
      );
      metadataOptionList.forEach((field) => {
        field[3].sort((a: any, b: any) => {
          if (field[0] === "Assessment Version") {
            return a.id - b.id;
          }
          if (a.label > b.label) {
            return 1;
          }
          if (b.label > a.label) {
            return -1;
          }
          return 0;
        });
      });
      return {
        ...state,
        dashboardFilters: {
          ...action.payload.dashboardFilters,
          metadataOptionList,
        },
      };
    }
    case GET_DASHBOARD_FILTERS_ERROR:
      return { ...state };

    case SET_DASHBOARD_FILTER_FIELD: {
      const stogareAdapter = TumekeJSModule.get(
        EAdapterNames.Storage,
      ) as IStorageAdapter;
      stogareAdapter.setItem(
        "filterObject",
        JSON.stringify({
          ...state.filterObject,
          [action.payload.field]: action.payload.value,
        }),
      );
      return {
        ...state,
        refresh: !state.refresh,
        filterObject: {
          ...state.filterObject,
          [action.payload.field]: action.payload.value,
        },
        dashboardDataLoading: true,
      };
    }
    case GET_DASHBOARD_DATA_EXTERNAL: {
      return { ...state, dashboardDataLoading: true };
    }
    case SET_DASHBOARD_FILTER_FIELDS: {
      return {
        ...state,
        filterObject: {
          ...action.payload.filterObject,
        },
      };
    }

    case SET_DASHBOARD_FILTERS_EXTERNAL: {
      return {
        ...state,
        filterObject: {
          ...action.payload.filterObject,
        },
      };
    }
    case CLEAR_DASHBOARD_FILTERS:
      return {
        ...state,
        filterObject: EMPTY_FILTER,
      };
    case SET_DASHBOARD_ONBOARDING: {
      return {
        ...state,
        onboarding: action.payload.value,
      };
    }
    case SET_DASHBOARD_AGGREGATION_PARAMS: {
      let fieldParam = action.payload.dashboardFieldParam;
      let granularityParam = action.payload.dashboardGranularity;
      let aggType = action.payload.dashboardAggType;
      if (fieldParam === null) {
        fieldParam = state.dashboardFieldParam;
      }
      if (granularityParam === null) {
        granularityParam = state.dashboardGranularity;
      }
      if (aggType === null) {
        aggType = state.dashboardAggType;
      }
      return {
        ...state,
        dashboardFieldParam: fieldParam,
        dashboardGranularity: granularityParam,
        dashboardAggType: aggType,
      };
    }
    case SET_DASHBOARD_ONBOARDING_FINISHED: {
      const newOnboardingFinished = state.onboardingFinished
        .filter((val) => val !== action.payload.value)
        .concat(action.payload.value);
      const stogareAdapter = TumekeJSModule.get(
        EAdapterNames.Storage,
      ) as IStorageAdapter;
      stogareAdapter.setItem(
        "onboardingFinished",
        JSON.stringify(newOnboardingFinished),
      );
      return {
        ...state,
        onboardingFinished: state.onboardingFinished
          .filter((val) => val !== action.payload.value)
          .concat(action.payload.value),
      };
    }
    case SET_DASHBOARD_ONBOARDING_SKIPPED: {
      return {
        ...state,
        onboardingSkipped: action.payload.value,
      };
    }
    case SAVE_DASHBOARD_FILTER: {
      const { index, name } = action.payload;
      let savedDashboardFilters: TSavedDashboardFilter[] = [
        ...state.savedDashboardFilters,
      ];
      if (index === null) {
        savedDashboardFilters.push({ name, filters: state.filterObject });
      } else {
        savedDashboardFilters = savedDashboardFilters.map(
          (savedDashboardFilter, idx) => {
            if (index === idx) {
              return { name, filters: state.filterObject };
            }
            return savedDashboardFilter;
          },
        );
      }
      const stogareAdapter = TumekeJSModule.get(
        EAdapterNames.Storage,
      ) as IStorageAdapter;
      stogareAdapter.setItem(
        "savedDashboardFilters",
        JSON.stringify(savedDashboardFilters),
      );

      return {
        ...state,
        savedDashboardFilters,
      };
    }
    case DELETE_DASHBOARD_FILTER: {
      const { index } = action.payload;
      const savedDashboardFilters = state.savedDashboardFilters.filter(
        (_, idx) => idx !== index,
      );
      const stogareAdapter = TumekeJSModule.get(
        EAdapterNames.Storage,
      ) as IStorageAdapter;
      stogareAdapter.setItem(
        "savedDashboardFilters",
        JSON.stringify(savedDashboardFilters),
      );

      return {
        ...state,
        savedDashboardFilters,
      };
    }
    default:
      return { ...state };
  }
};
