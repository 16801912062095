/* eslint-disable @typescript-eslint/no-use-before-define */
import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";
import { NotificationManager } from "@kernel-helpers/react-notifications";
import {
  addCompanyMetadataFieldHelper,
  addCompanyMetadataOptionHelper,
  deleteCompanyMetadataFieldHelper,
  deleteCompanyMetadataOptionHelper,
  editCompanyMetadataFieldHelper,
  editCompanyMetadataOptionHelper,
  getCompaniesData,
  updateCompanyPolicies,
} from "@kernel-helpers/DatabaseHelpers";
import { Mixpanel } from "@kernel-helpers/Mixpanel";

import {
  ADD_COMPANY_METADATA_FIELD,
  ADD_COMPANY_METADATA_OPTION,
  DELETE_COMPANY_METADATA_FIELD,
  DELETE_COMPANY_METADATA_OPTION,
  EDIT_COMPANY_METADATA_FIELD,
  EDIT_COMPANY_METADATA_OPTION,
  GET_COMPANIES_DATA_REQUEST,
  SAVE_POLICES_REQUEST,
} from "@kernel-store/actions";
import { ReduxState } from "@kernel-store/reducers";

import {
  getCompaniesDataSuccess,
  getCompaniesDataError,
  savePolicesRequestError,
  savePolicesRequestSuccess,
} from "./actions";

import { loginUserSuccess, setCompanyMetadataSuccess } from "../auth/actions";

export function* watchSavePolicesRequest() {
  yield takeEvery(SAVE_POLICES_REQUEST, savePolicesRequestSaga);
}

export function* watchGetCompaniesDataRequest() {
  yield takeEvery(GET_COMPANIES_DATA_REQUEST, getCompaniesDataRequestSaga);
}

export function* watchAddMetadataFieldRequest() {
  yield takeEvery(ADD_COMPANY_METADATA_FIELD, addMetadataFieldSaga);
}

export function* watchEditMetadataFieldRequest() {
  yield takeEvery(EDIT_COMPANY_METADATA_FIELD, editMetadataFieldSaga);
}

export function* watchDeleteMetadataFieldRequest() {
  yield takeEvery(DELETE_COMPANY_METADATA_FIELD, deleteMetadataFieldSaga);
}

export function* watchAddMetadataOptionRequest() {
  yield takeEvery(ADD_COMPANY_METADATA_OPTION, addMetadataOptionSaga);
}

export function* watchEditMetadataOptionRequest() {
  yield takeEvery(EDIT_COMPANY_METADATA_OPTION, editMetadataOptionSaga);
}

export function* watchDeleteMetadataOptionRequest() {
  yield takeEvery(DELETE_COMPANY_METADATA_OPTION, deleteMetadataOptionSaga);
}

export async function mixpanelTrackEvent(
  event: string,
  props?: any,
): Promise<void> {
  await Mixpanel.track(event, props);
}

const handleMetadataError = (e: any) => {
  if (e.response) {
    // TODO Change this
    NotificationManager.warning(
      "Error",
      e.response.data.message,
      3000,
      null,
      null,
      "",
    );
    return;
  }
  NotificationManager.warning(
    "Error",
    "Unknown server error. Please try again",
    3000,
    null,
    null,
    "",
  );
};

function* savePolicesRequestSaga({ payload }: any) {
  const state: ReduxState = yield select();
  const { companyId, policiesData } = payload;
  let companyData = null;
  try {
    companyData = (yield call(
      updateCompanyPolicies,
      companyId,
      policiesData,
    )) as unknown as any;
    if (companyData.id === state.authUser.company.id) {
      yield put(
        loginUserSuccess(state.authUser.user, {
          ...state.authUser.company,
          policies: companyData.policies,
        }),
      );
    }
  } catch (e: any) {
    NotificationManager.warning(
      e.response?.data?.message ||
        "Error getting data. Please take a screenshot and contact support.",
      "Error Getting Data",
      5000,
      null,
      null,
      "",
    );
    yield put(savePolicesRequestError());
    return;
  }
  yield put(savePolicesRequestSuccess(companyId, companyData));
}

function* getCompaniesDataRequestSaga({ payload }: any) {
  const { companiesByFieldParam } = payload;
  let companiesData = null;
  try {
    companiesData = (yield call(
      getCompaniesData,
      companiesByFieldParam,
    )) as unknown as any;
  } catch (e) {
    yield put(getCompaniesDataError());
    return;
  }
  yield put(getCompaniesDataSuccess(companiesData));
}

function* addMetadataFieldSaga({ payload }: any) {
  const { companyId, fieldName, fieldType, parentFieldId } = payload;

  let ret;
  try {
    ret = (yield call(
      addCompanyMetadataFieldHelper,
      companyId,
      fieldName,
      fieldType,
      parentFieldId,
    )) as unknown as any;

    console.log("ret", ret);
  } catch (e) {
    handleMetadataError(e);

    return;
  }
  NotificationManager.success(
    `"${fieldName}" added as field`,
    "Field added",
    3000,
    null,
    null,
    "filled",
  );
  yield put(setCompanyMetadataSuccess(ret.metadata));

  yield call(mixpanelTrackEvent, "RS - Event - Add Metadata Field", {
    Field: fieldName,
  });
}

function* editMetadataFieldSaga({ payload }: any) {
  const { companyId, fieldId, fieldName, fieldType, parentFieldId } = payload;
  let ret;
  try {
    ret = (yield call(
      editCompanyMetadataFieldHelper,
      companyId,
      fieldId,
      fieldName,
      fieldType,
      parentFieldId,
    )) as unknown as any;
  } catch (e) {
    handleMetadataError(e);
    return;
  }

  NotificationManager.success(
    `"${fieldName}" changed successfully`,
    "Field edit",
    3000,
    null,
    null,
    "filled",
  );

  yield put(setCompanyMetadataSuccess(ret.metadata));
  yield call(mixpanelTrackEvent, "RS - Event - Edit Metadata Field", {
    Field: fieldName,
  });
}

function* deleteMetadataFieldSaga({ payload }: any) {
  const { companyId, fieldId, fieldName } = payload;
  let ret;
  try {
    ret = (yield call(
      deleteCompanyMetadataFieldHelper,
      companyId,
      fieldId,
    )) as unknown as any;
  } catch (e) {
    handleMetadataError(e);

    return;
  }

  NotificationManager.success(
    `"${fieldName}" deleted as option`,
    "Field deleted",
    3000,
    null,
    null,
    "filled",
  );

  yield put(setCompanyMetadataSuccess(ret.metadata));
  yield call(mixpanelTrackEvent, "RS - Event - Delete Metadata Field");
}

function* addMetadataOptionSaga({ payload }: any) {
  const { companyId, fieldId, optionName, parentOptionId, fieldName } = payload;

  let ret;
  try {
    ret = (yield call(
      addCompanyMetadataOptionHelper,
      companyId,
      fieldId,
      optionName,
      parentOptionId,
    )) as unknown as any;
  } catch (e) {
    handleMetadataError(e);

    return;
  }
  NotificationManager.success(
    `"${optionName}" added as option`,
    "Option added",
    3000,
    null,
    null,
    "filled",
  );
  yield put(setCompanyMetadataSuccess(ret.metadata));

  yield call(mixpanelTrackEvent, "RS - Event - Add Metadata Option", {
    Field: fieldName,
  });
}

function* editMetadataOptionSaga({ payload }: any) {
  const { companyId, optionId, optionName, fieldName, parentOptionId } =
    payload;
  let ret;
  try {
    ret = (yield call(
      editCompanyMetadataOptionHelper,
      companyId,
      optionId,
      optionName,
      parentOptionId,
    )) as unknown as any;
  } catch (e) {
    handleMetadataError(e);
    return;
  }

  NotificationManager.success(
    `"${optionName}" changed successfully`,
    "Option edit",
    3000,
    null,
    null,
    "filled",
  );

  yield put(setCompanyMetadataSuccess(ret.metadata));
  yield call(mixpanelTrackEvent, "RS - Event - Edit Metadata Option", {
    Field: fieldName,
  });
}

function* deleteMetadataOptionSaga({ payload }: any) {
  const { companyId, optionId, optionName } = payload;
  let ret;
  try {
    ret = (yield call(
      deleteCompanyMetadataOptionHelper,
      companyId,
      optionId,
    )) as unknown as any;
  } catch (e) {
    handleMetadataError(e);

    return;
  }

  NotificationManager.success(
    `"${optionName}" deleted as option`,
    "Option deleted",
    3000,
    null,
    null,
    "filled",
  );

  yield put(setCompanyMetadataSuccess(ret.metadata));
  yield call(mixpanelTrackEvent, "RS - Event - Delete Metadata Option");
}

export default function* rootSaga() {
  yield all([
    fork(watchGetCompaniesDataRequest),
    fork(watchSavePolicesRequest),
    fork(watchAddMetadataFieldRequest),
    fork(watchEditMetadataFieldRequest),
    fork(watchDeleteMetadataFieldRequest),
    fork(watchAddMetadataOptionRequest),
    fork(watchEditMetadataOptionRequest),
    fork(watchDeleteMetadataOptionRequest),
  ]);
}
