import { AnyAction } from "@reduxjs/toolkit";

import { Assessments } from "@kernel-constants/maps";
import { getAssessmentScore } from "@kernel-helpers/assessments";
import {
  GET_IMPROVEMENTS_REQUEST,
  GET_IMPROVEMENTS_SUCCESS,
  ADD_IMPROVEMENT_SUCCESS,
  SET_IMPROVEMENTS_LOADING,
  GET_IMPROVEMENT_THUMBNAIL_SUCCESS,
  SAVE_IMPROVEMENT_REQUEST,
  SET_IMPROVEMENT_PAGE_METADATA,
  DELETE_IMPROVEMENT_REQUEST,
  DELETE_IMPROVEMENT_SUCCESS,
  DELETE_IMPROVEMENT_ERROR,
} from "@kernel-store/actions";

export type TImpovementFilter = {
  status?: string;
};

export type TImpovementPageMetadata = {
  currentPage: number;
  pageSize: number;
  filter: TImpovementFilter;
};

export type ImprovementsReduxState = {
  list: any;
  count: number;
  loading: boolean;
  saving: boolean;
  deleting: boolean;
  pageMetadata: TImpovementPageMetadata;
};

export const INIT_STATE: ImprovementsReduxState = {
  list: {},
  count: 0,
  loading: false,
  pageMetadata: {
    currentPage: 1,
    pageSize: 8,
    filter: {},
  },
  saving: false,
  deleting: false,
};

export default (
  state: ImprovementsReduxState = INIT_STATE,
  action: AnyAction = { type: undefined },
): ImprovementsReduxState => {
  switch (action.type) {
    case GET_IMPROVEMENTS_REQUEST: {
      return { ...state };
    }
    case GET_IMPROVEMENTS_SUCCESS: {
      const { count } = action.payload;
      return { ...state, count, loading: false };
    }
    case SET_IMPROVEMENTS_LOADING: {
      const { isLoading } = action.payload;
      const newImprovements = {};
      Object.keys(state.list).forEach((key) => ({
        ...state.list[key],
        visible: false,
      }));

      return {
        ...state,
        list: newImprovements,
        loading: isLoading,
      };
    }
    case ADD_IMPROVEMENT_SUCCESS: {
      const { improvementData } = action.payload;
      const key = String(improvementData.id);

      let video: any = {};
      let videoAfter: any = {};
      let assessment: any = state.list[key] ? state.list[key].assessment : {};
      let assessmentAfter: any = {};
      (improvementData?.videos_before || []).some((vid: any) =>
        (vid.tasks || []).some((task: any) =>
          (task.assessments || []).some((taskAssessment: any) => {
            video = vid;
            assessment = {
              ...assessment,
              ...taskAssessment,
            };
            return true;
          }),
        ),
      );
      (improvementData?.videos_after || []).some((vid: any) =>
        (vid.tasks || []).some((task: any) =>
          (task.assessments || []).some((taskAssessment: any) => {
            videoAfter = vid;
            assessmentAfter = taskAssessment;
            return true;
          }),
        ),
      );
      const improvedAssessment = {
        ...(state.list[key] ? state.list[key].improvedAssessment : {}),
        ...improvementData.improvement_assessments[0],
      };

      let score = -1;
      const assessmentResult = assessment?.posture_assessments
        ? assessment?.posture_assessments[0].riskAssessment?.assessmentResult
        : {};
      if (typeof assessment?.data?.score !== "undefined") {
        score = assessment?.data?.score;
      } else if (typeof assessmentResult?.Score !== "undefined") {
        score = assessmentResult.Score;
      } else if (assessmentResult?.OverallScore !== "undefined") {
        score = assessmentResult.OverallScore;
      } else if (assessmentResult?.li !== "undefined") {
        score = assessmentResult.li;
      } else if (assessmentResult?.mal !== "undefined") {
        score = assessmentResult.mal;
      }
      const currentScore = getAssessmentScore(
        assessment?.data?.assessmentType,
        score,
      );
      const improvedScore = getAssessmentScore(
        improvedAssessment?.data?.assessmentType,
        improvedAssessment?.data?.score,
      );
      let componentName = "";
      if (
        [Assessments.RULA, Assessments.REBA].includes(
          improvedAssessment?.data?.assessmentType,
        )
      ) {
        componentName = "rulareba";
      }
      if (
        improvedAssessment?.data?.assessmentType === Assessments.HAND_STRAIN
      ) {
        componentName = "handstrain";
      }

      const improvement = {
        id: improvementData.id,
        name: improvementData.name,
        assessmentType: improvedAssessment?.data?.assessmentType,
        dueDate: improvementData.due_date,
        metadata: improvementData.metadata,
        status: improvementData.status,
        owner: improvementData.owner,
        performer: improvementData.performer,
        problems: improvementData.problems,
        roi: improvementData.roi,
        thumbnailLoc: state.list[key]?.thumbnailLoc || "",
        createdAt: improvementData.created_at,
        updatedAt: improvementData.updated_at,
        visible: true,
        video,
        assessment,
        improvedAssessment,
        videoAfter,
        assessmentAfter,
        currentScore,
        improvedScore,
        componentName,
      };

      return {
        ...state,
        list: {
          ...state.list,
          [key]: improvement,
        },
        saving: false,
      };
    }
    case GET_IMPROVEMENT_THUMBNAIL_SUCCESS: {
      const { videoId, realThumbnailLoc } = action.payload;

      const newImprovements: any = {};
      Object.keys(state.list).forEach((improvementId) => {
        if (state.list[improvementId].video?.id === videoId) {
          newImprovements[improvementId] = {
            ...state.list[improvementId],
            thumbnailLoc: realThumbnailLoc,
          };
        } else {
          newImprovements[improvementId] = state.list[improvementId];
        }
      });

      return {
        ...state,
        list: newImprovements,
      };
    }
    case SAVE_IMPROVEMENT_REQUEST: {
      return { ...state, saving: true };
    }
    case SET_IMPROVEMENT_PAGE_METADATA: {
      const newPayload = { ...action.payload };
      Object.keys(newPayload).forEach((key) => {
        if (newPayload[key] === undefined) {
          delete newPayload[key];
        }
      });

      return {
        ...state,
        pageMetadata: {
          ...state.pageMetadata,
          ...newPayload,
        },
      };
    }
    case DELETE_IMPROVEMENT_REQUEST: {
      return { ...state, deleting: true };
    }
    case DELETE_IMPROVEMENT_ERROR: {
      return { ...state, deleting: false };
    }
    case DELETE_IMPROVEMENT_SUCCESS: {
      const { improvementId } = action.payload;
      const newImprovements = { ...state.list };
      delete newImprovements[improvementId];

      return {
        ...state,
        list: newImprovements,
        deleting: false,
      };
    }
    default:
      return { ...state };
  }
};
