import { AnyAction } from "@reduxjs/toolkit";
import {
  ADD_NOTIFICATION_RECORD,
  DELETE_NOTIFICATION_RECORD,
  START_NOTIFICATION_LISTENER,
  SET_LISTENER_ACTIVE,
} from "@kernel-store/actions";

export type NotificationsReduxState = {
  notificationList: any[];
  listenerActive: boolean;
};

export const INIT_STATE: NotificationsReduxState = {
  notificationList: [],
  listenerActive: false,
};

export default (
  state = INIT_STATE,
  action: AnyAction = { type: undefined },
) => {
  switch (action.type) {
    case SET_LISTENER_ACTIVE:
      return { ...state, listenerActive: true };
    case START_NOTIFICATION_LISTENER:
      return { ...state };
    case ADD_NOTIFICATION_RECORD: {
      if (
        state.notificationList.some(
          (notification) => notification.key === action.payload.key,
        )
      ) {
        return {
          ...state,
        };
      }
      const arr = [
        ...state.notificationList,
        {
          ...action.payload.notification,
          key: action.payload.key,
        },
      ];

      return {
        ...state,
        notificationList: arr.sort((a, b) => b.date - a.date),
      };
    }
    case DELETE_NOTIFICATION_RECORD:
      console.log("IN REDUCER FOR DELETE notification");
      return {
        ...state,
        notificationList: state.notificationList.filter(
          (notification) => notification.key !== action.payload.notificationId,
        ),
      };

    default:
      return { ...state };
  }
};
