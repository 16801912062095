import { AnyAction } from "@reduxjs/toolkit";
import {
  GET_COMPANIES_DATA_REQUEST,
  GET_COMPANIES_DATA_SUCCESS,
  GET_COMPANIES_DATA_ERROR,
  GET_COMPANIES_FILTERS_REQUEST,
  GET_COMPANIES_FILTERS_SUCCESS,
  GET_COMPANIES_FILTERS_ERROR,
  SET_COMPANIES_FILTER_FIELD,
  CLEAR_COMPANIES_FILTERS,
  SAVE_POLICES_REQUEST,
  SAVE_POLICES_REQUEST_ERROR,
  SAVE_POLICES_REQUEST_SUCCESS,
  ADD_COMPANY_METADATA_FIELD,
  EDIT_COMPANY_METADATA_FIELD,
  DELETE_COMPANY_METADATA_FIELD,
  ADD_COMPANY_METADATA_OPTION,
  EDIT_COMPANY_METADATA_OPTION,
  DELETE_COMPANY_METADATA_OPTION,
} from "@kernel-store/actions";

export enum AssessmentTypes {
  RULA = 1,
  REBA = 2,
  NIOSH = 3,
  HandStrain = 4,
  LibertyMutual = 5,
  Snook = 5,
  Training = 6,
}

export interface ICompanyMandatoryMetadataInputs {
  [key: string]: boolean;
}

export const savePolicesRequest = (
  companyId: number,
  policiesData: {
    companyName: string;
    metadata: {
      [key: string]: any;
      edit_role: string;
    };
    faceBlur: boolean;
    backgroundBlur: boolean;
    devices: string[];
    notes: {
      [key: string]: any;
      taskDescription: boolean;
      suggestionsForImprovement: boolean;
    };
    reportFormat: {
      [key: string]: any;
      chubbFormat: boolean;
    };
    sendAcceptConfirmEmail: boolean;
    defaultAssessmentType: AssessmentTypes;
    mandatoryMetadataInputs: ICompanyMandatoryMetadataInputs[];
    scrambleFilesPreupload: boolean;
    blockRSUpload: boolean;
    nonAdminBlockVideoDownload: boolean;
    hasGPT: boolean;
    hasTrainingFeature: boolean;
    is_lcp: boolean;
    assessmentLimit: number;
    limitType: number;
    meterStartDate: string | null;
    userLimit: number;
    maintainers: string[];
    allowBulkExport: boolean;
  },
): AnyAction => ({
  type: SAVE_POLICES_REQUEST,
  payload: { companyId, policiesData },
});

export const savePolicesRequestSuccess = (
  companyId: number,
  companyData: any,
): AnyAction => ({
  type: SAVE_POLICES_REQUEST_SUCCESS,
  payload: { companyId, companyData },
});

export const savePolicesRequestError = (): AnyAction => ({
  type: SAVE_POLICES_REQUEST_ERROR,
  payload: {},
});

export const getCompaniesDataRequest = (companiesByFieldParam: {
  page: number;
  page_size: number;
  search: string;
}): AnyAction => ({
  type: GET_COMPANIES_DATA_REQUEST,
  payload: { companiesByFieldParam },
});

export const getCompaniesDataSuccess = (companiesData: any): AnyAction => ({
  type: GET_COMPANIES_DATA_SUCCESS,
  payload: { companiesData },
});

export const getCompaniesDataError = (): AnyAction => ({
  type: GET_COMPANIES_DATA_ERROR,
  payload: {},
});

export const getCompaniesFiltersRequest = (): AnyAction => ({
  type: GET_COMPANIES_FILTERS_REQUEST,
  payload: {},
});

export const getCompaniesFiltersSuccess = (
  companiesFilters: any[],
): AnyAction => ({
  type: GET_COMPANIES_FILTERS_SUCCESS,
  payload: { companiesFilters },
});

export const getCompaniesFiltersError = (): AnyAction => ({
  type: GET_COMPANIES_FILTERS_ERROR,
  payload: {},
});

export const setCompaniesFilterField = (
  field: string,
  value: string,
): AnyAction => ({
  type: SET_COMPANIES_FILTER_FIELD,
  payload: { field, value },
});

export const clearCompaniesFilters = (): AnyAction => ({
  type: CLEAR_COMPANIES_FILTERS,
  payload: {},
});

export const addCompanyMetadataField = (
  companyId: number,
  fieldName: string,
  fieldType: string,
  fieldParentId: number | null,
): AnyAction => ({
  type: ADD_COMPANY_METADATA_FIELD,
  payload: { companyId, fieldName, fieldType, fieldParentId },
});

export const editCompanyMetadataField = (
  companyId: number,
  fieldId: string,
  fieldName: string,
  fieldType: string,
  fieldParentId: number | null,
): AnyAction => ({
  type: EDIT_COMPANY_METADATA_FIELD,
  payload: { companyId, fieldId, fieldName, fieldType, fieldParentId },
});

export const deleteCompanyMetadataField = (
  companyId: number,
  fieldId: number,
  fieldName: string,
): AnyAction => ({
  type: DELETE_COMPANY_METADATA_FIELD,
  payload: { companyId, fieldId, fieldName },
});

export const addCompanyMetadataOption = (
  fieldId: string,
  optionName: string,
  parentOptionId: string,
  fieldName: string,
): AnyAction => ({
  type: ADD_COMPANY_METADATA_OPTION,
  payload: { fieldId, optionName, parentOptionId, fieldName },
});

export const editCompanyMetadataOption = (
  optionId: string,
  optionName: string,
  fieldName: string,
): AnyAction => ({
  type: EDIT_COMPANY_METADATA_OPTION,
  payload: { optionId, optionName, fieldName },
});

export const deleteCompanyMetadataOption = (
  optionId: string,
  optionName: string,
): AnyAction => ({
  type: DELETE_COMPANY_METADATA_OPTION,
  payload: { optionId, optionName },
});
