import { AnyAction } from "@reduxjs/toolkit";
import {
  GET_USERS_DATA_REQUEST,
  GET_USERS_DATA_SUCCESS,
  GET_USERS_DATA_ERROR,
  GET_USERS_FILTERS_REQUEST,
  GET_USERS_FILTERS_SUCCESS,
  GET_USERS_FILTERS_ERROR,
  SET_USERS_FILTER_FIELD,
  CLEAR_USERS_FILTERS,
} from "@kernel-store/actions";

export const getUsersDataRequest = (usersByFieldParam: {
  page: number;
  page_size: number;
  search: string;
  company_id?: number;
}): AnyAction => ({
  type: GET_USERS_DATA_REQUEST,
  payload: { usersByFieldParam },
});

export const getUsersDataSuccess = (usersData: any): AnyAction => ({
  type: GET_USERS_DATA_SUCCESS,
  payload: { usersData },
});

export const getUsersDataError = (): AnyAction => ({
  type: GET_USERS_DATA_ERROR,
  payload: {},
});

export const getUsersFiltersRequest = (): AnyAction => ({
  type: GET_USERS_FILTERS_REQUEST,
  payload: {},
});

export const getUsersFiltersSuccess = (usersFilters: any[]): AnyAction => ({
  type: GET_USERS_FILTERS_SUCCESS,
  payload: { usersFilters },
});

export const getUsersFiltersError = (): AnyAction => ({
  type: GET_USERS_FILTERS_ERROR,
  payload: {},
});

export const setUsersFilterField = (
  field: string,
  value: string,
): AnyAction => ({
  type: SET_USERS_FILTER_FIELD,
  payload: { field, value },
});

export const clearUsersFilters = (): AnyAction => ({
  type: CLEAR_USERS_FILTERS,
  payload: {},
});
