import { AnyAction } from "@reduxjs/toolkit";
import { TumekeJSModule, EAdapterNames } from "@kernel";
import { IStorageAdapter } from "@kernel-adapters/storage";
import { CHANGE_LOCALE, SET_WEBAPP_LOGO } from "@kernel-store/actions";

export const changeLocale = (locale: string): AnyAction => {
  const storageAdapter = TumekeJSModule.get(
    EAdapterNames.Storage,
  ) as IStorageAdapter;
  storageAdapter.setItem("currentLanguage", JSON.stringify(locale));
  return {
    type: CHANGE_LOCALE,
    payload: { locale },
  };
};

export const setWebAppLogo = (logo: string): AnyAction => ({
  type: SET_WEBAPP_LOGO,
  payload: { logo },
});
