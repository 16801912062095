/* eslint-disable @typescript-eslint/no-throw-literal */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";
import {
  cognitoAuthUser,
  cognitoRegisterUser,
  cognitoChangePasswordWeb,
  cognitoRefreshTokenWeb,
  asyncStore,
  asyncStoreIdToken,
  cognitoSendVerificationcode,
  cognitoSignoutUser,
} from "@tumeke/tumekejs";

import {
  loginUser,
  createUser,
  createCompany,
  joinCompanyRequest,
  getCompanyById,
  checkLoginStatus,
  deleteTempPassword,
  setUserRole,
  checkAccountStatus,
  checkCompanyUserLimit,
  updateCompanySettingsHelper,
  saveUserData,
} from "@kernel-helpers/DatabaseHelpers";
import { handleVideoError } from "@kernel-helpers/ErrorHandle";
import { ReduxState } from "@kernel-store/reducers";

import {
  initializeVirgil,
  isVirgilInitialized,
  logoutVirgil,
  createNewUserVirgil,
  joinGroup,
  decryptAESKeys,
  changePassword,
  restorePrivateKey,
  initiateResetPasswordFlow,
  confirmPasswordReset,
} from "@tumeke/tumekejs/lib/auth";

import { TumekeJSModule, EAdapterNames, Config } from "@kernel";
import { IStorageAdapter } from "@kernel-adapters/storage";
import { NotificationManager } from "@kernel-helpers/react-notifications";
import { Mixpanel } from "@kernel-helpers/Mixpanel";
import * as Sentry from "@sentry/browser";

import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  SET_DECYPTED_AES_KEYS_REQUEST,
  SET_DECYPTED_AES_KEYS_SUCCESS,
  ENTERED_PASSWORD_REQUEST,
  CHECK_PASSWORD,
  SET_COMPANY_METADATA_SUCCESS,
  FORGOT_PASSWORD_CONFIRM,
  FORGOT_PASSWORD_CONFIRM_SUCCESS,
  ENTER_MULTIFACTOR_CODE,
  LOGIN_SERVER_HELPER,
  LOGIN_USER_SUCCESS,
  SET_USER_ROLE_REQUEST,
  CHECK_TRIAL_STATUS,
  UPDATE_COMPANY_SETTINGS,
  UPDATE_USER_SETTINGS,
} from "../actions";

import {
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordConfirmSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
  setDecryptedAESKeysSuccess,
  cancelAskPassword,
  enterPasswordError,
  logoutUser,
  virgilInitError,
  setDecyptingKeys,
  setDecryptedAESKeysRequest,
  setMultifactorStatus,
  loginServerHelper,
  setCompanyMetadataSuccess,
  registerUserStatus,
  increaseCompanyUserCount,
  updateCompanySettingsSuccess,
} from "./actions";

import { ERegisterStatus } from "./reducer";

import { removeVideoListeners } from "../videos/actions";

import { removeNotificationListener } from "../notifications/actions";

import {
  addUserToGroupSuccess,
  acceptUserIntoCompanyRequest,
  addUserToGroupRequest,
  createNewGroupRequest,
} from "../adminProfile/actions";

// const EXPIRATION_TIME_MINS = 5;

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

export function* watchRequestAESDecryptSuccess() {
  yield takeEvery(SET_DECYPTED_AES_KEYS_SUCCESS, aesKeySuccessSaga);
}

export function* watchEnteredPasswordRequest() {
  yield takeEvery(ENTERED_PASSWORD_REQUEST, enteredPasswordRequestSaga);
}

export function* watchRequestAESDecrypt() {
  yield takeEvery(SET_DECYPTED_AES_KEYS_REQUEST, decryptAESKeysSaga);
}

export function* watchSetCompanyMetadataSuccess() {
  yield takeEvery(SET_COMPANY_METADATA_SUCCESS, setCompanyMetadataSuccessSaga);
}

export function* watchCheckPassword() {
  yield takeEvery(CHECK_PASSWORD, checkPasswordSaga);
}

export function* watchForgotPasswordConfirm() {
  yield takeEvery(FORGOT_PASSWORD_CONFIRM, forgotPasswordConfirm);
}

export function* watchForgotPasswordSuccess() {
  yield takeEvery(FORGOT_PASSWORD_CONFIRM_SUCCESS, forgotPasswordSuccessSaga);
}

export function* watchEnterMultifactorCode() {
  yield takeEvery(ENTER_MULTIFACTOR_CODE, enterMultifactorCodeSaga);
}

export function* watchLoginServerHelper() {
  yield takeEvery(LOGIN_SERVER_HELPER, loginServerSaga);
}

export function* watchLoginSuccessHelper() {
  yield takeEvery(LOGIN_USER_SUCCESS, loginSuccessSaga);
}

export function* watchSetUserRoleRequest() {
  yield takeEvery(SET_USER_ROLE_REQUEST, setUserRoleSaga);
}

export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, registerUser);
}

export function* watchCheckTrialStatus() {
  yield takeEvery(CHECK_TRIAL_STATUS, checkTrialStatusSaga);
}

export function* watchUpdateCompanySettings() {
  yield takeEvery(UPDATE_COMPANY_SETTINGS, updateCompanySettings);
}

export function* watchUpdateUserSettings() {
  yield takeEvery(UPDATE_USER_SETTINGS, updateUserSettings);
}

const cognitologinWithEmailPasswordAsync = async (
  email: string,
  password: string,
  onlyCheck = false,
) =>
  cognitoAuthUser(email, password, onlyCheck)
    .then((authUser) => authUser)
    .catch((error) => error);

const cognitoSendSMSAsync = async (email: string, code: string): Promise<any> =>
  cognitoSendVerificationcode(email, code)
    .then((msg) => msg)
    .catch((error) => error);

function* aesKeySuccessSaga({ payload }: any) {
  yield 0;
  console.log("in setting keys success");
  if (payload.callback === undefined) return;
  console.log("Calling callback");
  payload.callback();
}

function* decryptAESKeysSaga({ payload }: any) {
  // TODO: Clean up this logic
  const state: ReduxState = yield select();
  if (state.authUser.decryptingKeys) {
    return;
  }
  yield put(setDecyptingKeys(true));
  if (!state.authUser.user) {
    yield put(setDecyptingKeys(false));
    return;
  }
  if (
    Object.prototype.hasOwnProperty.call(
      state.authUser.user,
      "decryptedAESKeys",
    )
  ) {
    yield put(setDecyptingKeys(false));
    return;
  }
  if (state.authUser.user.isSSO || state.authUser.company.virgil_off) {
    console.log("Company AES", state.authUser.company.aes_key);
    yield put(
      setDecryptedAESKeysSuccess(
        state.authUser.company.aes_key,
        payload.callback,
      ),
    );
    return;
  }
  if (isVirgilInitialized()) {
    try {
      const decryptedKeys = (yield call(
        decryptAESKeys,
        state.authUser.user,
        state.authUser.company,
      )) as unknown as any;
      yield put(setDecryptedAESKeysSuccess(decryptedKeys, payload.callback));
      yield put(setDecyptingKeys(false));
      return;
    } catch (e) {
      yield put(virgilInitError());
      yield put(setDecyptingKeys(false));
      return;
    }
  }
  // const {uid} = state.authUser.user;
  const password = payload.password ? payload.password : "";
  try {
    yield call(initializeVirgil, state.authUser.user.virgil_id);
    yield call(restorePrivateKey, password);
    yield call(joinGroup, state.authUser.user, state.authUser.company);
  } catch (e) {
    console.log(`DECRYPT KEYS VIRGIL ERROR: ${e}`);
    yield put(virgilInitError());
    yield put(setDecyptingKeys(false));
    return;
  }
  try {
    const decryptedKeys = (yield call(
      decryptAESKeys,
      state.authUser.user,
      state.authUser.company,
    )) as unknown as any;
    yield put(setDecryptedAESKeysSuccess(decryptedKeys, payload.callback));
    yield put(cancelAskPassword());
  } catch (error) {
    console.log(error);
    yield put(enterPasswordError("Password incorrect"));
  }

  yield put(setDecyptingKeys(false));
}

function* enteredPasswordRequestSaga({ payload }: any) {
  console.log(payload);
  const { password } = payload;
  const state: ReduxState = yield select();
  // const {uid} = state.authUser.user;
  try {
    yield call(initializeVirgil, state.authUser.user.virgil_id);
    yield call(restorePrivateKey, password);
    yield call(joinGroup, state.authUser.user, state.authUser.company);

    const decryptedKeys = (yield call(
      decryptAESKeys,
      state.authUser.user,
      state.authUser.company,
    )) as unknown as any;
    yield put(setDecryptedAESKeysSuccess(decryptedKeys));
    yield put(cancelAskPassword());
  } catch (error) {
    console.log(error);
    yield put(enterPasswordError("Password incorrect"));
  }
}

const checkLoginStatusHelper = async () => {
  try {
    const cognitoUser = await cognitoRefreshTokenWeb();
    const idToken = cognitoUser.getIdToken().getJwtToken();
    await asyncStoreIdToken(idToken);
    await checkLoginStatus();
    return true;
  } catch (e) {
    console.log(e);
    console.log("[CHECK] caught error!");
  }
  return false;
};

function* checkPasswordSaga({ payload }: any) {
  // const state: ReduxState = yield select();
  console.log("[CHECK] Entered check password saga");
  const { history } = payload;
  try {
    const ret = (yield call(checkLoginStatusHelper)) as unknown as any;
    if (!ret) {
      throw "Inactive error";
    }
  } catch (e) {
    console.log("[CHECK] BAD");
    console.log(`[CHECK] ${JSON.stringify(e)}`);
    yield put(logoutUser(history));
    NotificationManager.warning(
      "Logout",
      "Logged out due to inactivity. Please log back in.",
      3000,
      null,
      null,
      "",
    );
  }
}

function* setUserRoleSaga({ payload }: any) {
  // const state: ReduxState = yield select();
  const { userId, role } = payload;
  try {
    const userGroups = (yield call(
      setUserRole,
      userId,
      role,
    )) as unknown as any;
    yield put(addUserToGroupSuccess(userGroups));
  } catch (e) {
    console.log("USER ROLE ERROR", e);
  }
}

function* setCompanyMetadataSuccessSaga() {
  const state: ReduxState = yield select();
  const companyObj = state.authUser.company;
  yield call(asyncStore, "companyObj", JSON.stringify(companyObj));
}

function* loginSuccessSaga({ payload }: any) {
  const { company } = payload;
  yield put(setCompanyMetadataSuccess(company.metadata));
}

function* loginServerSaga({ payload }: any) {
  console.log("IN LOGIN SERVER SAGA");
  const { email, password, isSSO, clientId } = payload.user;
  const { history, cognitoUser } = payload;
  try {
    const idToken = cognitoUser.getIdToken().getJwtToken();
    yield call(asyncStoreIdToken, idToken);
    const userObj = (yield call(loginUser)) as unknown as any;

    if (userObj.role === "none" || userObj.role === "rejected") {
      yield put(loginUserError("User not authorized to access this website."));
      return;
    }
    if (!userObj.has_paid_deal && !userObj.trial_active) {
      yield put(
        loginUserError(
          `Your trial has expired. To move on to a paid plan please contact: ${Config.SUPPORT_EMAIL}`,
        ),
      );
      if (history) {
        history.push("/user/trial-expired");
      }
      return;
    }
    const companyObj = (yield call(
      getCompanyById,
      userObj.company_id,
    )) as unknown as any;

    const userCookie = payload.user.isDemo ? "demoUserObj" : "userObj";
    // const companyCookie = payload.user.isDemo ? "demoCompanyObj" : "companyObj";
    userObj.isSSO = isSSO;
    if (clientId) userObj.clientId = clientId;
    yield call(asyncStore, userCookie, JSON.stringify(userObj));
    yield put(loginUserSuccess(userObj, companyObj));
    yield put(setDecryptedAESKeysRequest(() => {}, password));
    if (history) {
      history.push("/app/home/home-nav");
    }
    yield call(initializeMixpanel, email);
  } catch (e) {
    console.log(e);
    yield put(loginUserError("Login error"));
    return;
  }
  const stogareAdapter = TumekeJSModule.get(
    EAdapterNames.Storage,
  ) as IStorageAdapter;
  yield call(stogareAdapter.removeItem, "email");
  yield call(stogareAdapter.removeItem, "password");
}

function* enterMultifactorCodeSaga({ payload }: any) {
  const { code, history } = payload;
  const stogareAdapter = TumekeJSModule.get(
    EAdapterNames.Storage,
  ) as IStorageAdapter;
  try {
    const email: string = yield call(stogareAdapter.getItem, "email");
    const password: string = yield call(stogareAdapter.getItem, "password");
    const cognitoUser = (yield call(
      cognitoSendSMSAsync,
      email,
      code,
    )) as unknown as any;
    if (cognitoUser.code === "CodeMismatchException") {
      yield put(loginUserError("Invalid code"));
      return;
    }
    if (cognitoUser.message) {
      yield put(loginUserError(cognitoUser.message));
      return;
    }
    yield put(loginServerHelper(cognitoUser, { email, password }, history));
  } catch (e) {
    console.log("[MFA] Error: ", e);
    yield put(loginUserError("Login error"));
  }

  yield call(stogareAdapter.removeItem, "email");
  yield call(stogareAdapter.removeItem, "password");
}

function* loginWithEmailPassword({ payload }: any) {
  console.log("In login saga");
  const { email, password } = payload.user;
  const { history } = payload;
  // const firebaseUser = null;
  let cognitoUser: any = null;
  // const user = null;
  // const userObj = null;
  // const companyObj = null;
  // const id_token = null;
  localStorage.setItem("email", email);
  localStorage.setItem("password", password);
  try {
    cognitoUser = (yield call(
      cognitologinWithEmailPasswordAsync,
      email,
      password,
    )) as unknown as any;
    if (cognitoUser.message === "mfa") {
      if (history) {
        history.push("/user/multifactor");
      }
      yield put(setMultifactorStatus("SENT_CODE", cognitoUser.destination));
      return;
    }
    if (cognitoUser.message) {
      yield put(loginUserError(cognitoUser.message));
      return;
    }
    yield put(loginServerHelper(cognitoUser, { email, password }, history));
  } catch (e) {
    console.log(e);
    Sentry.captureException(e);
    yield put(loginUserError("Login error"));
  }
}

export async function aliasMixpanel(email: string) {
  /* Mixpanel user creation */
  try {
    Mixpanel.alias(email);
  } catch (error) {
    console.log("MIXPANEL error: ", error);
  }
}

export async function initializeMixpanel(email: string) {
  /* Mixpanel identification */
  try {
    // await Mixpanel.alias(email);
    await Mixpanel.identify(email);
    await Mixpanel.people.set({
      $email: email,
      // $last_login: new Date().toDateString("en-US", {
      //  timeZone: "America/Los_Angeles",
      // }),
      $last_login: new Date().toDateString(),
      $user_type: [
        "znoland3@gmail.com",
        "diwakar.ganesan@gmail.com",
        "rnoland97@gmail.com",
      ].includes(email)
        ? "internal"
        : "external",
    });
  } catch (error) {
    console.log("MIXPANEL error: ", error);
  }
}

export async function resetMixpanel() {
  // await Mixpanel.reset(email);
  await Mixpanel.reset();
}

const virgilIdGenerator = () => {
  const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  let str = "";
  for (let i = 0; i < 28; i += 1) {
    str += chars[Math.floor(Math.random() * 28)];
  }
  return str;
};

const registerWithEmailPasswordAsync = async (
  email: string,
  password: string,
  virgilId: string,
) =>
  cognitoRegisterUser(email, password, virgilId)
    .then((authUser) => authUser)
    .catch((error) => error);
type TRegisterPayload = {
  email: string;
  password: string;
  companyName: string;
  isCreatingNewOrg: boolean;
  companyID?: number;
  consultancy: string;
  adminAction: boolean;
  history: any;
  isCreatingNewGroup?: string;
  groupIdOrName?: string;
  accessMetadata?: any;
  accessUsers?: any;
};
function* registerUser({ payload }: any) {
  const {
    email,
    password,
    companyName,
    isCreatingNewOrg,
    companyID,
    consultancy,
    adminAction,
    isCreatingNewGroup,
    groupIdOrName,
    accessMetadata,
    accessUsers,
  }: TRegisterPayload = payload.user;
  const { history } = payload;
  let company = null;
  let user = null;
  try {
    // COGNITO USER REGISTER
    const virgilId = virgilIdGenerator();
    console.log(`Generated virgil id: ${virgilId}`);
    yield put(registerUserStatus(ERegisterStatus.CheckCompany));
    // Check only for company admin
    if (adminAction && companyID) {
      const companyUserLimit = (yield call(
        checkCompanyUserLimit,
        companyID,
        companyName,
      )) as unknown as any;
      if (companyUserLimit?.message === "Limit") {
        yield put(registerUserError("Company user limit exceeded"));
        return;
      }
    }
    yield put(registerUserStatus(ERegisterStatus.CongitoRegister));
    let registeredUser: any = {};
    if (!adminAction) {
      registeredUser = (yield call(
        registerWithEmailPasswordAsync,
        email,
        password,
        virgilId,
      )) as unknown as any;
      console.log("registered user", registeredUser);
    }
    if (!registeredUser.message) {
      if (!adminAction) {
        yield put(registerUserStatus(ERegisterStatus.CongitoLogin));
        const cognitoUser = (yield call(
          cognitologinWithEmailPasswordAsync,
          email,
          password,
        )) as unknown as any;
        yield put(registerUserStatus(ERegisterStatus.VirginInit));
        const idToken = cognitoUser.getIdToken().getJwtToken();
        yield call(asyncStoreIdToken, idToken);
      }
      yield call(initializeVirgil, virgilId);
      yield put(registerUserStatus(ERegisterStatus.VirgilCreateUser));
      yield call(createNewUserVirgil, password);

      // CREATE USER DOC
      yield put(registerUserStatus(ERegisterStatus.CreateUser));
      const createdUser = (yield call(
        createUser,
        payload.user,
        virgilId,
        companyID,
        adminAction,
      )) as unknown as any;
      const userId = createdUser.id;

      if (isCreatingNewOrg) {
        yield put(registerUserStatus(ERegisterStatus.CreateCompany));
        company = (yield call(
          createCompany,
          virgilId,
          companyName,
          consultancy,
          userId,
          adminAction,
          payload.user,
        )) as unknown as any;
        yield call(asyncStore, "companyObj", JSON.stringify(company));
        console.log(`COMPANY ID: ${company.id}`);
      } else {
        console.log("Requesting company join");
        yield put(registerUserStatus(ERegisterStatus.JoinCompany));
        yield call(
          joinCompanyRequest,
          companyName,
          companyID,
          userId,
          adminAction,
        );
      }
      if (adminAction) {
        if (companyID) {
          yield put(increaseCompanyUserCount());
        }
        if (isCreatingNewGroup && groupIdOrName) {
          yield put(registerUserStatus(ERegisterStatus.AddingToGroup));
          yield put(acceptUserIntoCompanyRequest(createdUser));
          if (isCreatingNewGroup === "true") {
            yield put(
              createNewGroupRequest(
                [createdUser],
                groupIdOrName,
                accessMetadata,
                accessUsers,
              ),
            );
          } else {
            yield put(addUserToGroupRequest(createdUser, groupIdOrName));
          }
        }
        yield put(registerUserSuccess());
      } else {
        console.log("logging in....");
        user = (yield call(loginUser)) as unknown as any;
        console.log("Got user obj");
        yield call(asyncStore, "userObj", JSON.stringify(user));
        console.log("Stored user obj");
        if (isCreatingNewOrg) {
          yield put(registerUserSuccess(user, company));
        } else {
          yield put(registerUserSuccess(user));
        }
        history.push("/");
        yield call(aliasMixpanel, email);
        yield call(initializeMixpanel, email);
      }
    } else {
      yield put(registerUserError(registeredUser.message));
    }
  } catch (error: any) {
    if (error.response) {
      yield put(registerUserError(error.response.data.message));
    } else {
      yield put(registerUserError("Registration error. Please try again"));
    }
  }
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

// const logoutAsync = async (history) => {
//   await auth.signOut();
// };

function* logout({ payload }: any) {
  const { history } = payload;
  console.log("logout");
  const stogareAdapter = TumekeJSModule.get(
    EAdapterNames.Storage,
  ) as IStorageAdapter;
  try {
    // const state: ReduxState = yield select();
    const userObj: any = localStorage.getItem("userObj");

    if (!isVirgilInitialized() && userObj) {
      yield call(initializeVirgil, userObj.virgil_id);
    }
    yield call(logoutVirgil);

    yield put(removeVideoListeners());
    yield put(removeNotificationListener());

    yield call(resetMixpanel);
    cognitoSignoutUser();
  } catch (error) {
    console.log("Logout error", error);
  }
  yield call(stogareAdapter.removeItem, "userObj");
  yield call(stogareAdapter.removeItem, "companyObj");
  yield call(stogareAdapter.removeItem, "id_token");

  // Sometimes history not passed in
  if (history) {
    history.push("/user/login");
  }
}

export function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

/*
const forgotPasswordAsync = async (email: string) => cognitoInitiateForgotPassword(email)
    .then((data) => data)
    .catch((error) => error);
*/

function* forgotPassword({ payload }: any) {
  const { email } = payload.forgotUserMail;
  try {
    const ret = (yield call(
      initiateResetPasswordFlow,
      email,
    )) as unknown as any;
    yield put(forgotPasswordSuccess(ret));
    if (ret === "INITIATED_RESET" || ret === "VIRGIL_OFF") {
      NotificationManager.success(
        "Please check your email.",
        "Forgot Password Success",
        3000,
        null,
        null,
        "",
      );
    } else if (ret === "ERROR") {
      NotificationManager.error(
        "Please try again later.",
        "Forgot Password Error",
        3000,
        null,
        null,
        "",
      );
    }
  } catch (e: any) {
    handleVideoError(e);
    yield put(forgotPasswordError("Some error, please try again later"));
  }
}

function* forgotPasswordConfirm({ payload }: any) {
  const { newPassword, code, shouldResetAccount } =
    payload.forgotUserConfirmData;
  const { email } = payload;
  console.log(newPassword, code, email);
  try {
    yield call(
      confirmPasswordReset,
      email,
      code,
      newPassword,
      shouldResetAccount,
    );
    yield put(forgotPasswordConfirmSuccess(payload.history));
  } catch (e) {
    handleVideoError(e);
    yield put(forgotPasswordError("Error!"));
  }
}

function* forgotPasswordSuccessSaga({ payload }: any) {
  console.log("Success saga");
  const { history } = payload;
  NotificationManager.success(
    `Successfully reset password`,
    "Password reset success",
    5000,
    null,
    null,
    "filled",
  );
  yield put(forgotPasswordSuccess("INITIAL"));
  history.push("/user/login");
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPasswordSaga);
}

// const resetPasswordAsync = async (oldPassword, newPassword) => {
//   await auth.currentUser.updatePassword(newPassword);
// };

const cognitoChangePasswordAsync = async (
  user: any,
  oldPass: string,
  newPassword: string,
) =>
  cognitoChangePasswordWeb(user, oldPass, newPassword).then(
    (authUser) => authUser,
  );

function hasNumber(str: string) {
  return /\d/g.test(str);
}

function hasLetter(str: string) {
  return /[a-zA-Z]/g.test(str);
}
function* resetPasswordSaga({ payload }: any) {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { oldPassword, newPassword_1, newPassword_2 } = payload;

  console.log(`oldPassword: ${oldPassword}`);
  console.log(`newPassword: ${newPassword_1}`);
  console.log("In change password thunk");
  const state: ReduxState = yield select();
  const { company } = state.authUser;

  if (newPassword_1 !== newPassword_2) {
    NotificationManager.warning(
      "New passwords don't match",
      "Reset Password Error",
      3000,
      null,
      null,
      "",
    );
    yield put(resetPasswordError("New passwords don't match"));
    return;
  }

  if (newPassword_1.length < 8) {
    NotificationManager.warning(
      "New password must be at least 8 characters",
      "Reset Password Error",
      3000,
      null,
      null,
      "",
    );
    yield put(resetPasswordError("New password must be at least 8 characters"));
    return;
  }

  if (!hasNumber(newPassword_1) || !hasLetter(newPassword_1)) {
    NotificationManager.warning(
      "New password must have mix of letters and numbers",
      "Reset Password Error",
      3000,
      null,
      null,
      "",
    );
    yield put(resetPasswordError("New password must be at least 8 characters"));
    return;
  }

  if (!company.virgil_off) {
    if (!isVirgilInitialized()) {
      try {
        yield call(initializeVirgil, state.authUser.user.virgil_id);
        yield call(restorePrivateKey, oldPassword);
        yield call(joinGroup, state.authUser.user, state.authUser.company);
      } catch (e) {
        yield put(virgilInitError());
      }
    }
    try {
      console.log("Changing password");
      yield call(changePassword, oldPassword, newPassword_1);
    } catch (err) {
      console.log(err);
      Sentry.captureException(err);
      // If this error happens then there was a problem with old password
      NotificationManager.warning(
        "Old password wrong",
        "Reset Password Error",
        3000,
        null,
        null,
        "",
      );
      yield put(resetPasswordError("Old password wrong"));
      return;
    }
  }

  try {
    console.log("Changing password.... cognito");
    yield call(
      cognitoChangePasswordAsync,
      state.authUser.user.email,
      oldPassword,
      newPassword_1,
    );
  } catch (err) {
    console.log(err);
    NotificationManager.warning(
      "Password set error",
      "Reset Password Error",
      3000,
      null,
      null,
      "",
    );
    yield put(resetPasswordError("Password set error"));
    if (!company.virgil_off) {
      yield call(changePassword, newPassword_1, oldPassword);
    }
    return;
  }
  try {
    yield call(deleteTempPassword, state.authUser.user.id);
  } catch (e) {
    console.log("ERROR", e);
    // do nothing
  }
  NotificationManager.success(
    `Successfully updated`,
    "Password",
    3000,
    null,
    null,
    "filled",
  );
  yield put(resetPasswordSuccess());
}

function* checkTrialStatusSaga({ payload }: any) {
  const { email, history } = payload;
  const accountStatus = (yield call(
    checkAccountStatus,
    email,
  )) as unknown as any;
  if (!accountStatus.has_paid_deal && !accountStatus.trial_active) {
    yield put(
      loginUserError(
        "Your trial has expired. To move on to a paid plan please contact: riley@tumeke.io",
      ),
    );
    // yield put (logoutUser());
    if (history) {
      history.push("/user/trial-expired");
    }
    return;
  }
  console.log("[ACCOUNT_STATUS]", accountStatus);
}

function* updateCompanySettings({ payload }: any) {
  const { key, value }: { key: string; value: string } = payload;
  try {
    const state: ReduxState = yield select();
    const { company_id: companyId } = state.authUser.user;
    const companyData = (yield call(
      updateCompanySettingsHelper,
      companyId,
      key,
      value,
    )) as unknown as any;
    NotificationManager.success(
      "Settings updated successfully",
      "Settings updated",
      3000,
      null,
      null,
      "",
    );
    yield put(updateCompanySettingsSuccess(companyData));
  } catch (e: any) {
    handleVideoError(e);
    yield put(updateCompanySettingsSuccess(null));
  }
}

function* updateUserSettings({ payload }: any) {
  const { key, value }: { key: string; value: string } = payload;
  try {
    const state: ReduxState = yield select();
    const { user, company } = state.authUser;
    const userData = (yield call(
      saveUserData,
      user.id,
      {
        [key]: value,
      },
      ["superadmin"].includes(user.role),
    )) as unknown as any;
    const updatedUser = { ...user, ...userData };
    yield call(asyncStore, "userObj", JSON.stringify(updatedUser));
    yield put(loginUserSuccess(updatedUser, company));

    NotificationManager.success(
      "Settings updated successfully",
      "Settings updated",
      3000,
      null,
      null,
      "",
    );
  } catch (e: any) {
    handleVideoError(e);
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
    fork(watchRequestAESDecrypt),
    fork(watchEnteredPasswordRequest),
    fork(watchRequestAESDecryptSuccess),
    fork(watchCheckPassword),
    fork(watchSetCompanyMetadataSuccess),
    fork(watchForgotPasswordConfirm),
    fork(watchForgotPasswordSuccess),
    fork(watchEnterMultifactorCode),
    fork(watchLoginServerHelper),
    fork(watchLoginSuccessHelper),
    fork(watchSetUserRoleRequest),
    fork(watchCheckTrialStatus),
    fork(watchUpdateCompanySettings),
    fork(watchUpdateUserSettings),
  ]);
}
