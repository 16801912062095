import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";

import {
  getImprovements,
  getImprovement,
  saveImprovement,
  deleteImprovement,
} from "@kernel-helpers/DatabaseHelpers";
import { ReduxState } from "@kernel-store/reducers";
import {
  GET_IMPROVEMENTS_REQUEST,
  SAVE_IMPROVEMENT_REQUEST,
  DELETE_IMPROVEMENT_REQUEST,
} from "@kernel-store/actions";

import {
  getImprovementsSuccess,
  addImprovement,
  removeImprovementSuccess,
  removeImprovementError,
  setImprovementsLoading,
} from "./actions";
import { getThumbnailsRequest } from "../videos/actions";

function* getImprovementsSaga({ payload }: any) {
  const { pageSizeArg, pageOffsetArg, filter, improvementId } = payload;
  const state: ReduxState = yield select();

  if (!improvementId) {
    yield put(setImprovementsLoading(true));
  }

  let improvements: any = null;
  let improvementsObj: any = null;
  let improvementsCount: number = 0;

  let pageSize = pageSizeArg;
  let pageOffset = pageOffsetArg;
  if (pageSize === undefined) {
    pageSize = state.videos.pageMetadata.pageSize;
  }
  if (pageOffset === undefined) {
    pageOffset = (state.videos.pageMetadata.currentPage - 1) * pageSize;
  }

  try {
    if (improvementId) {
      improvementsObj = (yield call(
        getImprovement,
        improvementId,
      )) as unknown as any;
      improvements = [improvementsObj];
    } else {
      improvementsObj = (yield call(
        getImprovements,
        filter,
        pageSize,
        pageOffset,
      )) as unknown as any;
      improvements = improvementsObj.improvements;
    }

    improvementsCount = improvementsObj.improvementsCount;
  } catch (e) {
    return;
  }

  let improvementVideosIds: string[] = [];
  for (let i = 0; i < improvements.length; i += 1) {
    improvementVideosIds = improvementVideosIds.concat(
      improvements[i].videos_before.map((a: any) => a.id),
    );

    yield put(addImprovement(improvements[i]));
  }
  yield put(getThumbnailsRequest(improvementVideosIds, undefined, true));

  yield put(
    getImprovementsSuccess(
      improvementId ? state.improvements.count : improvementsCount,
    ),
  );
}

function* saveImprovementSaga({ payload }: any) {
  const { improvementData, improvementId } = payload;

  let improvement: any;
  try {
    improvement = (yield call(
      saveImprovement,
      improvementData,
      improvementId,
    )) as unknown as any;
  } catch (e) {
    return;
  }

  if (improvement) {
    yield put(addImprovement(improvement));
    if (improvement?.videos_before.length) {
      yield put(
        getThumbnailsRequest(
          [improvement.videos_before[0].id],
          undefined,
          true,
        ),
      );
    }
  }
}

function* deleteImprovementSaga({ payload }: any) {
  const { improvementId } = payload;

  try {
    (yield call(deleteImprovement, improvementId)) as unknown as any;
  } catch (e) {
    yield put(removeImprovementError(improvementId));
    return;
  }

  yield put(removeImprovementSuccess(improvementId));
}

export function* watchGetImprovements() {
  yield takeEvery(GET_IMPROVEMENTS_REQUEST, getImprovementsSaga);
}

export function* watchSaveImprovement() {
  yield takeEvery(SAVE_IMPROVEMENT_REQUEST, saveImprovementSaga);
}

export function* watchDeleteImprovement() {
  yield takeEvery(DELETE_IMPROVEMENT_REQUEST, deleteImprovementSaga);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetImprovements),
    fork(watchSaveImprovement),
    fork(watchDeleteImprovement),
  ]);
}
