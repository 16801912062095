/* eslint-disable @typescript-eslint/no-use-before-define */
import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";
import { NotificationManager } from "@kernel-helpers/react-notifications";

import {
  getClientDeptCardsData,
  getClientDeptObjData,
} from "@kernel-helpers/DatabaseHelpers";
import { ReduxState } from "@kernel-store/reducers";
import {
  GET_CLIENT_DEPT_CARDS_REQUEST,
  GET_CLIENT_DEPT_CARDS_ERROR,
  GET_CLIENT_DEPT_OBJ_REQUEST,
  GET_CLIENT_DEPT_OBJ_ERROR,
  SET_METADATA_VIEW_PREFERENCE,
} from "@kernel-store/actions";

import {
  getClientDeptCardsRequest,
  getClientDeptCardsError,
  getClientDeptCardsSuccess,
  getClientDeptObjError,
  getClientDeptObjSuccess,
} from "./actions";

export function* watchClientDeptCardsRequest() {
  yield takeEvery(GET_CLIENT_DEPT_CARDS_REQUEST, getClientDeptCardsRequestSaga);
}

export function* watchClientDeptCardsError() {
  yield takeEvery(GET_CLIENT_DEPT_CARDS_ERROR, getClientDeptCardsErrorSaga);
}

export function* watchClientDeptObjRequest() {
  yield takeEvery(GET_CLIENT_DEPT_OBJ_REQUEST, getClientDeptObjRequestSaga);
}

export function* watchClientDeptObjError() {
  yield takeEvery(GET_CLIENT_DEPT_OBJ_ERROR, getClientDeptObjErrorSaga);
}

export function* watchSetMetadataViewPref() {
  yield takeEvery(SET_METADATA_VIEW_PREFERENCE, setMetadataViewPrefSaga);
}
// export function* watchClientDeptCardsSuccess() {
//     yield takeEvery(GET_CLIENT_DEPT_CARDS_SUCCESS, getClientDeptCardsSuccessSaga)
// }

function* getClientDeptCardsErrorSaga() {
  yield 0;
  NotificationManager.warning(
    "Error getting data. Please try again or contact support if the issue persists.",
    "Error Getting Data",
    5000,
    null,
    null,
    "",
  );
}

function* getClientDeptObjErrorSaga() {
  yield 0;
  NotificationManager.warning(
    "Error getting data. Please try again or contact support if the issue persists.",
    "Error Getting Data",
    5000,
    null,
    null,
    "",
  );
}

// function* getClientDeptCardsSuccessSaga({ payload }) {
//     console.log('Filtered video list', payload.dashboardData.FilteredVideoIdList[0][0])
//     yield put(filterVideos(payload.dashboardData.FilteredVideoIdList[0][0]))
// }

function* setMetadataViewPrefSaga() {
  yield put(getClientDeptCardsRequest());
}

function* getClientDeptCardsRequestSaga() {
  // const { company_id } = payload;
  const state: ReduxState = yield select();
  const companyId = state.authUser.company.id;

  // const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // TODO(znoland): consider passing in timezone like the dashboard in the future

  let clientDeptCardsData = null;
  const clientDeptCardsDataCleaned: any[] = [];
  try {
    clientDeptCardsData = (yield call(
      getClientDeptCardsData,
      companyId,
      state.tree.seeOnlyYourMetadata,
    )) as unknown as any;

    clientDeptCardsData.clientDepartmentCards.forEach((card: any) => {
      let cardName = card.name;
      if (!card.name) {
        cardName = state.authUser.company.consultancy
          ? "Client Unassigned"
          : "Department Unassigned";
      }

      clientDeptCardsDataCleaned.push({
        date: card.date,
        id: card.id,
        name: cardName,
        videoCount: card.videoCount,
      });
    });
  } catch (e) {
    yield put(getClientDeptCardsError());
    return;
  }
  yield put(getClientDeptCardsSuccess(clientDeptCardsDataCleaned));
}

function* getClientDeptObjRequestSaga({ payload }: any) {
  const { clientDeptOptionId } = payload;

  let clientDeptObjData = null;
  const clientDeptObjJobs: any[] = [];
  try {
    clientDeptObjData = (yield call(
      getClientDeptObjData,
      clientDeptOptionId,
    )) as unknown as any; // TODO(znoland): add toggle to filter on just user's vids

    clientDeptObjData.ClientDeptObject[0][1].forEach((job: any) => {
      clientDeptObjJobs.push({
        jobName: !job.job_name ? "Job Type Unassigned" : job.job_name,
        jobOptionId: !job.job_option_id ? 1 : job.job_option_id,
        videoCount: job.video_count,
        videos: job.videos,
      });
    });
  } catch (e) {
    yield put(getClientDeptObjError());
    return;
  }
  yield put(getClientDeptObjSuccess(clientDeptObjData, clientDeptObjJobs));
}

// getClientDeptObject

export default function* rootSaga() {
  yield all([
    fork(watchClientDeptCardsRequest),
    fork(watchClientDeptCardsError),
    // fork(watchClientDeptCardsSuccess),
    fork(watchClientDeptObjRequest),
    fork(watchClientDeptObjError),
    fork(watchSetMetadataViewPref),
    // fork(watchClientDeptCardsSuccess),
  ]);
}
