import { AnyAction } from "@reduxjs/toolkit";
import { IImprovementData } from "@tumeke/tumekejs/lib/utils/interfaces";
import {
  GET_IMPROVEMENTS_REQUEST,
  GET_IMPROVEMENTS_SUCCESS,
  ADD_IMPROVEMENT_SUCCESS,
  SET_IMPROVEMENTS_LOADING,
  GET_IMPROVEMENT_THUMBNAIL_SUCCESS,
  SAVE_IMPROVEMENT_REQUEST,
  SET_IMPROVEMENT_PAGE_METADATA,
  DELETE_IMPROVEMENT_REQUEST,
  DELETE_IMPROVEMENT_SUCCESS,
  DELETE_IMPROVEMENT_ERROR,
} from "@kernel-store/actions";
import { TImpovementPageMetadata, TImpovementFilter } from "./reducer";

export const getImprovementsRequest = (
  userId: string,
  companyId: string,
  groupId: string,
  pageSizeArg?: number,
  pageOffsetArg?: number,
  filter?: TImpovementFilter,
  improvementId?: number,
): AnyAction => ({
  type: GET_IMPROVEMENTS_REQUEST,
  payload: {
    userId,
    companyId,
    groupId,
    pageSizeArg,
    pageOffsetArg,
    filter,
    improvementId,
  },
});

export const getImprovementsSuccess = (count: number): AnyAction => ({
  type: GET_IMPROVEMENTS_SUCCESS,
  payload: { count },
});

export const addImprovement = (improvementData: any): AnyAction => ({
  type: ADD_IMPROVEMENT_SUCCESS,
  payload: { improvementData },
});

export const setImprovementsLoading = (isLoading: boolean): AnyAction => ({
  type: SET_IMPROVEMENTS_LOADING,
  payload: { isLoading },
});

export const getImprovementThumbnailSuccess = (
  videoId: string,
  realThumbnailLoc: string,
): AnyAction => ({
  type: GET_IMPROVEMENT_THUMBNAIL_SUCCESS,
  payload: {
    videoId,
    realThumbnailLoc,
  },
});

export const saveImprovement = (
  improvementData: IImprovementData,
  improvementId?: number,
): AnyAction => ({
  type: SAVE_IMPROVEMENT_REQUEST,
  payload: { improvementData, improvementId },
});

export const setImprovementPageMetadata = ({
  currentPage,
  pageSize,
  filter,
}: TImpovementPageMetadata): AnyAction => ({
  type: SET_IMPROVEMENT_PAGE_METADATA,
  payload: { currentPage, pageSize, filter },
});

export const deleteImprovementRequest = (improvementId: number): AnyAction => ({
  type: DELETE_IMPROVEMENT_REQUEST,
  payload: { improvementId },
});

export const removeImprovementSuccess = (improvementId: number): AnyAction => ({
  type: DELETE_IMPROVEMENT_SUCCESS,
  payload: { improvementId },
});

export const removeImprovementError = (improvementId: number): AnyAction => ({
  type: DELETE_IMPROVEMENT_ERROR,
  payload: { improvementId },
});
