import { AnyAction } from "@reduxjs/toolkit";
import {
  GET_CLIENT_DEPT_CARDS_REQUEST,
  GET_CLIENT_DEPT_CARDS_ERROR,
  GET_CLIENT_DEPT_CARDS_SUCCESS,
  GET_CLIENT_DEPT_OBJ_REQUEST,
  GET_CLIENT_DEPT_OBJ_ERROR,
  GET_CLIENT_DEPT_OBJ_SUCCESS,
  SET_METADATA_VIEW_PREFERENCE,
  SET_CLIENT_CARD_VISIBLE,
} from "@kernel-store/actions";

export const getClientDeptCardsRequest = (): AnyAction => ({
  type: GET_CLIENT_DEPT_CARDS_REQUEST,
  payload: {},
});

export const getClientDeptCardsError = () => ({
  type: GET_CLIENT_DEPT_CARDS_ERROR,
  payload: {},
});

export const getClientDeptCardsSuccess = (
  clientDeptCardsData: any[],
): AnyAction => ({
  type: GET_CLIENT_DEPT_CARDS_SUCCESS,
  payload: { clientDeptCardsData },
});

export const getClientDeptObjRequest = (
  clientDeptOptionId: string,
): AnyAction => ({
  type: GET_CLIENT_DEPT_OBJ_REQUEST,
  payload: { clientDeptOptionId },
});

export const getClientDeptObjError = (): AnyAction => ({
  type: GET_CLIENT_DEPT_OBJ_ERROR,
  payload: {},
});

export const getClientDeptObjSuccess = (
  clientDeptObjData: any[],
  clientDeptObjJobs: any[],
): AnyAction => ({
  type: GET_CLIENT_DEPT_OBJ_SUCCESS,
  payload: { clientDeptObjData, clientDeptObjJobs },
});

export const setMetadataViewPreference = (val: string): AnyAction => ({
  type: SET_METADATA_VIEW_PREFERENCE,
  payload: { val },
});

export const setClientCardVisible = (
  clientDeptCardId: string,
  visible: boolean,
): AnyAction => ({
  type: SET_CLIENT_CARD_VISIBLE,
  payload: { clientDeptCardId, visible },
});
