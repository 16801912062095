import {
  AnyAction,
  combineReducers,
  ReducersMapObject,
} from "@reduxjs/toolkit";
import adminProfile, { AdminProfileReduxState } from "./adminProfile/reducer";
import authUser, { AuthReduxState } from "./auth/reducer";
import dashboard, { DashboardReduxState } from "./dashboard/reducer";
import users, { UsersReduxState } from "./users/reducer";
import companies, { CompaniesReduxState } from "./companies/reducer";
import tempPasswords, {
  TempPasswordsReduxState,
} from "./temp_passwords/reducer";
import menu, { MenuReduxState } from "./menu/reducer";
import notifications, {
  NotificationsReduxState,
} from "./notifications/reducer";
import roi, { RoiReduxState } from "./roi/reducer";
import settings, { SettingsReduxState } from "./settings/reducer";
import tree, { TreeReduxState } from "./tree/reducer";
import videos, { VideosReduxState } from "./videos/reducer";
import improvements, { ImprovementsReduxState } from "./improvements/reducer";
import { LOGOUT_USER } from "./actions";
import { ExtendedState } from "../types";

type EmptyReduxState = {
  loading: boolean;
};

export type EmptyState = {
  empty: EmptyReduxState;
};

export type ReduxState = {
  menu: MenuReduxState;
  settings: SettingsReduxState;
  authUser: AuthReduxState;
  adminProfile: AdminProfileReduxState;
  notifications: NotificationsReduxState;
  videos: VideosReduxState;
  dashboard: DashboardReduxState;
  users: UsersReduxState;
  companies: CompaniesReduxState;
  tempPasswords: TempPasswordsReduxState;
  roi: RoiReduxState;
  tree: TreeReduxState;
  improvements: ImprovementsReduxState;
};

export const emptyReducer = combineReducers<EmptyState>({
  empty: (state: EmptyReduxState = { loading: true }) => ({ ...state }),
});

const mergeReducers = <S extends ReducersMapObject>(extendedStore: S) => {
  const reduxState: ReducersMapObject = {
    menu,
    settings,
    authUser,
    adminProfile,
    notifications,
    videos,
    dashboard,
    users,
    companies,
    tempPasswords,
    roi,
    tree,
    improvements,
    ...extendedStore,
  };
  const reducers = combineReducers<ExtendedState<S>>(reduxState);

  const rootReducer = (state: any, action: AnyAction): ExtendedState<S> => {
    // Clear all data in redux store to initial.
    if (action.type === LOGOUT_USER) {
      return reducers(undefined, action);
    }

    return reducers(state, action);
  };

  return rootReducer;
};

export default mergeReducers;
