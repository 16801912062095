import { all } from "redux-saga/effects";
import authSagas from "./auth/saga";
import adminProfileSagas from "./adminProfile/saga";
import videosSagas from "./videos/saga";
import notificationsSagas from "./notifications/saga";
import dashboardSagas from "./dashboard/saga";
import usersSagas from "./users/saga";
import companiesSagas from "./companies/saga";
import tempPasswordsSagas from "./temp_passwords/saga";
import treeSagas from "./tree/saga";
import roiSagas from "./roi/saga";
import improvementsSagas from "./improvements/saga";

export default function* rootSaga() {
  yield all([
    authSagas(),
    adminProfileSagas(),
    videosSagas(),
    notificationsSagas(),
    dashboardSagas(),
    usersSagas(),
    companiesSagas(),
    tempPasswordsSagas(),
    treeSagas(),
    roiSagas(),
    improvementsSagas(),
  ]);
}
