import { AnyAction } from "@reduxjs/toolkit";
import {
  GET_DASHBOARD_DATA_REQUEST,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_DASHBOARD_DATA_ERROR,
  SET_DASHBOARD_DATA_SHORT_KEY,
  GET_DASHBOARD_DATA_EXTERNAL,
  SET_DASHBOARD_FILTERS_EXTERNAL,
  GET_DASHBOARD_FILTERS_REQUEST,
  GET_DASHBOARD_FILTERS_SUCCESS,
  GET_DASHBOARD_FILTERS_ERROR,
  SET_DASHBOARD_FILTER_FIELD,
  SET_DASHBOARD_FILTER_FIELDS,
  CLEAR_DASHBOARD_FILTERS,
  SET_DASHBOARD_ONBOARDING,
  SET_DASHBOARD_ONBOARDING_SKIPPED,
  SET_DASHBOARD_ONBOARDING_FINISHED,
  SET_DASHBOARD_AGGREGATION_PARAMS,
  SAVE_DASHBOARD_FILTER,
  DELETE_DASHBOARD_FILTER,
  EXPORT_BULK_CSV_REQUEST
} from "@kernel-store/actions";

export const getDashboardDataRequest = (
  assessmentsByFieldParam: any,
  dateGranularityParam: any,
  aggType: string,
  isExternal: boolean = false,
  ttl: number = 365,
): AnyAction => ({
  type: GET_DASHBOARD_DATA_REQUEST,
  payload: {
    assessmentsByFieldParam,
    dateGranularityParam,
    aggType,
    isExternal,
    ttl,
  },
});

export const getDashboardDataSuccess = (dashboardData: any): AnyAction => ({
  type: GET_DASHBOARD_DATA_SUCCESS,
  payload: { dashboardData },
});

export const getDashboardDataError = (): AnyAction => ({
  type: GET_DASHBOARD_DATA_ERROR,
  payload: {},
});

export const setDashboardDataShortKey = (shortKey: string): AnyAction => ({
  type: SET_DASHBOARD_DATA_SHORT_KEY,
  payload: { shortKey },
});

export const getDashboardDataExternal = (shortKey: string): AnyAction => ({
  type: GET_DASHBOARD_DATA_EXTERNAL,
  payload: { shortKey },
});

export const setDashboardFiltersExternal = (filterObject: any): AnyAction => ({
  type: SET_DASHBOARD_FILTERS_EXTERNAL,
  payload: { filterObject },
});

export const getDashboardFiltersRequest = (): AnyAction => ({
  type: GET_DASHBOARD_FILTERS_REQUEST,
  payload: {},
});

export const getDashboardFiltersSuccess = (
  dashboardFilters: any[],
): AnyAction => ({
  type: GET_DASHBOARD_FILTERS_SUCCESS,
  payload: { dashboardFilters },
});

export const getDashboardFiltersError = (): AnyAction => ({
  type: GET_DASHBOARD_FILTERS_ERROR,
  payload: {},
});

export const setDashboardFilterField = (
  field: string,
  value: string,
): AnyAction => ({
  type: SET_DASHBOARD_FILTER_FIELD,
  payload: { field, value },
});

export const setDashboardFilterFields = (filterObject: any): AnyAction => ({
  type: SET_DASHBOARD_FILTER_FIELDS,
  payload: { filterObject },
});

export const clearDashboardFilters = (): AnyAction => ({
  type: CLEAR_DASHBOARD_FILTERS,
  payload: {},
});

export const setDashboardOnboarding = (value: string | null): AnyAction => ({
  type: SET_DASHBOARD_ONBOARDING,
  payload: { value },
});

export const setDashboardOnboardingFinished = (value: string): AnyAction => ({
  type: SET_DASHBOARD_ONBOARDING_FINISHED,
  payload: { value },
});

export const setDashboardOnboardingSkipped = (value: boolean): AnyAction => ({
  type: SET_DASHBOARD_ONBOARDING_SKIPPED,
  payload: { value },
});

export const setDashboardAggregationParams = (
  dashboardFieldParam: string | null,
  dashboardGranularity: string | null,
  dashboardAggType: string | null,
): AnyAction => ({
  type: SET_DASHBOARD_AGGREGATION_PARAMS,
  payload: { dashboardFieldParam, dashboardGranularity, dashboardAggType },
});

export const saveDashboardFilter = (
  index: number | null,
  name: string,
): AnyAction => ({
  type: SAVE_DASHBOARD_FILTER,
  payload: { index, name },
});

export const deleteDashboardFilter = (index: number): AnyAction => ({
  type: DELETE_DASHBOARD_FILTER,
  payload: { index },
});

export const exportBulkCSVRequest = (): AnyAction => ({
  type: EXPORT_BULK_CSV_REQUEST,
  payload: {},
})