import { AnyAction } from "@reduxjs/toolkit";
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_STATUS,
  LOGIN_USER_ERROR,
  REGISTER_USER_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_CONFIRM,
  FORGOT_PASSWORD_CONFIRM_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  SET_DECYPTED_AES_KEYS_REQUEST,
  SET_DECYPTED_AES_KEYS_SUCCESS,
  ASK_FOR_PASSWORD_SHOW_MODAL,
  ASK_FOR_PASSWORD_REQUEST,
  CANCEL_ASK_PASSWORD,
  ENTERED_PASSWORD_REQUEST,
  ENTER_PASSWORD_ERROR,
  CHECK_PASSWORD,
  VIRGIL_INIT_ERROR,
  SET_COMPANY_METADATA_SUCCESS,
  SET_DECRYPTING_KEYS,
  SET_MULTIFACTOR_STATUS,
  ENTER_MULTIFACTOR_CODE,
  LOGIN_SERVER_HELPER,
  SET_USER_ROLE_REQUEST,
  SET_USER_ROLE_SUCCESS,
  CHECK_TRIAL_STATUS,
  INCREASE_COMPANY_USER_COUNT,
  DECREASE_COMPANY_USER_COUNT,
  UPDATE_COMPANY_SETTINGS,
  UPDATE_COMPANY_SETTINGS_SUCCESS,
  UPDATE_COMPANY_BASELINE_TASK_SUCCESS,
  DELETE_COMPANY_BASELINE_TASK_SUCCESS,
  UPDATE_USER_SETTINGS,
} from "@kernel-store/actions";
import { ERegisterStatus } from "./reducer";

export const loginUser = (user: any, history: any): AnyAction => ({
  type: LOGIN_USER,
  payload: { user, history },
});

export const cancelAskPassword = (): AnyAction => ({
  type: CANCEL_ASK_PASSWORD,
  payload: {},
});

export const checkPassword = (history: any): AnyAction => ({
  type: CHECK_PASSWORD,
  payload: { history },
});

export const enteredPasswordRequest = (password: string): AnyAction => ({
  type: ENTERED_PASSWORD_REQUEST,
  payload: { password },
});

export const virgilInitError = () => ({
  type: VIRGIL_INIT_ERROR,
  payload: {},
});

export const enterPasswordError = (errorMsg: string): AnyAction => ({
  type: ENTER_PASSWORD_ERROR,
  payload: { errorMsg },
});

export const askForPasswordShowModal = () => ({
  type: ASK_FOR_PASSWORD_SHOW_MODAL,
  payload: {},
});

export const askForPasswordRequest = () => ({
  type: ASK_FOR_PASSWORD_REQUEST,
  payload: {},
});

export const loginUserSuccess = (user: any, company: any): AnyAction => ({
  type: LOGIN_USER_SUCCESS,
  payload: { user, company },
});

export const setDecyptingKeys = (decryptingKeys: any): AnyAction => ({
  type: SET_DECRYPTING_KEYS,
  payload: { decryptingKeys },
});

export const setDecryptedAESKeysRequest = (
  callback: any,
  password: string,
): AnyAction => ({
  type: SET_DECYPTED_AES_KEYS_REQUEST,
  payload: { callback, password },
});

export const setDecryptedAESKeysSuccess = (
  aesKey: string,
  callback?: any,
): AnyAction => ({
  type: SET_DECYPTED_AES_KEYS_SUCCESS,
  payload: { aesKey, callback },
});

export const loginUserError = (message: string): AnyAction => ({
  type: LOGIN_USER_ERROR,
  payload: { message },
});

export const forgotPassword = (
  forgotUserMail: string,
  history: any,
): AnyAction => ({
  type: FORGOT_PASSWORD,
  payload: { forgotUserMail, history },
});
export const forgotPasswordSuccess = (
  forgotPasswordStatus: string,
): AnyAction => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: { forgotPasswordStatus },
});
export const forgotPasswordError = (message: string): AnyAction => ({
  type: FORGOT_PASSWORD_ERROR,
  payload: { message },
});
export const forgotPasswordConfirm = (
  email: string,
  forgotUserConfirmData: any,
  history: any,
): AnyAction => ({
  type: FORGOT_PASSWORD_CONFIRM,
  payload: { email, forgotUserConfirmData, history },
});
export const forgotPasswordConfirmSuccess = (history: any): AnyAction => ({
  type: FORGOT_PASSWORD_CONFIRM_SUCCESS,
  payload: { history },
});

export const resetPassword = (
  oldPassword: string,
  newPassword_1: string,
  newPassword_2: string,
): AnyAction => ({
  type: RESET_PASSWORD,
  payload: { oldPassword, newPassword_1, newPassword_2 },
});
export const resetPasswordSuccess = () => ({
  type: RESET_PASSWORD_SUCCESS,
});
export const resetPasswordError = (message: string): AnyAction => ({
  type: RESET_PASSWORD_ERROR,
  payload: { message },
});

export const setCompanyMetadataSuccess = (metadataObj: any): AnyAction => ({
  type: SET_COMPANY_METADATA_SUCCESS,
  payload: { metadataObj },
});

export const registerUser = (user: any, history: any): AnyAction => ({
  type: REGISTER_USER,
  payload: { user, history },
});
export const registerUserSuccess = (user?: any, company?: any): AnyAction => ({
  type: REGISTER_USER_SUCCESS,
  payload: { user, company },
});
export const registerUserError = (message: string): AnyAction => ({
  type: REGISTER_USER_ERROR,
  payload: { message },
});
export const registerUserStatus = (status: ERegisterStatus): AnyAction => ({
  type: REGISTER_USER_STATUS,
  payload: { status },
});

export const logoutUser = (history: any): AnyAction => ({
  type: LOGOUT_USER,
  payload: { history },
});

export const setMultifactorStatus = (
  status: string,
  destination: string,
): AnyAction => ({
  type: SET_MULTIFACTOR_STATUS,
  payload: { status, destination },
});

export const enterMultifactorCode = (
  code: string,
  history: any,
): AnyAction => ({
  type: ENTER_MULTIFACTOR_CODE,
  payload: { code, history },
});

export const loginServerHelper = (
  cognitoUser: any,
  user: any,
  history: any,
): AnyAction => ({
  type: LOGIN_SERVER_HELPER,
  payload: { cognitoUser, user, history },
});

export const setUserRoleRequest = (
  userId: string,
  role: string,
): AnyAction => ({
  type: SET_USER_ROLE_REQUEST,
  payload: { userId, role },
});

export const setUserRoleSuccess = (user: any): AnyAction => ({
  type: SET_USER_ROLE_SUCCESS,
  payload: { user },
});

export const checkTrialStatus = (email: string, history: any): AnyAction => ({
  type: CHECK_TRIAL_STATUS,
  payload: { email, history },
});

export const increaseCompanyUserCount = (count: number = 1): AnyAction => ({
  type: INCREASE_COMPANY_USER_COUNT,
  payload: { count },
});

export const decreaseCompanyUserCount = (count: number = 1): AnyAction => ({
  type: DECREASE_COMPANY_USER_COUNT,
  payload: { count },
});

export const updateCompanySettings = (
  key: string,
  value: string,
): AnyAction => ({
  type: UPDATE_COMPANY_SETTINGS,
  payload: { key, value },
});

export const updateCompanySettingsSuccess = (companyData: any): AnyAction => ({
  type: UPDATE_COMPANY_SETTINGS_SUCCESS,
  payload: { companyData },
});

export const updateCompanyBaselineTaskSuccess = (
  baselineAssessment: any,
): AnyAction => ({
  type: UPDATE_COMPANY_BASELINE_TASK_SUCCESS,
  payload: baselineAssessment,
});

export const deleteCompanyBaselineTaskSuccess = (id: number): AnyAction => ({
  type: DELETE_COMPANY_BASELINE_TASK_SUCCESS,
  payload: { id },
});

export const updateUserSettings = (key: string, value: string): AnyAction => ({
  type: UPDATE_USER_SETTINGS,
  payload: { key, value },
});
