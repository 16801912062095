import { AnyAction } from "@reduxjs/toolkit";
import {
  ACCEPT_USER_INTO_COMPANY_REQUEST,
  ACCEPT_USER_INTO_COMPANY_SUCCESS,
  INITIALIZE_REQUEST_LIST_REQUEST,
  INITIALIZE_REQUEST_LIST_SUCCESS,
  CREATE_NEW_GROUP_REQUEST,
  CREATE_NEW_GROUP_SUCCESS,
  UPDATE_GROUP_REQUEST,
  UPDATE_GROUP_REQUEST_SUCCESS,
  DELETE_GROUP_REQUEST,
  DELETE_GROUP_SUCCESS,
  INITIALIZE_GROUPS_LIST_REQUEST,
  INITIALIZE_GROUPS_LIST_SUCCESS,
  INITIALIZE_GROUPS_LIST_ERROR,
  MOVE_USER_TO_GROUP_REQUEST,
  MOVE_USER_TO_GROUP_SUCCESS,
  ADD_USER_TO_GROUP_REQUEST,
  ADD_USER_TO_GROUP_SUCCESS,
  DENY_USER_REQUEST,
  DENY_USER_SUCCESS,
  ACCEPT_AND_ADD_TO_GROUP,
  SET_ADMIN_PROFILE_LOADING,
  DELETE_USERS_REQUEST,
  DELETE_USERS_SUCCESS,
  DELETE_USERS_ERROR,
  DELETE_COMPANY_REQUEST,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_ERROR,
  SEND_COMPANY_NOTIFICATIONS_REQUEST,
  SEND_COMPANY_NOTIFICATIONS_SUCCESS,
  SEND_COMPANY_NOTIFICATIONS_ERROR,
} from "@kernel-store/actions";

export const acceptUserIntoCompanyRequest = (
  user: any,
  sendEmail = false,
): AnyAction => ({
  type: ACCEPT_USER_INTO_COMPANY_REQUEST,
  payload: { user, sendEmail },
});

export const acceptUserIntoCompanySuccess = (userId: number): AnyAction => ({
  type: ACCEPT_USER_INTO_COMPANY_SUCCESS,
  payload: { userId },
});

export const setAdminProfileLoading = (val: string): AnyAction => ({
  type: SET_ADMIN_PROFILE_LOADING,
  payload: { val },
});

export const acceptUserAndAddToGroup = (
  user: any,
  groupName: string,
  isNewGroup: boolean,
  sendEmail: boolean = false,
): AnyAction => ({
  type: ACCEPT_AND_ADD_TO_GROUP,
  payload: { user, groupName, isNewGroup, sendEmail },
});

export const initializeRequestListRequest = (): AnyAction => ({
  type: INITIALIZE_REQUEST_LIST_REQUEST,
  payload: {},
});

export const initializeRequestListSuccess = (
  requestList: any[],
): AnyAction => ({
  type: INITIALIZE_REQUEST_LIST_SUCCESS,
  payload: { requestList },
});

export const initializeGroupsListRequest = (): AnyAction => ({
  type: INITIALIZE_GROUPS_LIST_REQUEST,
  payload: {},
});

export const initializeGroupsListSuccess = (userGroups: any[]): AnyAction => ({
  type: INITIALIZE_GROUPS_LIST_SUCCESS,
  payload: { userGroups },
});

export const initializeGroupsListError = (): AnyAction => ({
  type: INITIALIZE_GROUPS_LIST_ERROR,
});

export const createNewGroupRequest = (
  users: any[],
  groupName: string,
  accessMetadata: any = [],
  accessUsers: any = [],
): AnyAction => ({
  type: CREATE_NEW_GROUP_REQUEST,
  payload: { users, groupName, accessMetadata, accessUsers },
});

export const createNewGroupSuccess = (userGroups: any[]): AnyAction => ({
  type: CREATE_NEW_GROUP_SUCCESS,
  payload: { userGroups },
});

export const deleteGroupRequest = (groupId: string): AnyAction => ({
  type: DELETE_GROUP_REQUEST,
  payload: { groupId },
});

export const updateGroupRequest = (
  groupId: number,
  groupName: string,
  accessMetadata: any = [],
  accessUsers: any = [],
): AnyAction => ({
  type: UPDATE_GROUP_REQUEST,
  payload: { groupId, groupName, accessMetadata, accessUsers },
});

export const updateGroupRequestSuccess = (userGroups: any[]): AnyAction => ({
  type: UPDATE_GROUP_REQUEST_SUCCESS,
  payload: { userGroups },
});

export const deleteGroupSuccess = (groupId: string): AnyAction => ({
  type: DELETE_GROUP_SUCCESS,
  payload: { groupId },
});

export const moveUserToGroupRequest = (
  userId: string,
  toGroup: string,
  fromGroup: string,
): AnyAction => ({
  type: MOVE_USER_TO_GROUP_REQUEST,
  payload: { userId, toGroup, fromGroup },
});

export const moveUserToGroupSuccess = (
  id: string,
  toGroup: string,
  fromGroup: string,
): AnyAction => ({
  type: MOVE_USER_TO_GROUP_SUCCESS,
  payload: { id, toGroup, fromGroup },
});

export const addUserToGroupRequest = (
  user: any,
  groupId: string,
): AnyAction => ({
  type: ADD_USER_TO_GROUP_REQUEST,
  payload: { user, groupId },
});

export const addUserToGroupSuccess = (userGroups: any[]): AnyAction => ({
  type: ADD_USER_TO_GROUP_SUCCESS,
  payload: { userGroups },
});

export const denyUserRequest = (userId: string): AnyAction => ({
  type: DENY_USER_REQUEST,
  payload: { userId },
});

export const denyUserSuccess = (userId: string): AnyAction => ({
  type: DENY_USER_SUCCESS,
  payload: { userId },
});

export const deleteUsersRequest = (
  fromGroup: string,
  userIds: number[],
  isFull: boolean = false,
): AnyAction => ({
  type: DELETE_USERS_REQUEST,
  payload: { fromGroup, userIds, isFull },
});

export const deleteUsersSuccess = (
  fromGroup: string,
  userIds: number[],
): AnyAction => ({
  type: DELETE_USERS_SUCCESS,
  payload: { fromGroup, userIds },
});

export const deleteUsersError = (error: string): AnyAction => ({
  type: DELETE_USERS_ERROR,
  payload: { error },
});

export const deleteCompanyRequest = (companyId: number): AnyAction => ({
  type: DELETE_COMPANY_REQUEST,
  payload: { companyId },
});

export const deleteCompanySuccess = (companyId: string): AnyAction => ({
  type: DELETE_COMPANY_SUCCESS,
  payload: { companyId },
});

export const deleteCompanyError = (error: string): AnyAction => ({
  type: DELETE_COMPANY_ERROR,
  payload: { error },
});

export const sendCompanyNotificationsRequest = (
  companyId: number,
  message: string,
): AnyAction => ({
  type: SEND_COMPANY_NOTIFICATIONS_REQUEST,
  payload: { companyId, message },
});

export const sendCompanyNotificationsSuccess = (
  companyId: string,
): AnyAction => ({
  type: SEND_COMPANY_NOTIFICATIONS_SUCCESS,
  payload: { companyId },
});

export const sendCompanyNotificationsError = (error: string): AnyAction => ({
  type: SEND_COMPANY_NOTIFICATIONS_ERROR,
  payload: { error },
});
