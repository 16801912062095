/* eslint-disable import/no-cycle */
/* SETTINGS */
export const CHANGE_LOCALE: string = "CHANGE_LOCALE";
export const SET_WEBAPP_LOGO: string = "SET_WEBAPP_LOGO";

/* AUTH */
export const LOGIN_USER: string = "LOGIN_USER";
export const LOGIN_SERVER_HELPER: string = "LOGIN_SERVER_HELPER";
export const LOGIN_USER_SUCCESS: string = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR: string = "LOGIN_USER_ERROR";
export const REGISTER_USER: string = "REGISTER_USER";
export const REGISTER_USER_SUCCESS: string = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_STATUS: string = "REGISTER_USER_STATUS";
export const REGISTER_USER_ERROR: string = "REGISTER_USER_ERROR";
export const LOGOUT_USER: string = "LOGOUT_USER";
export const FORGOT_PASSWORD: string = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS: string = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR: string = "FORGOT_PASSWORD_ERROR";
export const FORGOT_PASSWORD_CONFIRM: string = "FORGOT_PASSWORD_CONFIRM";
export const FORGOT_PASSWORD_CONFIRM_SUCCESS =
  "FORGOT_PASSWORD_CONFIRM_SUCCESS";
export const FORGOT_PASSWORD_CONFIRM_ERROR: string =
  "FORGOT_PASSWORD_CONFIRM_ERROR";
export const RESET_PASSWORD: string = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS: string = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR: string = "RESET_PASSWORD_ERROR";
export const SET_DECYPTED_AES_KEYS_REQUEST: string =
  "SET_DECYPTED_AES_KEYS_REQUEST";
export const SET_DECYPTED_AES_KEYS_SUCCESS: string =
  "SET_DECYPTED_AES_KEYS_SUCCESS";
export const SET_DECRYPTING_KEYS: string = "SET_DECRYPTING_KEYS";
export const DETERMINE_USER_ROLE_REQUEST: string =
  "DETERMINE_USER_ROLE_REQUEST";
export const DETERMINE_USER_ROLE_SUCCESS: string =
  "DETERMINE_USER_ROLE_SUCCESS";
export const ASK_FOR_PASSWORD_REQUEST: string = "ASK_FOR_PASSWORD_REQUEST";
export const ASK_FOR_PASSWORD_SHOW_MODAL: string =
  "ASK_FOR_PASSWORD_SHOW_MODAL";
export const CANCEL_ASK_PASSWORD: string = "CANCEL_ASK_PASSWORD";
export const ENTERED_PASSWORD_REQUEST: string = "ENTERED_PASSWORD_REQUEST";
export const ENTER_PASSWORD_ERROR: string = "ENTER_PASSWORD_ERROR";
export const CHECK_PASSWORD: string = "CHECK_PASSWORD";
export const VIRGIL_INIT_ERROR: string = "VIRGIL_INIT_ERROR";
export const ENTER_MULTIFACTOR_CODE: string = "ENTER_MULTIFACTOR_CODE";
export const SET_MULTIFACTOR_STATUS: string = "SET_MULTIFACTOR_STATUS";
export const SET_USER_ROLE_REQUEST: string = "SET_USER_ROLE_REQUEST";
export const SET_USER_ROLE_SUCCESS: string = "SET_USER_ROLE_SUCCESS";
export const SET_USER_ROLE_ERROR: string = "SET_USER_ROLE_ERROR";
export const CHECK_TRIAL_STATUS: string = "CHECK_TRIAL_STATUS";
export const DELETE_USERS_REQUEST: string = "DELETE_USERS_REQUEST";
export const DELETE_USERS_SUCCESS: string = "DELETE_USERS_SUCCESS";
export const DELETE_USERS_ERROR: string = "DELETE_USERS_ERROR";
export const DELETE_COMPANY_REQUEST: string = "DELETE_COMPANY_REQUEST";
export const DELETE_COMPANY_SUCCESS: string = "DELETE_COMPANY_SUCCESS";
export const DELETE_COMPANY_ERROR: string = "DELETE_COMPANY_ERROR";
export const INCREASE_COMPANY_USER_COUNT: string =
  "INCREASE_COMPANY_USER_COUNT";
export const DECREASE_COMPANY_USER_COUNT: string =
  "DECREASE_COMPANY_USER_COUNT";
export const SEND_COMPANY_NOTIFICATIONS_REQUEST: string =
  "SEND_COMPANY_NOTIFICATIONS_REQUEST";
export const SEND_COMPANY_NOTIFICATIONS_SUCCESS: string =
  "SEND_COMPANY_NOTIFICATIONS_SUCCESS";
export const SEND_COMPANY_NOTIFICATIONS_ERROR: string =
  "SEND_COMPANY_NOTIFICATIONS_ERROR";

/* MENU */
export const MENU_SET_CLASSNAMES: string = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME: string =
  "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU: string = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES: string =
  "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  "MENU_CHANGE_HAS_SUB_ITEM_STATUS";

/* ADMIN PROFILE */
export const ACCEPT_USER_INTO_COMPANY_REQUEST =
  "ACCEPT_USER_INTO_COMPANY_REQUEST";
export const ACCEPT_USER_INTO_COMPANY_SUCCESS =
  "ACCEPT_USER_INTO_COMPANY_SUCCESS";
export const INITIALIZE_REQUEST_LIST_REQUEST =
  "INITIALIZE_REQUEST_LIST_REQUEST";
export const INITIALIZE_REQUEST_LIST_SUCCESS =
  "INITIALIZE_REQUEST_LIST_SUCCESS";
export const CREATE_NEW_GROUP_REQUEST: string = "CREATE_NEW_GROUP_REQUEST";
export const CREATE_NEW_GROUP_SUCCESS: string = "CREATE_NEW_GROUP_SUCCESS";
export const UPDATE_GROUP_REQUEST: string = "UPDATE_GROUP_REQUEST";
export const UPDATE_GROUP_REQUEST_SUCCESS: string =
  "UPDATE_GROUP_REQUEST_SUCCESS";
export const DELETE_GROUP_REQUEST: string = "DELETE_GROUP_REQUEST";
export const DELETE_GROUP_SUCCESS: string = "DELETE_GROUP_SUCCESS";
export const INITIALIZE_GROUPS_LIST_REQUEST: string =
  "INITIALIZE_GROUPS_LIST_REQUEST";
export const INITIALIZE_GROUPS_LIST_SUCCESS: string =
  "INITIALIZE_GROUPS_LIST_SUCCESS";
export const INITIALIZE_GROUPS_LIST_ERROR: string =
  "INITIALIZE_GROUPS_LIST_ERROR";
export const MOVE_USER_TO_GROUP_REQUEST: string = "MOVE_USER_TO_GROUP_REQUEST";
export const MOVE_USER_TO_GROUP_SUCCESS: string = "MOVE_USER_TO_GROUP_SUCCESS";
export const ADD_USER_TO_GROUP_REQUEST: string = "ADD_USER_TO_GROUP_REQUEST";
export const ADD_USER_TO_GROUP_SUCCESS: string = "ADD_USER_TO_GROUP_SUCCESS";
export const DENY_USER_REQUEST: string = "DENY_USER_REQUEST";
export const DENY_USER_SUCCESS: string = "DENY_USER_SUCCESS";
export const ACCEPT_AND_ADD_TO_GROUP: string = "ACCEPT_AND_ADD_TO_GROUP";
export const SET_ADMIN_PROFILE_LOADING: string = "SET_ADMIN_PROFILE_LOADING";

/* NOTIFICATIONS */
export const ADD_NOTIFICATION_RECORD: string = "ADD_NOTIFICATION_RECORD";
export const ADD_NOTIFICATION_RECORD_SUCCESS =
  "ADD_NOTIFICATION_RECORD_SUCCESS";
export const DELETE_NOTIFICATION_RECORD: string = "DELETE_NOTIFICATION_RECORD";
export const START_NOTIFICATION_LISTENER: string =
  "START_NOTIFICATION_LISTENER";
export const SET_LISTENER_ACTIVE: string = "SET_LISTENER_ACTIVE";
export const REMOVE_NOTIFICATION_LISTENER: string =
  "REMOVE_NOTIFICATION_LISTENER";

/* VIDEO LIST */
export const GET_VIDEO_LIST_REQUEST: string = "GET_VIDEO_LIST_REQUEST";
export const GET_VIDEO_SUCCESS: string = "GET_VIDEO_SUCCESS";
export const GET_SKINNY_VIDEO_SUCCESS: string = "GET_SKINNY_VIDEO_SUCCESS";
export const ADD_SINGLE_VIDEO_LISTENER: string = "ADD_SINGLE_VIDEO_LISTENER";
export const GET_SINGLE_VIDEO_DOC_REQUEST: string =
  "GET_SINGLE_VIDEO_DOC_REQUEST";
export const GET_SINGLE_VIDEO_DOC_ERROR: string = "GET_SINGLE_VIDEO_DOC_ERROR";
export const MODIFY_VIDEO_DOC: string = "MODIFY_VIDEO_DOC";
export const LOADING_VIDEOS_START: string = "LOADING_VIDEOS_START";
export const ALL_VIDEOS_LOADED: string = "ALL_VIDEOS_LOADED";
export const UPDATE_ADDITIONAL_INFO: string = "UPDATE_ADDITIONAL_INFO";
export const INITIALIZE_INSPECTION_DATA: string = "INITIALIZE_INSPECTION_DATA";
export const UPLOAD_ADDITIONAL_INFO: string = "UPLOAD_ADDITIONAL_INFO";
export const GET_JOINT_DATA: string = "GET_JOINT_DATA";
export const GET_JOINT_DATA_LEGACY: string = "GET_JOINT_DATA_LEGACY";
export const GET_JOINT_DATA_SUCCESS: string = "GET_JOINT_DATA_SUCCESS";
export const UPDATE_RISK_COMPONENTS: string = "UPDATE_RISK_COMPONENTS";
export const UPLOAD_RISK_COMPONENTS: string = "UPLOAD_RISK_COMPONENTS";
export const UPDATE_ASSESSMENT_METADATA: string = "UPDATE_ASSESSMENT_METADATA";
export const UPDATE_ASSESSMENT_ROI_RESULTS: string =
  "UPDATE_ASSESSMENT_ROI_RESULTS";
export const DELETE_VIDEOS_REQUEST: string = "DELETE_VIDEOS_REQUEST";
export const DELETE_VIDEOS_SUCCESS: string = "DELETE_VIDEOS_SUCCESS";
export const DELETE_VIDEO_ASSESSMENT: string = "DELETE_VIDEO_ASSESSMENT";
export const DELETE_VIDEO_CLIP: string = "DELETE_VIDEO_CLIP";
export const SET_PRIMARY_VIDEO_ASSESSMENT: string =
  "SET_PRIMARY_VIDEO_ASSESSMENT";
export const SET_VIDEO_METADATA: string = "SET_VIDEO_METADATA";
export const SET_VIDEO_METADATA_SUCCESS: string = "SET_VIDEO_METADATA_SUCCESS";
export const SET_COMPANY_METADATA_SUCCESS: string =
  "SET_COMPANY_METADATA_SUCCESS";
export const GET_ALL_METADATA_FIELDS: string = "GET_ALL_METADATA_FIELDS";
export const GET_ALL_METADATA_FIELDS_SUCCESS =
  "GET_ALL_METADATA_FIELDS_SUCCESS";
export const REMOVE_VIDEO_LISTENERS: string = "REMOVE_VIDEO_LISTENERS";
export const GENERATE_REPORT_REQUEST: string = "GENERATE_REPORT_REQUEST";
export const GENERATE_REPORT_SUCCESS: string = "GENERATE_REPORT_SUCCESS";
export const GENERATE_REPORT_ERROR: string = "GENERATE_REPORT_ERROR";
export const GENERATE_CSV_REQUEST: string = "GENERATE_CSV_REQUEST";
export const GENERATE_CSV_SUCCESS: string = "GENERATE_CSV_SUCCESS";
export const GENERATE_CSV_ERROR: string = "GENERATE_CSV_ERROR";
export const EXTERNAL_GENERATE_GPT_RECOMMENDATIONS_REQUEST: string =
  "EXTERNAL_GENERATE_GPT_RECOMMENDATIONS_REQUEST";
export const EXTERNAL_GENERATE_GPT_RECOMMENDATIONS_ERROR: string =
  "EXTERNAL_GENERATE_GPT_RECOMMENDATIONS_ERROR";
export const EXTERNAL_GENERATE_GPT_RECOMMENDATIONS_SUCCESS: string =
  "EXTERNAL_GENERATE_GPT_RECOMMENDATIONS_SUCCESS";
export const DOWNLOAD_VIDEO_REQUEST: string = "DOWNLOAD_VIDEO_REQUEST";
export const DOWNLOAD_VIDEO_SUCCESS: string = "DOWNLOAD_VIDEO_SUCCESS";
export const DOWNLOAD_VIDEO_ERROR: string = "DOWNLOAD_VIDEO_ERROR";
export const DOWNLOAD_RISK_JOINTS_CSV_REQUEST: string =
  "DOWNLOAD_RISK_JOINTS_CSV_REQUEST";
export const DOWNLOAD_RISK_JOINTS_CSV_SUCCESS: string =
  "DOWNLOAD_RISK_JOINTS_CSV_SUCCESS";
export const DOWNLOAD_RISK_JOINTS_CSV_ERROR: string =
  "DOWNLOAD_RISK_JOINTS_CSV_ERROR";
export const GET_VIDEO_JOINT_URLS: string = "GET_VIDEO_JOINT_URLS";
export const ADD_VIDEO_JOINT_URLS: string = "ADD_VIDEO_JOINT_URLS";
export const GET_THUMBNAIL_REQUEST: string = "GET_THUMBNAIL_REQUEST";
export const GET_THUMBNAILS_REQUEST: string = "GET_THUMBNAILS_REQUEST";
export const GET_THUMBNAIL_SUCCESS: string = "GET_THUMBNAIL_SUCCESS";
export const SUBMIT_VIDEO_FEEDBACK: string = "SUBMIT_VIDEO_FEEDBACK";
export const FILTER_VIDEOS: string = "FILTER_VIDEOS";
export const SET_SELECTED_POSTURE: string = "SET_SELECTED_POSTURE";
export const SET_SELECTED_REP: string = "SET_SELECTED_REP";
export const SET_PERSON_ID: string = "SET_PERSON_ID";
export const COMPARE_VIDEOS_REQUEST: string = "COMPARE_VIDEOS_REQUEST";
export const COMPARE_VIDEOS_ERROR: string = "COMPARE_VIDEOS_ERROR";
export const COMPARE_VIDEOS_SUCCESS: string = "COMPARE_VIDEOS_SUCCESS";
export const RERUN_VIDEO_REQUEST: string = "RERUN_VIDEO_REQUEST";
export const SAVE_VIDEO_NOTES_REQUEST: string = "SAVE_VIDEO_NOTES_REQUEST";
export const SAVE_VIDEO_NOTES_SUCCESS: string = "SAVE_VIDEO_NOTES_SUCCESS";
export const SAVE_VIDEO_NOTES_ERROR: string = "SAVE_VIDEO_NOTES_ERROR";
export const GET_POSTURE_THUMBNAILS_REQUEST: string =
  "GET_POSTURE_THUMBNAILS_REQUEST";
export const UPDATE_VIDEO_NAME: string = "UPDATE_VIDEO_NAME";
export const UPDATE_ASSESSMENT_NAME: string = "UPDATE_ASSESSMENT_NAME";
export const SET_PAGE_METADATA: string = "SET_PAGE_METADATA";
export const SET_WEBSOCKET_FAILED: string = "SET_WEBSOCKET_FAILED";
export const CLEAR_VIDEO_LIST: string = "CLEAR_VIDEO_LIST";
export const SET_ALL_INVISIBLE: string = "SET_ALL_INVISIBLE";
export const SET_VIDEOS_VISIBILITY: string = "SET_VIDEOS_VISIBILITY";
export const GET_VIDEO_DOC_EXTERNAL: string = "GET_VIDEO_DOC_EXTERNAL";
export const SET_RECOMMENDATION_GENERATION_LOADING: string =
  "SET_RECOMMENDATION_GENERATION_LOADING";
export const GET_LINKS: string = "GET_LINKS";
export const GET_LINKS_SUCCESS: string = "GET_LINKS_SUCCESS";
export const UPDATE_LINK: string = "UPDATE_LINK";
export const DELETE_LINK: string = "DELETE_LINK";
export const SEND_LINK: string = "SEND_LINK";
export const UPDATE_COMPANY_SETTINGS: string = "UPDATE_COMPANY_SETTINGS";
export const UPDATE_COMPANY_SETTINGS_SUCCESS: string =
  "UPDATE_COMPANY_SETTINGS_SUCCESS";
export const UPDATE_COMPANY_BASELINE_TASK_SUCCESS: string =
  "UPDATE_COMPANY_BASELINE_TASK_SUCCESS";
export const DELETE_COMPANY_BASELINE_TASK_SUCCESS: string =
  "DELETE_COMPANY_BASELINE_TASK_SUCCESS";
export const UPDATE_USER_SETTINGS: string = "UPDATE_USER_SETTINGS";
export const ADD_METADATA_OPTION: string = "ADD_METADATA_OPTION";
export const DELETE_METADATA_OPTION: string = "DELETE_METADATA_OPTION";
export const EDIT_METADATA_OPTION: string = "EDIT_METADATA_OPTION";
export const GET_IMPROVEMENTS_REQUEST: string = "GET_IMPROVEMENTS_REQUEST";
export const GET_IMPROVEMENTS_SUCCESS: string = "GET_IMPROVEMENTS_SUCCESS";
export const ADD_IMPROVEMENT_SUCCESS: string = "ADD_IMPROVEMENT_SUCCESS";
export const SET_IMPROVEMENTS_LOADING: string = "SET_IMPROVEMENTS_LOADING";
export const GET_IMPROVEMENT_THUMBNAIL_SUCCESS: string =
  "GET_IMPROVEMENT_THUMBNAIL_SUCCESS";
export const SAVE_IMPROVEMENT_REQUEST: string = "SAVE_IMPROVEMENT_REQUEST";
export const SET_IMPROVEMENT_PAGE_METADATA: string =
  "SET_IMPROVEMENT_PAGE_METADATA";
export const DELETE_IMPROVEMENT_REQUEST: string = "DELETE_IMPROVEMENT_REQUEST";
export const DELETE_IMPROVEMENT_SUCCESS: string = "DELETE_IMPROVEMENT_SUCCESS";
export const DELETE_IMPROVEMENT_ERROR: string = "DELETE_IMPROVEMENT_ERROR";

/* DASHBOARD */
export const GET_DASHBOARD_DATA_REQUEST: string = "GET_DASHBOARD_DATA_REQUEST";
export const GET_DASHBOARD_DATA_SUCCESS: string = "GET_DASHBOARD_DATA_SUCCESS";
export const GET_DASHBOARD_DATA_ERROR: string = "GET_DASHBOARD_DATA_ERROR";
export const SET_DASHBOARD_DATA_SHORT_KEY: string =
  "SET_DASHBOARD_DATA_SHORT_KEY";
export const GET_DASHBOARD_DATA_EXTERNAL: string =
  "GET_DASHBOARD_DATA_EXTERNAL";
export const SET_DASHBOARD_FILTERS_EXTERNAL: string =
  "SET_DASHBOARD_FILTERS_EXTERNAL";
export const GET_DASHBOARD_FILTERS_REQUEST: string =
  "GET_DASHBOARD_FILTERS_REQUEST";
export const GET_DASHBOARD_FILTERS_SUCCESS: string =
  "GET_DASHBOARD_FILTERS_SUCCESS";
export const GET_DASHBOARD_FILTERS_ERROR: string =
  "GET_DASHBOARD_FILTERS_ERROR";
export const SET_DASHBOARD_FILTER_FIELD: string = "SET_DASHBOARD_FILTER_FIELD";
export const SET_DASHBOARD_FILTER_FIELDS: string =
  "SET_DASHBOARD_FILTER_FIELDS";
export const CLEAR_DASHBOARD_FILTERS: string = "CLEAR_DASHBOARD_FILTERS";
export const SET_DASHBOARD_ONBOARDING: string = "SET_DASHBOARD_ONBOARDING";
export const SET_DASHBOARD_ONBOARDING_FINISHED: string =
  "SET_DASHBOARD_ONBOARDING_FINISHED";
export const SET_DASHBOARD_ONBOARDING_SKIPPED: string =
  "SET_DASHBOARD_ONBOARDING_SKIPPED";
export const SET_DASHBOARD_AGGREGATION_PARAMS: string =
  "SET_DASHBOARD_AGGREGATION_PARAMS";
export const TOGGLE_ASSESSMENTS_LIMIT_MODAL: string =
  "TOGGLE_ASSESSMENTS_LIMIT_MODAL";
export const SAVE_DASHBOARD_FILTER: string = "SAVE_DASHBOARD_FILTER";
export const DELETE_DASHBOARD_FILTER: string = "DELETE_DASHBOARD_FILTER";
export const EXPORT_BULK_CSV_REQUEST: string = "EXPORT_BULK_CSV_REQUEST";

/* USERS */
export const GET_USERS_DATA_REQUEST: string = "GET_USERS_DATA_REQUEST";
export const GET_USERS_DATA_SUCCESS: string = "GET_USERS_DATA_SUCCESS";
export const GET_USERS_DATA_ERROR: string = "GET_USERS_DATA_ERROR";
export const GET_USERS_FILTERS_REQUEST: string = "GET_USERS_FILTERS_REQUEST";
export const GET_USERS_FILTERS_SUCCESS: string = "GET_USERS_FILTERS_SUCCESS";
export const GET_USERS_FILTERS_ERROR: string = "GET_USERS_FILTERS_ERROR";
export const SET_USERS_FILTER_FIELD: string = "SET_USERS_FILTER_FIELD";
export const CLEAR_USERS_FILTERS: string = "CLEAR_USERS_FILTERS";

/* COMPANIES */
export const SAVE_POLICES_REQUEST: string = "SAVE_POLICES_REQUEST";
export const SAVE_POLICES_REQUEST_ERROR: string = "SAVE_POLICES_REQUEST_ERROR";
export const SAVE_POLICES_REQUEST_SUCCESS: string =
  "SAVE_POLICES_REQUEST_SUCCESS";
export const GET_COMPANIES_DATA_REQUEST: string = "GET_COMPANIES_DATA_REQUEST";
export const GET_COMPANIES_DATA_SUCCESS: string = "GET_COMPANIES_DATA_SUCCESS";
export const GET_COMPANIES_DATA_ERROR: string = "GET_COMPANIES_DATA_ERROR";
export const GET_COMPANIES_FILTERS_REQUEST: string =
  "GET_COMPANIES_FILTERS_REQUEST";
export const GET_COMPANIES_FILTERS_SUCCESS: string =
  "GET_COMPANIES_FILTERS_SUCCESS";
export const GET_COMPANIES_FILTERS_ERROR: string =
  "GET_COMPANIES_FILTERS_ERROR";
export const SET_COMPANIES_FILTER_FIELD: string = "SET_COMPANIES_FILTER_FIELD";
export const CLEAR_COMPANIES_FILTERS: string = "CLEAR_COMPANIES_FILTERS";
export const ADD_COMPANY_METADATA_FIELD: string = "ADD_COMPANY_METADATA_FIELD";
export const EDIT_COMPANY_METADATA_FIELD: string =
  "EDIT_COMPANY_METADATA_FIELD";
export const DELETE_COMPANY_METADATA_FIELD: string =
  "DELETE_COMPANY_METADATA_FIELD";
export const ADD_COMPANY_METADATA_OPTION: string =
  "ADD_COMPANY_METADATA_OPTION";
export const EDIT_COMPANY_METADATA_OPTION: string =
  "EDIT_COMPANY_METADATA_OPTION";
export const DELETE_COMPANY_METADATA_OPTION: string =
  "DELETE_COMPANY_METADATA_OPTION";

/* TEMP_PASSWORDS */
export const GET_TEMP_PASSWORDS_DATA_REQUEST: string =
  "GET_TEMP_PASSWORDS_DATA_REQUEST";
export const GET_TEMP_PASSWORDS_DATA_SUCCESS: string =
  "GET_TEMP_PASSWORDS_DATA_SUCCESS";
export const GET_TEMP_PASSWORDS_DATA_ERROR: string =
  "GET_TEMP_PASSWORDS_DATA_ERROR";
export const GET_TEMP_PASSWORDS_FILTERS_REQUEST: string =
  "GET_TEMP_PASSWORDS_FILTERS_REQUEST";
export const GET_TEMP_PASSWORDS_FILTERS_SUCCESS: string =
  "GET_TEMP_PASSWORDS_FILTERS_SUCCESS";
export const GET_TEMP_PASSWORDS_FILTERS_ERROR: string =
  "GET_TEMP_PASSWORDS_FILTERS_ERROR";
export const SET_TEMP_PASSWORDS_FILTER_FIELD: string =
  "SET_TEMP_PASSWORDS_FILTER_FIELD";
export const CLEAR_TEMP_PASSWORDS_FILTERS: string =
  "CLEAR_TEMP_PASSWORDS_FILTERS";

/* TREE */
export const GET_CLIENT_DEPT_CARDS_REQUEST: string =
  "GET_CLIENT_DEPT_CARDS_REQUEST";
export const GET_CLIENT_DEPT_CARDS_ERROR: string =
  "GET_CLIENT_DEPT_CARDS_ERROR";
export const GET_CLIENT_DEPT_CARDS_SUCCESS: string =
  "GET_CLIENT_DEPT_CARDS_SUCCESS";
export const GET_CLIENT_DEPT_OBJ_REQUEST: string =
  "GET_CLIENT_DEPT_OBJ_REQUEST";
export const GET_CLIENT_DEPT_OBJ_ERROR: string = "GET_CLIENT_DEPT_OBJ_ERROR";
export const GET_CLIENT_DEPT_OBJ_SUCCESS: string =
  "GET_CLIENT_DEPT_OBJ_SUCCESS";
export const SET_METADATA_VIEW_PREFERENCE: string =
  "SET_METADATA_VIEW_PREFERENCE";
export const SET_CLIENT_CARD_VISIBLE: string = "SET_CLIENT_CARD_VISIBLE";

/* ROI Calculator */
export const ADD_SOLUTION: string = "ADD_SOLUTION";
export const DELETE_SOLUTION: string = "DELETE_SOLUTION";
export const ADD_INJURY: string = "ADD_INJURY";
export const DELETE_INJURY: string = "DELETE_INJURY";
export const SAVE_INTRO: string = "SAVE_INTRO";
export const SAVE_INJURY: string = "SAVE_INJURY";
export const SAVE_SOLUTION: string = "SAVE_SOLUTION";
export const COMPUTE_ROI: string = "COMPUTE_ROI";
export const CLEAR_ROI_CALCULATOR: string = "CLEAR_ROI_CALCULATOR";
export const SAVE_ROI_RESULTS: string = "SAVE_ROI_RESULTS";
export const RESTORE_ROI_RESULTS: string = "RESTORE_ROI_RESULTS";

export * from "./menu/actions";
export * from "./settings/actions";
export * from "./auth/actions";
export * from "./videos/actions";
export * from "./notifications/actions";
export * from "./adminProfile/actions";
export * from "./dashboard/actions";
export * from "./users/actions";
export * from "./companies/actions";
export * from "./temp_passwords/actions";
export * from "./roi/actions";
export * from "./tree/actions";
export * from "./improvements/actions";
