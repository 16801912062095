import { AnyAction } from "@reduxjs/toolkit";
import {
  GET_CLIENT_DEPT_CARDS_REQUEST,
  GET_CLIENT_DEPT_CARDS_ERROR,
  GET_CLIENT_DEPT_CARDS_SUCCESS,
  GET_CLIENT_DEPT_OBJ_REQUEST,
  GET_CLIENT_DEPT_OBJ_ERROR,
  GET_CLIENT_DEPT_OBJ_SUCCESS,
  SET_METADATA_VIEW_PREFERENCE,
  SET_CLIENT_CARD_VISIBLE,
} from "@kernel-store/actions";

export type TreeReduxState = {
  clientDeptCardsData: any[];
  clientDeptCardsDataLoading: boolean;
  clientDeptObjData: any[];
  clientDeptObjJobs: any[];
  clientDeptObjDataLoading: boolean;
  filters: any;
  seeOnlyYourMetadata: boolean;
  refresh: boolean;
};

export const INIT_STATE: TreeReduxState = {
  clientDeptCardsData: [],
  clientDeptCardsDataLoading: false,
  clientDeptObjData: [],
  clientDeptObjJobs: [],
  clientDeptObjDataLoading: false,
  filters: {},
  seeOnlyYourMetadata: false,
  refresh: false,
};

export default (
  state: TreeReduxState = INIT_STATE,
  action: AnyAction = { type: undefined },
): TreeReduxState => {
  switch (action.type) {
    case GET_CLIENT_DEPT_CARDS_REQUEST:
      return { ...state, clientDeptCardsDataLoading: true };

    case GET_CLIENT_DEPT_CARDS_ERROR:
      return { ...state, clientDeptCardsDataLoading: false };

    case GET_CLIENT_DEPT_CARDS_SUCCESS:
      console.log(action.payload.clientDeptCardsData);
      return {
        ...state,
        clientDeptCardsData: action.payload.clientDeptCardsData.map(
          (a: any) => ({
            ...a,
            visible: true,
          }),
        ),
        clientDeptCardsDataLoading: false,
      };

    case GET_CLIENT_DEPT_OBJ_REQUEST:
      return { ...state, clientDeptObjDataLoading: true };

    case GET_CLIENT_DEPT_OBJ_ERROR:
      return { ...state, clientDeptObjDataLoading: false };

    case GET_CLIENT_DEPT_OBJ_SUCCESS:
      return {
        ...state,
        clientDeptObjData: action.payload.clientDeptObjData.ClientDeptObject,
        clientDeptObjJobs: action.payload.clientDeptObjJobs,
        clientDeptObjDataLoading: false,
      };

    case SET_METADATA_VIEW_PREFERENCE:
      return {
        ...state,
        seeOnlyYourMetadata: action.payload.val,
      };

    case SET_CLIENT_CARD_VISIBLE:
      return {
        ...state,
        clientDeptCardsData: state.clientDeptCardsData.map((card) => {
          if (card.id !== action.payload.clientDeptCardId) {
            return card;
          }
          return {
            ...card,
            visible: action.payload.visible,
          };
        }),
      };

    default:
      return { ...state };
  }
};
