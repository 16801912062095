import { AnyAction } from "@reduxjs/toolkit";
import {
  GET_TEMP_PASSWORDS_DATA_REQUEST,
  GET_TEMP_PASSWORDS_DATA_SUCCESS,
  GET_TEMP_PASSWORDS_DATA_ERROR,
  GET_TEMP_PASSWORDS_FILTERS_REQUEST,
  GET_TEMP_PASSWORDS_FILTERS_SUCCESS,
  GET_TEMP_PASSWORDS_FILTERS_ERROR,
  SET_TEMP_PASSWORDS_FILTER_FIELD,
  CLEAR_TEMP_PASSWORDS_FILTERS,
} from "@kernel-store/actions";

export const getTempPasswordsDataRequest = (tempPasswordsByFieldParam: {
  page: number;
  page_size: number;
  search: string;
}): AnyAction => ({
  type: GET_TEMP_PASSWORDS_DATA_REQUEST,
  payload: { tempPasswordsByFieldParam },
});

export const getTempPasswordsDataSuccess = (
  tempPasswordsData: any,
): AnyAction => ({
  type: GET_TEMP_PASSWORDS_DATA_SUCCESS,
  payload: { tempPasswordsData },
});

export const getTempPasswordsDataError = (): AnyAction => ({
  type: GET_TEMP_PASSWORDS_DATA_ERROR,
  payload: {},
});

export const getTempPasswordsFiltersRequest = (): AnyAction => ({
  type: GET_TEMP_PASSWORDS_FILTERS_REQUEST,
  payload: {},
});

export const getTempPasswordsFiltersSuccess = (
  tempPasswordsFilters: any[],
): AnyAction => ({
  type: GET_TEMP_PASSWORDS_FILTERS_SUCCESS,
  payload: { tempPasswordsFilters },
});

export const getTempPasswordsFiltersError = (): AnyAction => ({
  type: GET_TEMP_PASSWORDS_FILTERS_ERROR,
  payload: {},
});

export const setTempPasswordsFilterField = (
  field: string,
  value: string,
): AnyAction => ({
  type: SET_TEMP_PASSWORDS_FILTER_FIELD,
  payload: { field, value },
});

export const clearTempPasswordsFilters = (): AnyAction => ({
  type: CLEAR_TEMP_PASSWORDS_FILTERS,
  payload: {},
});
