/* eslint-disable @typescript-eslint/no-use-before-define */
import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";
import { NotificationManager } from "@kernel-helpers/react-notifications";
import {
  acceptUserIntoCompanyFirebaseHelper,
  getRequestingUsers,
  createNewGroupWithUsers,
  deleteGroup,
  addUserToGroup,
  removeUserFromGroup,
  denyUserRequest,
  getCompanysGroups,
  deleteUsers,
  deleteCompany,
  updateGroup,
  sendCompanyNotification,
} from "@kernel-helpers/DatabaseHelpers";
import {
  initializeVirgil,
  isVirgilInitialized,
  acceptUserIntoCompanyVirgilHelper,
  joinGroup,
  restorePrivateKey,
} from "@tumeke/tumekejs/lib/auth";
import {
  ACCEPT_USER_INTO_COMPANY_REQUEST,
  INITIALIZE_REQUEST_LIST_REQUEST,
  INITIALIZE_GROUPS_LIST_REQUEST,
  CREATE_NEW_GROUP_REQUEST,
  DELETE_GROUP_REQUEST,
  MOVE_USER_TO_GROUP_REQUEST,
  ADD_USER_TO_GROUP_REQUEST,
  DENY_USER_REQUEST,
  ACCEPT_AND_ADD_TO_GROUP,
  DELETE_USERS_REQUEST,
  DELETE_COMPANY_REQUEST,
  UPDATE_GROUP_REQUEST,
  SEND_COMPANY_NOTIFICATIONS_REQUEST,
} from "@kernel-store/actions";

import { ReduxState } from "@kernel-store/reducers";
import {
  acceptUserIntoCompanySuccess,
  initializeRequestListSuccess,
  initializeGroupsListSuccess,
  initializeGroupsListError,
  createNewGroupSuccess,
  deleteGroupSuccess,
  moveUserToGroupSuccess,
  addUserToGroupSuccess,
  denyUserSuccess,
  acceptUserIntoCompanyRequest,
  addUserToGroupRequest,
  createNewGroupRequest,
  deleteUsersSuccess,
  deleteUsersError,
  deleteCompanySuccess,
  deleteCompanyError,
  sendCompanyNotificationsSuccess,
  sendCompanyNotificationsError,
} from "./actions";
import { loginUserSuccess, decreaseCompanyUserCount } from "../auth/actions";
import { getUsersDataSuccess } from "../users/actions";
import { getCompaniesDataSuccess } from "../companies/actions";

export function* watchAcceptIntoCompany() {
  yield takeEvery(
    ACCEPT_USER_INTO_COMPANY_REQUEST,
    acceptUserIntoCompanySagaHelper,
  );
}

export function* watchInitializeRequestList() {
  yield takeEvery(INITIALIZE_REQUEST_LIST_REQUEST, initializeRequestListHelper);
}

export function* watchCreateNewGroup() {
  yield takeEvery(CREATE_NEW_GROUP_REQUEST, createNewGroupSagaHelper);
}

export function* watchDeleteGroup() {
  yield takeEvery(DELETE_GROUP_REQUEST, deleteGroupSagaHelper);
}

export function* watchInitializeGroupsList() {
  yield takeEvery(INITIALIZE_GROUPS_LIST_REQUEST, initializeGroupsListHelper);
}

export function* watchMoveUserToGroupRequest() {
  yield takeEvery(MOVE_USER_TO_GROUP_REQUEST, moveUserToGroupHelper);
}

export function* watchAddUserToGroupRequest() {
  yield takeEvery(ADD_USER_TO_GROUP_REQUEST, addUserToGroupHelper);
}

export function* watchDenyUserRequest() {
  yield takeEvery(DENY_USER_REQUEST, denyUserHelper);
}

export function* watchAcceptAndAdd() {
  yield takeEvery(ACCEPT_AND_ADD_TO_GROUP, acceptAndAddSaga);
}

export function* watchDeleteUsersRequest() {
  yield takeEvery(DELETE_USERS_REQUEST, deleteUsersSaga);
}

export function* watchDeleteCompanyRequest() {
  yield takeEvery(DELETE_COMPANY_REQUEST, deleteCompanySaga);
}

export function* watchUpdateGroup() {
  yield takeEvery(UPDATE_GROUP_REQUEST, updateGroupSagaHelper);
}

export function* watchSendCompanyNotificationsRequest() {
  yield takeEvery(
    SEND_COMPANY_NOTIFICATIONS_REQUEST,
    sendCompanyNotificationsSaga,
  );
}

const handleProfileError = (e: any) => {
  if (e.response) {
    // TODO Change this
    NotificationManager.error(
      "Error",
      `${e.response.data.message} Please try again`,
      3000,
      null,
      null,
      "",
    );
    return;
  }
  NotificationManager.error(
    "Error",
    "Unknown server error. Please try again",
    3000,
    null,
    null,
    "",
  );
};

function* acceptAndAddSaga({ payload }: any) {
  const { user, groupName, isNewGroup, sendEmail } = payload;
  yield put(acceptUserIntoCompanyRequest(user, sendEmail));
  if (isNewGroup) {
    yield put(createNewGroupRequest([user], groupName));
  } else {
    yield put(addUserToGroupRequest(user, groupName));
  }
}

function* acceptUserIntoCompanySagaHelper({ payload }: any) {
  const state: ReduxState = yield select();
  const { user, company } = state.authUser;
  if (!user.isSSO && !company.virgil_off) {
    if (!isVirgilInitialized()) {
      console.log("not initialized");
      yield call(initializeVirgil, user.virgil_id);
      yield call(restorePrivateKey, "");
      yield call(joinGroup, user, state.authUser.company);
    }

    console.log(`VIRGIL ID: ${payload.user.virgil_id}`);
    try {
      yield call(acceptUserIntoCompanyVirgilHelper, payload.user.virgil_id);
    } catch (e) {
      NotificationManager.warning(
        "Error",
        "Error in verifying ID. Please refresh page and try again.",
        3000,
        null,
        null,
        "",
      );
      return;
    }
  }
  try {
    yield call(
      acceptUserIntoCompanyFirebaseHelper,
      payload.user.id,
      payload.sendEmail,
    );
  } catch (e) {
    handleProfileError(e);
    return;
  }

  yield put(acceptUserIntoCompanySuccess(payload.user.id));
}

function* initializeRequestListHelper() {
  let requestList: any = null;
  try {
    requestList = (yield call(getRequestingUsers)) as unknown as any;
  } catch (e) {
    handleProfileError(e);
    return;
  }

  yield put(initializeRequestListSuccess(requestList));
}

function* createNewGroupSagaHelper({ payload }: any) {
  let userGroups: any = null;

  const { users, groupName, accessMetadata, accessUsers } = payload;

  const accessGroups: {
    id?: number;
    user_id?: number;
    metadata_field_id?: number;
    metadata_option_id?: number;
  }[] = [];
  (accessMetadata || []).forEach((metadata: any) => {
    (metadata.values || []).forEach((value: any) => {
      accessGroups.push({
        metadata_field_id: metadata.field_id,
        metadata_option_id: value.value,
      });
    });
  });
  (accessUsers || []).forEach((user: any) => {
    accessGroups.push({ user_id: user.value });
  });

  try {
    yield call(createNewGroupWithUsers, users, groupName, accessGroups);
    userGroups = (yield call(getCompanysGroups)) as unknown as any;
  } catch (e) {
    handleProfileError(e);
    return;
  }

  yield put(createNewGroupSuccess(userGroups.groups));
}

function* updateGroupSagaHelper({ payload }: any) {
  let userGroups: any = null;

  const { groupId, groupName, accessMetadata, accessUsers } = payload;

  const accessGroups: {
    id?: number;
    user_id?: number;
    metadata_field_id?: number;
    metadata_option_id?: number;
  }[] = [];
  (accessMetadata || []).forEach((metadata: any) => {
    (metadata.values || []).forEach((value: any) => {
      const newAccessGroup = {
        id: undefined,
        metadata_field_id: metadata.field_id,
        metadata_option_id: value.value,
      };
      if (value.id) {
        newAccessGroup.id = value.id;
      }
      accessGroups.push(newAccessGroup);
    });
  });
  (accessUsers || []).forEach((user: any) => {
    const newUser = {
      id: undefined,
      user_id: user.value,
    };
    if (user.id) {
      newUser.id = user.id;
    }
    accessGroups.push(newUser);
  });

  try {
    yield call(updateGroup, groupId, groupName, accessGroups);
    userGroups = (yield call(getCompanysGroups)) as unknown as any;
  } catch (e) {
    handleProfileError(e);
    return;
  }

  yield put(createNewGroupSuccess(userGroups.groups));
}

function* deleteGroupSagaHelper({ payload }: any) {
  try {
    yield call(deleteGroup, payload.groupId);
  } catch (e) {
    handleProfileError(e);
    return;
  }
  yield put(deleteGroupSuccess(payload.groupId));
}

function* initializeGroupsListHelper() {
  const state = (yield select()) as unknown as any;
  let data = null;
  try {
    data = (yield call(getCompanysGroups)) as unknown as any;
  } catch (e) {
    yield put(initializeGroupsListError());
    handleProfileError(e);
    return;
  }

  console.log(`GROUPS: ${data.groups}`);
  console.log(`COMPANY: ${data.company}`);
  const currentCompany = state.authUser.company;

  yield put(
    loginUserSuccess(state.authUser.user, {
      ...data.company,
      aes_key: currentCompany.aes_key,
    }),
  );
  yield put(initializeGroupsListSuccess(data.groups));
}

function* moveUserToGroupHelper({ payload }: any) {
  try {
    yield call(addUserToGroup, payload.userId, payload.toGroup);
    yield call(removeUserFromGroup, payload.userId, payload.fromGroup);
  } catch (e) {
    handleProfileError(e);
    return;
  }

  yield put(
    moveUserToGroupSuccess(payload.userId, payload.toGroup, payload.fromGroup),
  );
}

function* addUserToGroupHelper({ payload }: any) {
  let userGroups = null;
  try {
    yield call(addUserToGroup, payload.user.id, payload.groupId);
    userGroups = (yield call(getCompanysGroups)) as unknown as any;
  } catch (e) {
    handleProfileError(e);
    return;
  }

  yield put(addUserToGroupSuccess(userGroups.groups));
}

function* denyUserHelper({ payload }: any) {
  yield call(denyUserRequest, payload.userId);
  yield put(denyUserSuccess(payload.userId));
  yield put(decreaseCompanyUserCount());
}

function* deleteUsersSaga({ payload }: any) {
  const { fromGroup, userIds, isFull } = payload;
  try {
    if (!userIds.length) {
      yield put(deleteUsersError(""));
    }
    (yield call(deleteUsers, userIds, isFull)) as unknown as any;
    yield put(deleteUsersSuccess(fromGroup, userIds));
    yield put(decreaseCompanyUserCount(userIds.length));
    const state: ReduxState = yield select();
    if (
      state.users.usersData?.length > 0 &&
      state.users.usersData.some((user: any) => userIds.includes(user.id))
    ) {
      const newUsersData = isFull
        ? state.users.usersData.filter((user: any) => userIds.includes(user.id))
        : state.users.usersData.map((user: any) =>
            userIds.includes(user.id) ? { ...user, role: "none" } : user,
          );
      yield put(
        getUsersDataSuccess({
          items: newUsersData,
          count: isFull ? state.users.usersCount - 1 : state.users.usersCount,
        }),
      );
      NotificationManager.success(
        "Users deleted successfully",
        "Users deleted",
        3000,
        null,
        null,
        "filled",
      );
    }
  } catch (e: any) {
    yield put(deleteUsersError(e?.message || "Error deleting users"));
    NotificationManager.error(
      e.response.data.message,
      "Error",
      3000,
      null,
      null,
      "",
    );
    console.log("DELETE USER ERROR", e);
  }
}

function* deleteCompanySaga({ payload }: any) {
  const { companyId } = payload;
  try {
    (yield call(deleteCompany, companyId)) as unknown as any;
    yield put(deleteCompanySuccess(companyId));
    const state: ReduxState = yield select();
    if (
      state.companies.companiesData?.length > 0 &&
      state.companies.companiesData.some(
        (company: any) => company.id === companyId,
      )
    ) {
      const newCompaniesData = state.companies.companiesData.filter(
        (company: any) => company.id !== companyId,
      );
      yield put(
        getCompaniesDataSuccess({
          items: newCompaniesData,
          count: state.companies.companiesCount - 1,
        }),
      );
      NotificationManager.success(
        "Company deleted successfully",
        "Company deleted",
        3000,
        null,
        null,
        "filled",
      );
    }
  } catch (e: any) {
    yield put(deleteCompanyError(e?.message || "Error deleting company"));
    NotificationManager.error(
      e.response.data.message,
      "Error",
      3000,
      null,
      null,
      "",
    );
    console.log("DELETE USER ERROR", e);
  }
}

function* sendCompanyNotificationsSaga({ payload }: any) {
  const { companyId, message } = payload;
  try {
    (yield call(sendCompanyNotification, companyId, message)) as unknown as any;
    yield put(sendCompanyNotificationsSuccess(companyId));
    NotificationManager.success(
      "Message sent successfully",
      "Message sent",
      3000,
      null,
      null,
      "filled",
    );
  } catch (e: any) {
    yield put(
      sendCompanyNotificationsError(e?.message || "Error sending a message"),
    );
    NotificationManager.error(
      e.response.data.message,
      "Error",
      3000,
      null,
      null,
      "",
    );
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchAcceptIntoCompany),
    fork(watchInitializeRequestList),
    fork(watchCreateNewGroup),
    fork(watchDeleteGroup),
    fork(watchInitializeGroupsList),
    fork(watchMoveUserToGroupRequest),
    fork(watchAddUserToGroupRequest),
    fork(watchDenyUserRequest),
    fork(watchAcceptAndAdd),
    fork(watchDeleteUsersRequest),
    fork(watchDeleteCompanyRequest),
    fork(watchUpdateGroup),
    fork(watchSendCompanyNotificationsRequest),
  ]);
}
