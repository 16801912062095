import { AnyAction } from "@reduxjs/toolkit";
import { defaultLocale } from "@kernel-constants/defaultValues";
import { CHANGE_LOCALE, SET_WEBAPP_LOGO } from "@kernel-store/actions";

export type SettingsReduxState = {
  locale?: string | null;
  logo?: string | null;
};

export const INIT_STATE: SettingsReduxState = {
  locale: defaultLocale,
  logo: "",
};

export default (
  state: SettingsReduxState = INIT_STATE,
  action: AnyAction = { type: undefined },
): SettingsReduxState => {
  switch (action.type) {
    case CHANGE_LOCALE:
      return { ...state, locale: action.payload.locale };

    case SET_WEBAPP_LOGO: {
      return {
        ...state,
        logo: action.payload.logo,
      };
    }

    default:
      return { ...state };
  }
};
