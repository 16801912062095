import { IStorageAdapter } from "@kernel-adapters/storage";
import { VideosReduxState } from "./reducer";

export const persistStore = async (
  state: VideosReduxState,
  storageAdapter: IStorageAdapter,
): Promise<VideosReduxState> => {
  let videoLimitCount: string | null = await storageAdapter.getItem(
    "videoLimitCount",
  );
  if (videoLimitCount === undefined || videoLimitCount === "undefined") {
    videoLimitCount = null;
  }

  return {
    ...state,
    videoLimitCount:
      videoLimitCount !== null ? JSON.parse(videoLimitCount) : null,
  };
};
