import { IStorageAdapter } from "@kernel-adapters/storage";
import { persistStore as adminProfilePersistStore } from "./adminProfile/persist";
import { INIT_STATE as adminProfileInitState } from "./adminProfile/reducer";
import { persistStore as authPersistStore } from "./auth/persist";
import { INIT_STATE as authInitState } from "./auth/reducer";
import { persistStore as dashboardPersistStore } from "./dashboard/persist";
import { INIT_STATE as dashboardInitState } from "./dashboard/reducer";
import { persistStore as menuPersistStore } from "./menu/persist";
import { INIT_STATE as menuInitState } from "./menu/reducer";
import { persistStore as notificationsPersistStore } from "./notifications/persist";
import { INIT_STATE as notificationsInitState } from "./notifications/reducer";
import { persistStore as roiPersistStore } from "./roi/persist";
import { INIT_STATE as roiInitState } from "./roi/reducer";
import { persistStore as settingsPersistStore } from "./settings/persist";
import { INIT_STATE as settingsInitState } from "./settings/reducer";
import { persistStore as treePersistStore } from "./tree/persist";
import { INIT_STATE as treeInitState } from "./tree/reducer";
import { persistStore as videosPersistStore } from "./videos/persist";
import { INIT_STATE as videosInitState } from "./videos/reducer";
import { persistStore as usersPersistStore } from "./users/persist";
import { INIT_STATE as usersInitState } from "./users/reducer";
import { persistStore as companiesPersistStore } from "./companies/persist";
import { INIT_STATE as companiesInitState } from "./companies/reducer";
import { persistStore as tempPasswordsPersistStore } from "./temp_passwords/persist";
import { INIT_STATE as tempPasswordsInitState } from "./temp_passwords/reducer";
import { persistStore as improvementsPersistStore } from "./improvements/persist";
import { INIT_STATE as improvementsInitState } from "./improvements/reducer";
import { ReduxState } from "./reducers";
import { ExtendedState, PersistStateItems, StateItems } from "../types";

export const updateFromPersist = async <
  S extends StateItems<S>,
  T extends PersistStateItems<S>,
>(
  storageAdapter: IStorageAdapter,
  extentedPersistStore: T,
): Promise<ExtendedState<S>> => {
  const persistedStore: ReduxState = {
    adminProfile: await adminProfilePersistStore(adminProfileInitState),
    authUser: await authPersistStore(authInitState, storageAdapter),
    dashboard: await dashboardPersistStore(dashboardInitState, storageAdapter),
    menu: await menuPersistStore(menuInitState),
    notifications: await notificationsPersistStore(notificationsInitState),
    roi: await roiPersistStore(roiInitState, storageAdapter),
    settings: await settingsPersistStore(settingsInitState, storageAdapter),
    tree: await treePersistStore(treeInitState),
    videos: await videosPersistStore(videosInitState, storageAdapter),
    users: await usersPersistStore(usersInitState),
    companies: await companiesPersistStore(companiesInitState),
    tempPasswords: await tempPasswordsPersistStore(tempPasswordsInitState),
    improvements: await improvementsPersistStore(improvementsInitState),
  };
  const extendedState: S = extentedPersistStore
    ? extentedPersistStore.store
    : ({} as S);
  if (extentedPersistStore?.persistStore) {
    const promises = Object.entries(extentedPersistStore.persistStore).map(
      ([key, persistStore]) =>
        persistStore(extentedPersistStore.store[key] as any),
    );
    const results = await Promise.all(promises);
    Object.keys(extentedPersistStore.persistStore).forEach((key, index) => {
      extendedState[key as keyof S] = results[index];
    });
  }

  return {
    ...persistedStore,
    ...extendedState,
  };
};
