import { AnyAction } from "@reduxjs/toolkit";
import {
  GET_VIDEO_LIST_REQUEST,
  GET_VIDEO_SUCCESS,
  GET_SKINNY_VIDEO_SUCCESS,
  ALL_VIDEOS_LOADED,
  LOADING_VIDEOS_START,
  MODIFY_VIDEO_DOC,
  UPDATE_ADDITIONAL_INFO,
  UPLOAD_ADDITIONAL_INFO,
  GET_JOINT_DATA,
  GET_JOINT_DATA_LEGACY,
  GET_JOINT_DATA_SUCCESS,
  UPLOAD_RISK_COMPONENTS,
  UPDATE_RISK_COMPONENTS,
  DELETE_VIDEOS_SUCCESS,
  DELETE_VIDEOS_REQUEST,
  ADD_METADATA_OPTION,
  DELETE_METADATA_OPTION,
  EDIT_METADATA_OPTION,
  DELETE_VIDEO_ASSESSMENT,
  DELETE_VIDEO_CLIP,
  SET_VIDEO_METADATA,
  GET_ALL_METADATA_FIELDS,
  GET_ALL_METADATA_FIELDS_SUCCESS,
  ADD_SINGLE_VIDEO_LISTENER,
  REMOVE_VIDEO_LISTENERS,
  GENERATE_REPORT_REQUEST,
  GENERATE_REPORT_SUCCESS,
  GENERATE_REPORT_ERROR,
  GENERATE_CSV_REQUEST,
  GENERATE_CSV_SUCCESS,
  GENERATE_CSV_ERROR,
  EXTERNAL_GENERATE_GPT_RECOMMENDATIONS_REQUEST,
  EXTERNAL_GENERATE_GPT_RECOMMENDATIONS_SUCCESS,
  EXTERNAL_GENERATE_GPT_RECOMMENDATIONS_ERROR,
  GET_THUMBNAIL_REQUEST,
  GET_THUMBNAILS_REQUEST,
  GET_THUMBNAIL_SUCCESS,
  SET_VIDEO_METADATA_SUCCESS,
  SUBMIT_VIDEO_FEEDBACK,
  FILTER_VIDEOS,
  GET_SINGLE_VIDEO_DOC_REQUEST,
  GET_SINGLE_VIDEO_DOC_ERROR,
  SET_SELECTED_POSTURE,
  SET_SELECTED_REP,
  SET_PERSON_ID,
  COMPARE_VIDEOS_REQUEST,
  COMPARE_VIDEOS_ERROR,
  RERUN_VIDEO_REQUEST,
  COMPARE_VIDEOS_SUCCESS,
  SAVE_VIDEO_NOTES_REQUEST,
  SAVE_VIDEO_NOTES_SUCCESS,
  UPDATE_VIDEO_NAME,
  UPDATE_ASSESSMENT_NAME,
  DOWNLOAD_VIDEO_REQUEST,
  DOWNLOAD_VIDEO_SUCCESS,
  DOWNLOAD_VIDEO_ERROR,
  SET_PAGE_METADATA,
  SET_WEBSOCKET_FAILED,
  UPDATE_ASSESSMENT_METADATA,
  UPDATE_ASSESSMENT_ROI_RESULTS,
  CLEAR_VIDEO_LIST,
  SET_ALL_INVISIBLE,
  SET_VIDEOS_VISIBILITY,
  GET_VIDEO_DOC_EXTERNAL,
  SET_RECOMMENDATION_GENERATION_LOADING,
  TOGGLE_ASSESSMENTS_LIMIT_MODAL,
  DOWNLOAD_RISK_JOINTS_CSV_SUCCESS,
  DOWNLOAD_RISK_JOINTS_CSV_ERROR,
  DOWNLOAD_RISK_JOINTS_CSV_REQUEST,
  GET_VIDEO_JOINT_URLS,
  ADD_VIDEO_JOINT_URLS,
  GET_LINKS,
  GET_LINKS_SUCCESS,
  UPDATE_LINK,
  DELETE_LINK,
  SEND_LINK,
  SET_PRIMARY_VIDEO_ASSESSMENT,
} from "@kernel-store/actions";
import { TPageMetadata } from "@kernel-store/videos/reducer";

/*
 * uploadRiskComponents
 * ------------------------
 * Dispatch meant to save the updated risk cgetJointDataomponent info in Firebase
 * ------------------------
 * Parameters:
 * 	- videoId: The ID of the video you're updating info for
 * 	- assessmentId: The ID of the Assessment (RULA/REBA/NIOSH) for the video
 *	- personId: The person whose info you're modifying
 */
export const uploadRiskComponents = (
  videoId: string,
  assessmentId: string,
  postureId: string,
): AnyAction => ({
  type: UPLOAD_RISK_COMPONENTS,
  payload: { videoId, assessmentId, postureId },
});

/*
 * updateRiskComponents
 * ------------------------
 * Dispatch meant to modify the individual components of a risk assessment.
 * ------------------------
 * Parameters:
 * 	- videoId: The ID of the video you're updating info for
 * 	- assessmentId: The ID of the Assessment (RULA/REBA/NIOSH) for the video
 *	- personId: The person whose info you're modifying
 *	- newInfo: A dictionary meant to codify the type of info your updating.
 *  	- newInfo["bodyGroup"]: The body part whose risk your modifying.
 *		- newInfo["type"]: The type of info your changing for that body part
 *		- newInfo["newValue"]: The new (numerical) value of that risk component.
 */

export const updateRiskComponents = (
  videoId: string,
  assessmentId: string,
  personId: string,
  newInfo: any,
): AnyAction => ({
  type: UPDATE_RISK_COMPONENTS,
  payload: { videoId, assessmentId, personId, newInfo },
});

export const uploadAdditionalInfo = (
  videoId: string,
  assessmentId: string,
  postureId: string,
): AnyAction => ({
  type: UPLOAD_ADDITIONAL_INFO,
  payload: { videoId, assessmentId, postureId },
});

export const updateAdditionalInfo = (
  videoId: string,
  assessmentId: string,
  personId: string,
  newInfo: any,
): AnyAction => ({
  type: UPDATE_ADDITIONAL_INFO,
  payload: { videoId, assessmentId, personId, newInfo },
});

export const updateAssessmentMetadata = (
  videoId: string,
  assessmentId: string,
  personId: string,
  newInfo: any,
): AnyAction => ({
  type: UPDATE_ASSESSMENT_METADATA,
  payload: { videoId, assessmentId, personId, newInfo },
});

export const updateAssessmentROIResults = (
  videoId: string,
  assessmentId: number,
  results: any,
): AnyAction => ({
  type: UPDATE_ASSESSMENT_ROI_RESULTS,
  payload: { videoId, assessmentId, results },
});

export const getVideoListRequest = (
  userId: string,
  companyId: string,
  groupId: string,
  pageSizeArg?: number,
  pageOffsetArg?: number,
  search?: string,
  history?: any,
): AnyAction => ({
  // History needed to logout user if something goes wrong in getting doc
  type: GET_VIDEO_LIST_REQUEST,
  payload: {
    userId,
    companyId,
    groupId,
    pageSizeArg,
    pageOffsetArg,
    search,
    history,
  },
});

export const addNewVideo = (
  videoObj: any,
  initializeAfter: boolean,
): AnyAction => ({
  type: GET_VIDEO_SUCCESS,
  payload: { videoObj, initializeAfter },
});

export const addNewSkinnyVideo = (
  videoObj: any,
  initializeAfter?: boolean,
): AnyAction => ({
  type: GET_SKINNY_VIDEO_SUCCESS,
  payload: { videoObj, initializeAfter },
});

export const getVideoDocExternal = (
  videoKey: string,
  callback?: Function,
): AnyAction => ({
  type: GET_VIDEO_DOC_EXTERNAL,
  payload: { videoKey, callback },
});

export const deleteVideosSuccess = (videoIds: string[]): AnyAction => ({
  type: DELETE_VIDEOS_SUCCESS,
  payload: { videoIds },
});

export const deleteVideoAssessment = (
  videoId: string,
  assessmentId: string,
): AnyAction => ({
  type: DELETE_VIDEO_ASSESSMENT,
  payload: { videoId, assessmentId },
});

export const deleteVideoClip = (
  videoId: string,
  clipId: number,
): AnyAction => ({
  type: DELETE_VIDEO_CLIP,
  payload: { videoId, clipId },
});

export const setPrimaryVideoAssessment = (
  videoId: string,
  assessmentId: string,
): AnyAction => ({
  type: SET_PRIMARY_VIDEO_ASSESSMENT,
  payload: { videoId, assessmentId },
});

export const rerunVideoRequest = (
  videoId: string,
  history: any,
): AnyAction => ({
  type: RERUN_VIDEO_REQUEST,
  payload: { videoId, history },
});

export const getThumbnailRequest = (
  videoId: string,
  taskId?: string,
  assessmentId?: string,
  postureId?: string,
  frameId?: string,
): AnyAction => ({
  type: GET_THUMBNAIL_REQUEST,
  payload: { videoId, taskId, assessmentId, postureId, frameId },
});

export const getThumbnailsRequest = (
  videoIds: string[],
  pullAssessmentThumbnails?: any,
  isImprovement?: boolean,
): AnyAction => ({
  type: GET_THUMBNAILS_REQUEST,
  payload: { videoIds, pullAssessmentThumbnails, isImprovement },
});

export const getThumbnailSuccess = (
  videoId: string,
  realThumbnailLoc: string,
  taskId?: string,
  assessmentId?: string,
  postureId?: string,
): AnyAction => ({
  type: GET_THUMBNAIL_SUCCESS,
  payload: { videoId, realThumbnailLoc, taskId, assessmentId, postureId },
});

export const deleteVideosRequest = (userId: string, videoIds: string[]) => ({
  type: DELETE_VIDEOS_REQUEST,
  payload: { userId, videoIds },
});

export const addMetadataOption = (
  fieldId: string,
  optionName: string,
  parentOptionId: string,
  fieldName: string,
): AnyAction => ({
  type: ADD_METADATA_OPTION,
  payload: { fieldId, optionName, parentOptionId, fieldName },
});

export const deleteMetadataOption = (
  optionId: string,
  optionName: string,
): AnyAction => ({
  type: DELETE_METADATA_OPTION,
  payload: { optionId, optionName },
});

export const editMetadataOption = (
  optionId: string,
  optionName: string,
  fieldName: string,
): AnyAction => ({
  type: EDIT_METADATA_OPTION,
  payload: { optionId, optionName, fieldName },
});

export const setVideoMetadataSuccess = (
  videoId: string,
  metadataObj: any,
): AnyAction => ({
  type: SET_VIDEO_METADATA_SUCCESS,
  payload: { videoId, metadataObj },
});
export const setVideoMetadata = (
  videoId: string,
  fieldId: string,
  optionId: string,
  fieldName: string,
): AnyAction => ({
  type: SET_VIDEO_METADATA,
  payload: { videoId, fieldId, optionId, fieldName },
});

export const getAllMetadataFields = (videoId: string): AnyAction => ({
  type: GET_ALL_METADATA_FIELDS,
  payload: { videoId },
});

export const getAllMetadataFieldsSuccess = (data: any): AnyAction => ({
  type: GET_ALL_METADATA_FIELDS_SUCCESS,
  payload: { data },
});

export const modifyVideoDoc = (newData: any): AnyAction => ({
  type: MODIFY_VIDEO_DOC,
  payload: { newData },
});

export const updateVideoName = (videoId: string, name: string): AnyAction => ({
  type: UPDATE_VIDEO_NAME,
  payload: { videoId, name },
});

export const updateAssessmentName = (
  videoId: string,
  assessmentId: string,
  name: string,
): AnyAction => ({
  type: UPDATE_ASSESSMENT_NAME,
  payload: { videoId, assessmentId, name },
});

export const setSelectedPosture = (idx: number): AnyAction => ({
  type: SET_SELECTED_POSTURE,
  payload: { idx },
});

export const clearVideoList = () => ({
  type: CLEAR_VIDEO_LIST,
  payload: {},
});

export const setSelectedRep = (videoId: string, idx: number): AnyAction => ({
  type: SET_SELECTED_REP,
  payload: { videoId, idx },
});

export const setPersonId = (idx: number): AnyAction => ({
  type: SET_PERSON_ID,
  payload: { idx },
});

export const getJointDataLegacy = (
  videoId: string,
  chunk: number,
): AnyAction => ({
  type: GET_JOINT_DATA_LEGACY,
  payload: { videoId, chunk },
});

export const getJointData = (
  videoId: string,
  chunk: number,
  assessmentId: number,
  clipId: number,
): AnyAction => ({
  type: GET_JOINT_DATA,
  payload: { videoId, assessmentId, clipId, chunk },
});

export const getJointDataSuccess = (
  videoId: string,
  data: any,
  chunk: number,
): AnyAction => ({
  type: GET_JOINT_DATA_SUCCESS,
  payload: { videoId, data, chunk },
});

export const loadingVideos = (): AnyAction => ({
  type: LOADING_VIDEOS_START,
  payload: {},
});

export const addSingleVideoListener = (
  videoId: string,
  listenerType?: string,
  isExternal?: boolean,
  assessmentId?: number,
): AnyAction => ({
  type: ADD_SINGLE_VIDEO_LISTENER,
  payload: { videoId, listenerType, isExternal, assessmentId },
});

export const allVideosLoaded = (
  videoCount: number,
  totalVideoDurationMins: number,
  videoLimitCount: number,
): AnyAction => ({
  type: ALL_VIDEOS_LOADED,
  payload: { videoCount, totalVideoDurationMins, videoLimitCount },
});

export const removeVideoListeners = (videoId?: string): AnyAction => ({
  type: REMOVE_VIDEO_LISTENERS,
  payload: { videoId },
});

export const generateReportRequest = (
  videoId: string,
  assessmentId: string,
  subjectId: string,
  postureId?: string,
  reportType?: string,
  reportPages?: string[],
): AnyAction => ({
  type: GENERATE_REPORT_REQUEST,
  payload: {
    videoId,
    assessmentId,
    subjectId,
    postureId,
    reportType,
    reportPages,
  },
});

export const generateReportError = (): AnyAction => ({
  type: GENERATE_REPORT_ERROR,
  payload: {},
});

export const generateReportSuccess = (): AnyAction => ({
  type: GENERATE_REPORT_SUCCESS,
  payload: {},
});

export const generateCSVRequest = (
  videoId: string,
  subjectId: string,
): AnyAction => ({
  type: GENERATE_CSV_REQUEST,
  payload: { videoId, subjectId },
});

export const externalGenerateGPTRecommendationsRequest = (
  videoKey: string,
  assessmentId: number,
): AnyAction => ({
  type: EXTERNAL_GENERATE_GPT_RECOMMENDATIONS_REQUEST,
  payload: { videoKey, assessmentId },
});

export const externalGenerateGPTRecommendationsSuccess = (): AnyAction => ({
  type: EXTERNAL_GENERATE_GPT_RECOMMENDATIONS_SUCCESS,
  payload: {},
});

export const externalGenerateGPTRecommendationsError = (): AnyAction => ({
  type: EXTERNAL_GENERATE_GPT_RECOMMENDATIONS_ERROR,
  payload: {},
});

export const downloadVideoRequest = (
  videoId: string,
  email: string,
): AnyAction => ({
  type: DOWNLOAD_VIDEO_REQUEST,
  payload: { videoId, email },
});

export const downloadVideoError = (): AnyAction => ({
  type: DOWNLOAD_VIDEO_ERROR,
  payload: {},
});

export const downloadVideoSuccess = (): AnyAction => ({
  type: DOWNLOAD_VIDEO_SUCCESS,
  payload: {},
});

export const downloadRiskJointsCsvRequest = (
  videoId: string,
  assessmentId: number,
): AnyAction => ({
  type: DOWNLOAD_RISK_JOINTS_CSV_REQUEST,
  payload: { videoId, assessmentId },
});

export const downloadRiskJointsCsvError = (): AnyAction => ({
  type: DOWNLOAD_RISK_JOINTS_CSV_ERROR,
  payload: {},
});

export const downloadRiskJointsCsvSuccess = (): AnyAction => ({
  type: DOWNLOAD_RISK_JOINTS_CSV_SUCCESS,
  payload: {},
});

export const generateCSVError = (): AnyAction => ({
  type: GENERATE_CSV_ERROR,
  payload: {},
});

export const generateCSVSuccess = (): AnyAction => ({
  type: GENERATE_CSV_SUCCESS,
  payload: {},
});

export const getVideoJointUrls = (videoId: string): AnyAction => ({
  type: GET_VIDEO_JOINT_URLS,
  payload: { videoId },
});

export const addVideoJointUrls = (
  videoId: string,
  jointUrls: any,
): AnyAction => ({
  type: ADD_VIDEO_JOINT_URLS,
  payload: { videoId, jointUrls },
});

export const submitVideoFeedback = (
  rating: any,
  feedbackText: string,
): AnyAction => ({
  type: SUBMIT_VIDEO_FEEDBACK,
  payload: { rating, feedbackText },
});

export const getSingleVideoRequest = (videoId: string): AnyAction => ({
  type: GET_SINGLE_VIDEO_DOC_REQUEST,
  payload: { videoId },
});

export const getSingleVideoError = (
  videoId: string,
  msg: string,
): AnyAction => ({
  type: GET_SINGLE_VIDEO_DOC_ERROR,
  payload: { videoId, msg },
});

export const filterVideos = (videoIds: string[]): AnyAction => ({
  type: FILTER_VIDEOS,
  payload: { videoIds },
});

export const compareVideosRequest = (videoIds: string[]): AnyAction => ({
  type: COMPARE_VIDEOS_REQUEST,
  payload: { videoIds },
});

export const compareVideosError = (videoIds: string[]): AnyAction => ({
  type: COMPARE_VIDEOS_ERROR,
  payload: { videoIds },
});

export const compareVideosSuccess = (
  videoIds: string[],
  assessmentIds: string[],
  data: any,
): AnyAction => ({
  type: COMPARE_VIDEOS_SUCCESS,
  payload: { videoIds, assessmentIds, data },
});

export const saveVideoNotesRequest = (
  videoId: string,
  assessmentId: string,
  notes: any[],
  notesKey: string,
): AnyAction => ({
  type: SAVE_VIDEO_NOTES_REQUEST,
  payload: { videoId, assessmentId, notes, notesKey },
});

export const saveVideoNotesSuccess = (
  videoId: string,
  assessmentId: string,
  notes: any[],
  notesKey: string,
): AnyAction => ({
  type: SAVE_VIDEO_NOTES_SUCCESS,
  payload: { videoId, assessmentId, notes, notesKey },
});

export const setPageMetadata = ({
  currentPage,
  pageSize,
  search,
}: TPageMetadata): AnyAction => ({
  type: SET_PAGE_METADATA,
  payload: { currentPage, pageSize, search },
});

export const setWebsocketFailed = (websocketFailed: boolean): AnyAction => ({
  type: SET_WEBSOCKET_FAILED,
  payload: { websocketFailed },
});

export const setAllInvisible = (): AnyAction => ({
  type: SET_ALL_INVISIBLE,
  payload: {},
});

export const setVideosVisibility = (
  videoIds: string[],
  visible: boolean,
): AnyAction => ({
  type: SET_VIDEOS_VISIBILITY,
  payload: { videoIds, visible },
});

export const setRecLoading = (recommendationLoading: boolean): AnyAction => ({
  type: SET_RECOMMENDATION_GENERATION_LOADING,
  payload: { recommendationLoading },
});

export const toggleAssessmentsLimitModal = (): AnyAction => ({
  type: TOGGLE_ASSESSMENTS_LIMIT_MODAL,
});

export const getLinks = (): AnyAction => ({
  type: GET_LINKS,
  payload: {},
});

export const getLinksSuccess = (linksList: any[]): AnyAction => ({
  type: GET_LINKS_SUCCESS,
  payload: { linksList },
});

export const updateLink = (linkId: number, values: any): AnyAction => ({
  type: UPDATE_LINK,
  payload: { linkId, values },
});

export const deleteLink = (linkId: number): AnyAction => ({
  type: DELETE_LINK,
  payload: { linkId },
});

export const sendLink = (
  linkId: number,
  value: string,
  type: string,
): AnyAction => ({
  type: SEND_LINK,
  payload: { linkId, value, type },
});

/*
 * Some more info on "updateRiskComponents" and "uploadRiskComponents". These functions
 * are meant to modify the individual components of risk. The first step is to look at Firebase
 * to see the risk component store schema. 
 * 
 * For the Reba/Rula assessment, you'll notice that the risk components
 * are stored in a dictionary, with the first key noting the body part
 * and the second key noting a subtype of information for that body part. 
 * For example, riskAssessment.riskComponent.Trunk.Score is the 
 * overall risk rating of the trunk, which is equivalent to the 4 big trunk categories
 * the rula/reba risk form gives. If I want to update this Score with a new value, I'd call the
 * updateRiskComponents dispatch, as follows: 
 * 
 * this.props.updateRiskComponents(this.props.videoKey, this.props.personId, {
      bodyGroup: "Trunk",
      type: "Score",
      newValue: 4 // This is the new value
    }) 
 * If you wanted to set the "neck twist" risk category to true, just do:
 * this.props.updateRiskComponents(this.props.videoKey, this.props.personId, {
      bodyGroup: "Neck",
      type: "Twist",
      newValue: 1 // 1 = true
    }) 
 * To make sure this update is reflected on firebase, simply call:
 *
 * this.props.uploadRiskComponents(this.props.videoKey, this.props.personId);
 */
