/* eslint-disable import/no-import-module-exports */
import {
  configureStore,
  Middleware,
  EnhancedStore,
  AnyAction,
  ReducersMapObject,
} from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import mergeReducers, { emptyReducer, EmptyState } from "./reducers";
import sagas from "./sagas";
import { ExtendedStore, ExtendedState } from "../types";

const sagaMiddleware = createSagaMiddleware();

const middlewares: Middleware[] = [sagaMiddleware];

declare const module: {
  hot: { accept: (path: string, callback: () => void) => void };
};

export const initializeStore = <S>(
  initialState: ExtendedState<S> | undefined,
  extendedReducers: ReducersMapObject<S>,
): EnhancedStore<ExtendedState<S> | EmptyState, AnyAction, Middleware[]> => {
  const store = configureStore({
    reducer: mergeReducers(extendedReducers as any),
    preloadedState: initialState,
    middleware: middlewares,
  });

  sagaMiddleware.run(sagas);

  if (typeof module !== "undefined" && module && module.hot) {
    module.hot.accept("./reducers", () => {
      // eslint-disable-next-line global-require
      const nextRootReducer = require("./reducers");
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
};

export const initializeEmptyStore = <S>(): ExtendedStore<S> => {
  const store = configureStore({
    reducer: emptyReducer,
  });

  return store;
};
