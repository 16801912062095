import { AnyAction } from "@reduxjs/toolkit";
import {
  MENU_SET_CLASSNAMES,
  MENU_CONTAINER_ADD_CLASSNAME,
  MENU_CLICK_MOBILE_MENU,
  MENU_CHANGE_DEFAULT_CLASSES,
  MENU_CHANGE_HAS_SUB_ITEM_STATUS,
} from "@kernel-store/actions";
import {
  defaultMenuType,
  subHiddenBreakpoint,
  menuHiddenBreakpoint,
} from "@kernel-constants/defaultValues";

export type MenuReduxState = {
  containerClassnames: string;
  subHiddenBreakpoint: number;
  menuHiddenBreakpoint: number;
  menuClickCount: number;
  selectedMenuHasSubItems: boolean;
};

export const INIT_STATE: MenuReduxState = {
  containerClassnames: defaultMenuType,
  subHiddenBreakpoint,
  menuHiddenBreakpoint,
  menuClickCount: 0,
  selectedMenuHasSubItems: defaultMenuType === "menu-default", // if you use menu-sub-hidden as default menu type, set value of this variable to false
};

export default (
  state: MenuReduxState = INIT_STATE,
  action: AnyAction = { type: undefined },
) => {
  switch (action.type) {
    case MENU_CHANGE_HAS_SUB_ITEM_STATUS:
      return { ...state, selectedMenuHasSubItems: action.payload.hasSubItems };

    case MENU_SET_CLASSNAMES:
      return {
        ...state,
        containerClassnames: action.payload.containerClassnames,
        menuClickCount: action.payload.menuClickCount,
      };

    case MENU_CLICK_MOBILE_MENU:
      return {
        ...state,
        containerClassnames: action.payload.containerClassnames,
        menuClickCount: action.payload.menuClickCount,
      };

    case MENU_CONTAINER_ADD_CLASSNAME:
      return { ...state, containerClassnames: action.payload.newClasses };

    case MENU_CHANGE_DEFAULT_CLASSES:
      return {
        ...state,
        containerClassnames: action.payload.strCurrentClasses,
      };

    default:
      return { ...state };
  }
};
