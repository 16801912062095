/* eslint-disable @typescript-eslint/no-use-before-define */
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { NotificationManager } from "@kernel-helpers/react-notifications";
import { getUsersData } from "@kernel-helpers/DatabaseHelpers";

import {
  GET_USERS_DATA_REQUEST,
  GET_USERS_DATA_ERROR,
  GET_USERS_DATA_SUCCESS,
} from "@kernel-store/actions";
import { getUsersDataSuccess, getUsersDataError } from "./actions";

export function* watchGetUsersDataRequest() {
  yield takeEvery(GET_USERS_DATA_REQUEST, getUsersDataRequestSaga);
}

export function* watchGetUsersDataError() {
  yield takeEvery(GET_USERS_DATA_ERROR, getUsersDataErrorSaga);
}

export function* watchGetUsersDataSuccess() {
  yield takeEvery(GET_USERS_DATA_SUCCESS, getUsersDataSuccessSaga);
}
function* getUsersDataErrorSaga() {
  yield 0;
  NotificationManager.warning(
    "Error getting data. Please take a screenshot and contact support.",
    "Error Getting Data",
    5000,
    null,
    null,
    "",
  );
}

function* getUsersDataSuccessSaga() {
  // const state: ReduxState = yield select();
  // console.log("[GET_USER_LIST] usersSuccess", state);
  // yield put(
  //   getVideoListRequest(
  //     state.authUser.user.uid,
  //     state.authUser.user.companyId,
  //     state.authUser.user.groupId,
  //   ),
  // );
}

function* getUsersDataRequestSaga({ payload }: any) {
  const { usersByFieldParam } = payload;
  let usersData = null;
  try {
    usersData = (yield call(getUsersData, usersByFieldParam)) as unknown as any;
  } catch (e) {
    yield put(getUsersDataError());
    return;
  }
  yield put(getUsersDataSuccess(usersData));
}

export default function* rootSaga() {
  yield all([
    fork(watchGetUsersDataRequest),
    fork(watchGetUsersDataError),
    fork(watchGetUsersDataSuccess),
  ]);
}
