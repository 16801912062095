import { AnyAction } from "@reduxjs/toolkit";
import { TumekeJSModule, EAdapterNames } from "@kernel";
import { IStorageAdapter } from "@kernel-adapters/storage";
import {
  GET_USERS_DATA_REQUEST,
  GET_USERS_DATA_SUCCESS,
  GET_USERS_DATA_ERROR,
  GET_USERS_FILTERS_REQUEST,
  GET_USERS_FILTERS_SUCCESS,
  GET_USERS_FILTERS_ERROR,
  SET_USERS_FILTER_FIELD,
  CLEAR_USERS_FILTERS,
} from "@kernel-store/actions";

export type TUsersFilter = {
  assessment_types: any;
  assessors: any;
  start_date: any;
  end_date: any;
  metadata_array: any;
  groups: any;
  subject1_risk_levels: any;
};

export const EMPTY_FILTER: TUsersFilter = {
  assessment_types: [],
  assessors: [],
  start_date: null,
  end_date: null,
  metadata_array: [],
  groups: [],
  subject1_risk_levels: [],
};

export type UsersReduxState = {
  usersData: any;
  usersDataLoading: boolean;
  usersCount: number;
  usersFilters: any;
  usersDataDirty: boolean;
  filterObject: TUsersFilter;
  refresh: boolean;
};

export const INIT_STATE: UsersReduxState = {
  usersData: [],
  usersDataLoading: false,
  usersCount: 0,
  usersFilters: {},
  usersDataDirty: false,
  filterObject: EMPTY_FILTER,
  refresh: false,
};

const getUnvisited = (metadataSort: any[]) => {
  const arr = metadataSort.filter((m) => !m.visited);
  return arr;
};

const visit = (node: any, newMetadata: any[], metadata: any[]): void => {
  if (node.visited) {
    return;
  }
  const newNode = node;
  newNode.temp = true;
  if (node.data[2] != null) {
    const parentNode = metadata.filter((m) => m.data[1] === node.data[2])[0];
    visit(parentNode, newMetadata, metadata);
  }
  newNode.temp = false;
  newNode.visited = true;
  newMetadata.push(node);
};

const sortMetadataArray = (metadata: any[]): any[] => {
  const metadataSort = [];
  const newMetadata = [];
  for (let i = 0; i < metadata.length; i += 1) {
    metadataSort.push({
      data: metadata[i],
      visited: false,
    });
  }
  newMetadata.push(
    metadataSort.filter((m) => m.data[0] === "Assessment Version" || m.data[0] === "Attempt Number")[0],
  );
  newMetadata[0].visited = true;
  let unvisited = getUnvisited(metadataSort);
  while (unvisited.length > 0) {
    const node = unvisited[0];
    visit(node, newMetadata, metadataSort);
    unvisited = getUnvisited(metadataSort);
  }
  const ret = [];
  for (let i = 0; i < newMetadata.length; i += 1) {
    ret.push(newMetadata[i].data);
  }
  return ret;
};

export default (
  state: UsersReduxState = INIT_STATE,
  action: AnyAction = { type: undefined },
) => {
  switch (action.type) {
    case GET_USERS_DATA_REQUEST:
      return { ...state, usersDataLoading: true };

    case GET_USERS_DATA_SUCCESS:
      return {
        ...state,
        usersData: action.payload.usersData.items,
        usersCount: action.payload.usersData.count,
        usersDataLoading: false,
      };
    case GET_USERS_DATA_ERROR:
      // TODO(znoland): consider setting data to zero to make it clear data is not available?
      return { ...state, usersDataLoading: false };

    case GET_USERS_FILTERS_REQUEST:
      return { ...state };

    case GET_USERS_FILTERS_SUCCESS: {
      const metadataOptionList = sortMetadataArray(
        action.payload.usersFilters.metadataOptionList,
      );
      return {
        ...state,
        usersFilters: {
          ...action.payload.usersFilters,
          metadataOptionList,
        },
      };
    }
    case GET_USERS_FILTERS_ERROR:
      return { ...state };

    case SET_USERS_FILTER_FIELD: {
      const stogareAdapter = TumekeJSModule.get(
        EAdapterNames.Storage,
      ) as IStorageAdapter;
      stogareAdapter.setItem(
        "filterObject",
        JSON.stringify({
          ...state.filterObject,
          [action.payload.field]: action.payload.value,
        }),
      );
      return {
        ...state,
        refresh: !state.refresh,
        filterObject: {
          ...state.filterObject,
          [action.payload.field]: action.payload.value,
        },
        usersDataLoading: true,
      };
    }
    case CLEAR_USERS_FILTERS:
      return {
        ...state,
        filterObject: EMPTY_FILTER,
      };
    default:
      return { ...state };
  }
};
