import {
  cognitoAuthUser,
  cognitoRegisterUser,
  cognitoChangePasswordWeb,
  cognitoChangePasswordRN,
  cognitoRefreshTokenWeb,
  cognitoRefreshTokenRN,
  COGNITO_CONFIG,
  initializeUserPools,
  asyncGetIdToken,
  refreshTokenHelper,
  asyncStoreIdToken,
  asyncStore,
  cognitoInitiateForgotPassword,
  cognitoConfirmPasswordReset,
  cognitoSendVerificationcode,
  hasCurrentUser,
  setUserSession,
  cognitoSignoutUser,
} from "./auth/Cognito";
import { getCompareObject } from "./utils/compare";
import { getRecommendations } from "./utils/recommendations";
import { Rula } from "./assessments/Rula";
import { Reba } from "./assessments/Reba";
import { Niosh } from "./assessments/Niosh";
import { HandStrain } from "./assessments/HandStrain";
import { LibertyMutual } from "./assessments/LibertyMutual";
import { Training } from "./assessments/Training";

import RulaConfig from "./configs/rula.json";
import RebaConfig from "./configs/reba.json";
import NioshConfig from "./configs/niosh.json";
import HandStrainConfig from "./configs/handstrain.json";
import LibertyMutualConfig from "./configs/libertymutual.json";
import TrainingConfig from "./configs/training.json";

export {
  Rula,
  Reba,
  Niosh,
  HandStrain,
  LibertyMutual,
  Training,
  RulaConfig,
  RebaConfig,
  NioshConfig,
  HandStrainConfig,
  LibertyMutualConfig,
  TrainingConfig,
  getCompareObject,
  getRecommendations,
  cognitoAuthUser,
  cognitoRegisterUser,
  cognitoChangePasswordWeb,
  cognitoChangePasswordRN,
  cognitoRefreshTokenWeb,
  cognitoRefreshTokenRN,
  COGNITO_CONFIG,
  initializeUserPools,
  asyncGetIdToken,
  refreshTokenHelper,
  asyncStoreIdToken,
  asyncStore,
  cognitoInitiateForgotPassword,
  cognitoConfirmPasswordReset,
  cognitoSendVerificationcode,
  hasCurrentUser,
  setUserSession,
  cognitoSignoutUser,
};
