/* eslint-disable import/no-cycle */
import { AnyAction } from "@reduxjs/toolkit";
import clone from "lodash/clone";
import omit from "lodash/omit";

import { TumekeJSModule, EAdapterNames } from "@kernel";
import { IStorageAdapter } from "@kernel-adapters/storage";
import {
  Rula,
  Reba,
  Niosh,
  HandStrain,
  LibertyMutual,
  Training,
} from "@tumeke/tumekejs";
import { Assessments, ProcessingStatus } from "@kernel-constants/maps";
import { getAssessmentFromVideo } from "@kernel-helpers/filters/VideoFilters";
import {
  GET_SINGLE_VIDEO_DOC_ERROR,
  GET_VIDEO_SUCCESS,
  MODIFY_VIDEO_DOC,
  ALL_VIDEOS_LOADED,
  LOADING_VIDEOS_START,
  UPDATE_ADDITIONAL_INFO,
  GET_JOINT_DATA_SUCCESS,
  UPDATE_RISK_COMPONENTS,
  ADD_METADATA_OPTION,
  DELETE_METADATA_OPTION,
  EDIT_METADATA_OPTION,
  GET_ALL_METADATA_FIELDS,
  GET_ALL_METADATA_FIELDS_SUCCESS,
  DELETE_VIDEOS_SUCCESS,
  DELETE_VIDEO_ASSESSMENT,
  DELETE_VIDEO_CLIP,
  GENERATE_REPORT_REQUEST,
  GENERATE_REPORT_SUCCESS,
  GENERATE_REPORT_ERROR,
  GENERATE_CSV_REQUEST,
  GENERATE_CSV_SUCCESS,
  GENERATE_CSV_ERROR,
  DOWNLOAD_VIDEO_ERROR,
  DOWNLOAD_VIDEO_SUCCESS,
  DOWNLOAD_VIDEO_REQUEST,
  DOWNLOAD_RISK_JOINTS_CSV_ERROR,
  DOWNLOAD_RISK_JOINTS_CSV_SUCCESS,
  DOWNLOAD_RISK_JOINTS_CSV_REQUEST,
  GET_THUMBNAIL_SUCCESS,
  SUBMIT_VIDEO_FEEDBACK,
  SET_VIDEO_METADATA_SUCCESS,
  FILTER_VIDEOS,
  COMPARE_VIDEOS_REQUEST,
  COMPARE_VIDEOS_ERROR,
  COMPARE_VIDEOS_SUCCESS,
  SET_SELECTED_POSTURE,
  SET_SELECTED_REP,
  SET_PERSON_ID,
  SAVE_VIDEO_NOTES_SUCCESS,
  UPDATE_VIDEO_NAME,
  UPDATE_ASSESSMENT_NAME,
  SET_PAGE_METADATA,
  GET_SKINNY_VIDEO_SUCCESS,
  SET_WEBSOCKET_FAILED,
  UPDATE_ASSESSMENT_METADATA,
  UPDATE_ASSESSMENT_ROI_RESULTS,
  CLEAR_VIDEO_LIST,
  SET_ALL_INVISIBLE,
  SET_VIDEOS_VISIBILITY,
  SET_RECOMMENDATION_GENERATION_LOADING,
  TOGGLE_ASSESSMENTS_LIMIT_MODAL,
  ADD_VIDEO_JOINT_URLS,
  GET_LINKS,
  GET_LINKS_SUCCESS,
  UPDATE_LINK,
  DELETE_LINK,
  SET_PRIMARY_VIDEO_ASSESSMENT,
} from "@kernel-store/actions";

import exampleVideo from "./example.json";

export type TPageMetadata = {
  currentPage: number;
  search: string;
  pageSize: number;
};

export type VideosReduxState = {
  videoList: any;
  videosLoaded: boolean;
  videosLoading: boolean;
  refresh: boolean;
  optionsMetadataArray: any[];
  selectedMetadataObj: any;
  reportLoading: boolean;
  worksheetLoading: boolean;
  csvLoading: boolean;
  selectedPosture: number;
  selectedRepInPosture: number;
  personId: number;
  compareLoading: boolean;
  compareVideoIds: any[];
  assessmentIds: any[];
  compareVideoData: any;
  videoDownloading: boolean;
  riskJointsCsvDownloading: boolean;
  websocketFailed: boolean;
  videoCount: number;
  totalVideoDurationMins: number;
  videoLimitCount: number;
  recommendationLoading: boolean;
  pageMetadata: TPageMetadata;
  linksList: any[];
  linksLoading: boolean;
  assessmentsLimitModal: boolean;
};

export const INIT_STATE: VideosReduxState = {
  videoList: {
    example: exampleVideo,
  },
  videosLoaded: false,
  videosLoading: false,
  refresh: false,
  optionsMetadataArray: [],
  selectedMetadataObj: {},
  reportLoading: false,
  worksheetLoading: false,
  csvLoading: false,
  selectedPosture: 0,
  selectedRepInPosture: 0,
  personId: 0,
  compareLoading: false,
  compareVideoIds: [],
  assessmentIds: [],
  compareVideoData: {},
  videoDownloading: false,
  riskJointsCsvDownloading: false,
  websocketFailed: false,
  videoCount: 0,
  totalVideoDurationMins: 0,
  videoLimitCount: 0,
  pageMetadata: {
    currentPage: 1,
    search: "",
    pageSize: 8,
  },
  recommendationLoading: false,
  linksList: [],
  linksLoading: false,
  assessmentsLimitModal: false,
};

const DOSAGE_STATS_TEMPLATE = {
  frequency: 0.0,
  duration: 0.0,
  lowContrib: 1.0,
  mediumContrib: 1.0,
  highContrib: 1.0,
  vhighContrib: 1.0,
};

const getAssessmentFromSingleVideo = (
  videoObj: any,
  assessmentId: string,
): any | undefined => {
  if (!("tasks" in videoObj)) return undefined;

  const assessmentObjList: any[] = [];
  videoObj.tasks.forEach((task: any) => {
    task.assessments.forEach((assessment: any) => {
      assessmentObjList.push(assessment);
    });
  });

  return assessmentObjList.filter(
    (x) => x.id === parseInt(assessmentId, 10),
  )[0];
};

const notesHaveChangedHelper = (oldVideo: any, newVideo: any): boolean => {
  if (
    !oldVideo ||
    !newVideo ||
    !Object.prototype.hasOwnProperty.call(oldVideo, "tasks") ||
    !Object.prototype.hasOwnProperty.call(newVideo, "tasks")
  ) {
    return false;
  }
  if (oldVideo.tasks.length === 0 || newVideo.tasks.length === 0) {
    return false;
  }
  for (let i = 0; i < oldVideo.tasks.length; i++) {
    for (let j = 0; j < oldVideo.tasks[i].assessments.length; j++) {
      const oldAssessmentObj = oldVideo.tasks[i].assessments[j];
      const newAssessmentObj = getAssessmentFromSingleVideo(
        newVideo,
        oldAssessmentObj.id,
      );
      if (oldAssessmentObj.data.notes !== newAssessmentObj.data.notes) {
        return true;
      }
    }
  }
  return false;
};

export default (
  state: VideosReduxState = INIT_STATE,
  action: AnyAction = { type: undefined },
): VideosReduxState => {
  switch (action.type) {
    case GET_SKINNY_VIDEO_SUCCESS: {
      const videoId = action.payload.videoObj.key;
      const { videoObj } = action.payload;
      /** *
       *  Initialize
       *
       *  - Generate locally stored versions of the assessment objects
       */
      // TODO(znoland): old copy commented out below this if statement. remove when done
      const { tasks } = videoObj;
      // This means there's already been a full vid doc pulled for this item
      // Don't then pull skinny doc after
      if (
        state.videoList[videoId] &&
        state.videoList[videoId].tasks &&
        state.videoList[videoId].tasks[0] &&
        state.videoList[videoId].tasks[0].assessments &&
        Object.prototype.hasOwnProperty.call(
          state.videoList[videoId].tasks[0].assessments[0],
          "posture_assessments",
        )
      ) {
        return state;
      }
      // loop through tasks
      for (let iIask = 0; iIask < tasks.length; iIask += 1) {
        // loop through assessments
        for (
          let iAssessment = 0;
          iAssessment < tasks[iIask].assessments.length;
          iAssessment += 1
        ) {
          // const assessment = tasks[iIask].assessments[iAssessment];
          // Set video thumbnail
        }
      }
      if (videoId in state.videoList) {
        if (
          state.videoList[videoId].thumbnailLoc &&
          state.videoList[videoId].thumbnailLoc.startsWith("https://")
        ) {
          videoObj.thumbnailLoc = state.videoList[videoId].thumbnailLoc;
        }
      }

      return {
        ...state,
        videoList: {
          ...state.videoList,
          [videoId]: videoObj,
        },
      };
    }
    case GET_VIDEO_SUCCESS: {
      const videoId = action.payload.videoObj.key;
      let { recommendationLoading } = state;
      let { videoObj } = action.payload;
      /** *
       *  Update only - if already initialized
       *
       *  - This means listener has already been initialized, and the user is interacting
       *    with the video in the GUI. In this case don't accept videos from snapshot listener
       */
      if (
        state.videoList[videoId] &&
        state.videoList[videoId].tasks?.length > 0 &&
        state.videoList[videoId].tasks[0].assessments?.length > 0 &&
        state.videoList[videoId].tasks[0].assessments[0].posture_assessments
          ?.length > 0 &&
        state.videoList[videoId].tasks[0].assessments[0].posture_assessments[0]
          .riskAssessment &&
        Object.prototype.hasOwnProperty.call(
          state.videoList[videoId].tasks[0].assessments[0]
            .posture_assessments[0].riskAssessment,
          "initialized",
        )
      ) {
        const newVideoObj = action.payload.videoObj;
        const additionsToObj: any = {};
        if (Object.prototype.hasOwnProperty.call(newVideoObj, "videoLoc")) {
          additionsToObj.videoLoc = newVideoObj.videoLoc;
        }

        // TODO(znoland): update this!
        if (
          Object.prototype.hasOwnProperty.call(newVideoObj, "processingStatus")
        ) {
          additionsToObj.processingStatus = newVideoObj.processingStatus;
        }
        if (Object.prototype.hasOwnProperty.call(newVideoObj, "bucketName")) {
          additionsToObj.bucketName = newVideoObj.bucketName;
        }
        if (
          Object.prototype.hasOwnProperty.call(newVideoObj, "videoMetadata")
        ) {
          additionsToObj.videoMetadata = newVideoObj.videoMetadata;
        }

        if (Object.prototype.hasOwnProperty.call(newVideoObj, "tasks")) {
          additionsToObj.tasks = newVideoObj.tasks;
        }
        if (Object.prototype.hasOwnProperty.call(newVideoObj, "metadata")) {
          additionsToObj.metadata = newVideoObj.metadata;
        }
        additionsToObj.videoName = newVideoObj.videoName;
        additionsToObj.thumbnailLoc = newVideoObj.thumbnailLoc;

        const oldVideoObj = state.videoList[videoId];

        videoObj = { ...oldVideoObj, ...additionsToObj };
      }

      if (
        notesHaveChangedHelper(state.videoList[videoId], videoObj) &&
        state.recommendationLoading
      ) {
        recommendationLoading = false;
      }

      // TODO(znoland): complete updates to GET_VIDEO_SUCCESS

      // Loop through all tasks + all assessments + all postures

      //

      /** *
       *  Initialize
       *
       *  - Generate locally stored versions of the assessment objects
       */
      // TODO(znoland): old copy commented out below this if statement. remove when done
      const { tasks } = videoObj;
      if (action.payload.initializeAfter && tasks.length > 0) {
        // loop through tasks
        for (let iTask = 0; iTask < tasks.length; iTask += 1) {
          // loop through assessments
          for (
            let iAssessment = 0;
            iAssessment < tasks[iTask].assessments.length;
            iAssessment += 1
          ) {
            const assessment = tasks[iTask].assessments[iAssessment];

            // Set video thumbnail
            // if (videoObj.thumbnailLoc == '/assets/img/placeholder.jpg' || !videoObj.thumbnailLoc) {
            //   videoObj.thumbnailLoc = assessment['data'].thumbnailLoc
            // }

            // Create the Assessment Level insights (+ Posture Contribution to risk score)
            const postures = assessment.posture_assessments;
            console.log("POSTURES", postures);
            if (assessment.posture_assessments.length > 0) {
              const type = assessment.data.assessmentType;
              const numPostures = postures.length;
              assessment.data.otherDosageStatBucket = {};
              for (
                let i = 0;
                i < assessment.data.assessmentMetadata.numPeople;
                i += 1
              ) {
                assessment.data.otherDosageStatBucket[i] = clone(
                  DOSAGE_STATS_TEMPLATE,
                );
                if (!("vhighContrib" in postures[0].dosageStatistics)) {
                  delete assessment.data.otherDosageStatBucket[i].vhighContrib;
                }
              }
              let maxScore = -Number.MAX_VALUE;

              // Loop through postures
              for (let i = 0; i < numPostures; i += 1) {
                const posture = postures[i];
                if (
                  !Object.prototype.hasOwnProperty.call(
                    posture.riskAssessment,
                    "initialized",
                  )
                ) {
                  const { assessmentType } = assessment.data;
                  let personInspectionData = posture.riskAssessment;
                  let score = 0;
                  if (assessmentType === Assessments.RULA) {
                    posture.riskAssessment = new Rula(personInspectionData);
                    score = posture.riskAssessment.assessmentResult.Score;
                  } else if (assessmentType === Assessments.REBA) {
                    posture.riskAssessment = new Reba(personInspectionData);
                    score = posture.riskAssessment.assessmentResult.Score;
                  } else if (assessmentType === Assessments.NIOSH) {
                    personInspectionData = {
                      ...personInspectionData,
                      ...assessment.data.assessmentMetadata,
                    };
                    score =
                      Math.round(
                        posture.riskAssessment.assessmentResult.li * 100,
                      ) / 100.0;
                    posture.riskAssessment = new Niosh(personInspectionData);
                  } else if (assessmentType === Assessments.HAND_STRAIN) {
                    score =
                      Math.round(
                        posture.riskAssessment.assessmentResult.OverallScore *
                          100,
                      ) / 100.0;
                    posture.riskAssessment = new HandStrain(
                      personInspectionData,
                    );
                  } else if (assessmentType === Assessments.LIBERTY_MUTUAL) {
                    personInspectionData = {
                      ...personInspectionData,
                      ...assessment.data.assessmentMetadata,
                    };
                    score =
                      Math.round(
                        posture.riskAssessment.assessmentResult.mal * 100,
                      ) / 100.0;
                    posture.riskAssessment = new LibertyMutual(
                      personInspectionData,
                    );
                  } else if (assessmentType === Assessments.TRAINING) {
                    score =
                      Math.round(
                        posture.riskAssessment.assessmentResult.OverallScore *
                          100,
                      ) / 100.0;
                    posture.riskAssessment = new Training(personInspectionData);
                  }
                  if (score > maxScore) {
                    maxScore = score;
                  }

                  let repArr = [];
                  repArr = posture.repetitions.map((rep: any, idx: number) => ({
                    candidate: idx === 0,
                    frame: rep,
                  }));
                  repArr.sort((a: any, b: any) => a.frame - b.frame);
                  const { person_id: personId } = posture;
                  if ("lowContrib" in postures[i].dosageStatistics) {
                    assessment.data.otherDosageStatBucket[
                      personId
                    ].lowContrib -= posture.dosageStatistics.lowContrib;
                  }
                  if ("mediumContrib" in postures[i].dosageStatistics) {
                    assessment.data.otherDosageStatBucket[
                      personId
                    ].mediumContrib -= posture.dosageStatistics.mediumContrib;
                  }
                  if ("highContrib" in postures[i].dosageStatistics) {
                    assessment.data.otherDosageStatBucket[
                      personId
                    ].highContrib -= posture.dosageStatistics.highContrib;
                  }
                  if ("vhighContrib" in postures[i].dosageStatistics) {
                    assessment.data.otherDosageStatBucket[
                      personId
                    ].vhighContrib -= posture.dosageStatistics.vhighContrib;
                  }

                  posture.repetitions = repArr;
                }
              }
              assessment.data.score = maxScore;
              assessment.data.numPeople =
                assessment.data.assessmentMetadata.numPeople;
              for (
                let i = 0;
                i < assessment.data.assessmentMetadata.numPeople;
                i += 1
              ) {
                const dosageStatistics =
                  assessment.data.otherDosageStatBucket[i];
                if (
                  "summaryStats" in assessment.data &&
                  assessment.data.summaryStats
                ) {
                  console.log(
                    "assessment['data'].summaryStats",
                    assessment.data.summaryStats,
                  );
                  const summaryStats = assessment.data.summaryStats[i];
                  if ("riskFrequency" in summaryStats) {
                    const { riskFrequency } = summaryStats;
                    let levels: string[] = [];
                    let contrib: string[] = [];

                    if (type === 1) {
                      levels = ["low", "medium", "high"];
                      contrib = ["lowContrib", "mediumContrib", "highContrib"];
                    }
                    if (type === 2) {
                      levels = ["low", "medium", "high", "very high"];
                      contrib = [
                        "lowContrib",
                        "mediumContrib",
                        "highContrib",
                        "vhighContrib",
                      ];
                    }
                    if (type === 3) {
                      levels = [];
                      contrib = [];
                    }
                    let c = 0;
                    let nums: number[] = [];
                    levels.forEach((l) => {
                      nums =
                        type === 1
                          ? Rula.getRiskScoresFromLevel(l)
                          : Reba.getRiskScoresFromLevel(l);
                      const flag = Object.entries(riskFrequency).find(
                        (pair: any[]) =>
                          nums.includes(parseInt(pair[0], 10)) && pair[1] > 0,
                      );
                      const entries: any = Object.entries(riskFrequency);
                      for (let p = 0; p < entries.length; p += 1) {
                        // const contained = nums.includes(entries[p][0]);
                      }

                      if (!flag) {
                        dosageStatistics[contrib[c]] = -1;
                      }
                      c += 1;
                    });
                  }
                }
              }
            }
          }
        }
      }
      return {
        ...state,
        videoList: {
          ...state.videoList,
          [videoId]: videoObj,
        },
        recommendationLoading,
      };
    }
    case CLEAR_VIDEO_LIST: {
      return {
        ...state,
        videoList: {},
      };
    }
    case GET_SINGLE_VIDEO_DOC_ERROR: {
      return {
        ...state,
        videoList: {
          ...state.videoList,
          [action.payload.videoId]: {
            errorMsg: action.payload.msg,
          },
        },
      };
    }
    case DELETE_VIDEOS_SUCCESS: {
      // const oldVideoList = state.videoList;

      return {
        ...state,
        videoList: omit(state.videoList, action.payload.videoIds),
      };
    }
    case ADD_METADATA_OPTION: {
      return { ...state, refresh: !state.refresh };
    }
    case DELETE_METADATA_OPTION: {
      return { ...state };
    }
    case EDIT_METADATA_OPTION: {
      return { ...state, refresh: !state.refresh };
    }
    case SET_SELECTED_POSTURE: {
      return {
        ...state,
        selectedPosture: action.payload.idx,
      };
    }
    case SET_SELECTED_REP: {
      const len =
        state.videoList[action.payload.videoId].postures[state.selectedPosture]
          .repetitions.length;

      return {
        ...state,
        selectedRepInPosture: Math.max(
          Math.min(action.payload.idx, len - 1),
          0,
        ),
      };
    }
    case SET_PERSON_ID: {
      return {
        ...state,
        personId: action.payload.idx,
      };
    }

    case SET_VIDEO_METADATA_SUCCESS: {
      const videoObj = state.videoList[action.payload.videoId];
      if (!videoObj) return state;
      action.payload.metadataObj.sort(
        (a: any, b: any) => a.option_id < b.option_id,
      );
      videoObj.metadata = action.payload.metadataObj;
      return {
        ...state,
        videoList: {
          ...state.videoList,
          [action.payload.videoId]: videoObj,
        },
      };
    }
    case SET_WEBSOCKET_FAILED: {
      return { ...state, websocketFailed: action.payload.websocketFailed };
    }
    case GET_ALL_METADATA_FIELDS: {
      return { ...state };
    }
    case GET_ALL_METADATA_FIELDS_SUCCESS: {
      return {
        ...state,
        optionsMetadataArray: action.payload.data.options,
        selectedMetadataObj: action.payload.data.selected,
      };
    }
    case GET_THUMBNAIL_SUCCESS: {
      if (!(action.payload.videoId in state.videoList)) {
        return state;
      }

      if (
        action.payload.taskId === undefined ||
        action.payload.assessmentId === undefined ||
        action.payload.postureId === undefined
      ) {
        return {
          ...state,
          videoList: {
            ...state.videoList,
            [action.payload.videoId]: {
              ...state.videoList[action.payload.videoId],
              thumbnailLoc: action.payload.realThumbnailLoc,
            },
          },
        };
      }
      // const task = state.videoList[action.payload.videoId].tasks.filter(x => x.id == action.payload.taskId);
      // const assessment = task.assessments.filter(x => x.id == action.payload.assessmentId);
      return {
        ...state,
        videoList: {
          ...state.videoList,
          [action.payload.videoId]: {
            ...state.videoList[action.payload.videoId],
            // Task level
            tasks: state.videoList[action.payload.videoId].tasks.map(
              (task: any) => {
                if (task.id !== action.payload.taskId) {
                  return task;
                }
                // Assessment level
                return {
                  ...task,
                  assessments: task.assessments.map((assessment: any) => {
                    if (assessment.id !== action.payload.assessmentId) {
                      return assessment;
                    }
                    // Posture level
                    return {
                      ...assessment,
                      posture_assessments: assessment.posture_assessments.map(
                        (posture: any) => {
                          if (posture.id !== action.payload.postureId) {
                            return posture;
                          }
                          return {
                            ...posture,
                            thumbnailLoc: action.payload.realThumbnailLoc,
                          };
                        },
                      ),
                    };
                  }),
                };
              },
            ),
            // postures: state.videoList[action.payload.videoId].postures.map((posture) => {
            //   if (posture.id !== action.payload.postureId) {
            //     return posture;
            //   }
            //   return {
            //     ...posture,
            //     thumbnailLoc: action.payload.realThumbnailLoc
            //   }
            // })
          },
        },
      };
    }

    case UPDATE_VIDEO_NAME: {
      console.log("[UPDATE_VIDEO_NAME] ", action.payload);
      return {
        ...state,
        videoList: {
          ...state.videoList,
          [action.payload.videoId]: {
            ...state.videoList[action.payload.videoId],
            videoName: action.payload.name,
          },
        },
      };
    }

    case UPDATE_ASSESSMENT_NAME: {
      console.log("[UPDATE_ASSESSMENT_NAME] ", action.payload);

      return {
        ...state,
        videoList: {
          ...state.videoList,
          [action.payload.videoId]: {
            ...state.videoList[action.payload.videoId],
            // Task level
            tasks: state.videoList[action.payload.videoId].tasks.map(
              (task: any) => ({
                ...task,
                assessments: task.assessments.map((assessment: any) => {
                  if (
                    assessment.id !== parseInt(action.payload.assessmentId, 10)
                  ) {
                    return assessment;
                  }
                  return {
                    ...assessment,
                    data: {
                      ...assessment.data,
                      assessmentName: action.payload.name,
                    },
                  };
                }),
              }),
            ),
          },
        },
      };
    }

    case LOADING_VIDEOS_START: {
      return { ...state, videosLoading: true, videosLoaded: false };
    }
    case FILTER_VIDEOS: {
      const newVideoList1: any = {};
      let videoIdList: string[] = [];
      if (action.payload.videoIds !== null) {
        videoIdList = action.payload.videoIds;
      }
      Object.keys(state.videoList).forEach((key) => {
        newVideoList1[key] = state.videoList[key];
        newVideoList1[key].visible =
          videoIdList.includes(key) ||
          (newVideoList1[key].processingStatus !== ProcessingStatus.COMPLETED &&
            newVideoList1[key].processingStatus !== ProcessingStatus.ERROR);
      });
      return { ...state, videoList: newVideoList1, refresh: !state.refresh };
    }
    case ALL_VIDEOS_LOADED: {
      const stogareAdapter = TumekeJSModule.get(
        EAdapterNames.Storage,
      ) as IStorageAdapter;
      stogareAdapter.setItem(
        "videoLimitCount",
        JSON.stringify(action.payload.videoLimitCount),
      );
      return {
        ...state,
        videosLoading: false,
        videosLoaded: true,
        videoCount: action.payload.videoCount,
        totalVideoDurationMins: action.payload.totalVideoDurationMins,
        videoLimitCount: action.payload.videoLimitCount,
      };
    }

    case MODIFY_VIDEO_DOC: {
      // This means the object has already been initialized by INITIALIZE_INSPECTION_DATA
      // So it no longer needs to be updated locally
      if (
        Object.prototype.hasOwnProperty.call(
          state.videoList[action.payload.newData.key],
          "riskAssessment",
        ) &&
        Object.prototype.hasOwnProperty.call(
          state.videoList[action.payload.newData.key].riskAssessment["0"],
          "initialized",
        )
      ) {
        return state;
      }

      const { key } = action.payload.newData;
      const newObj = {
        ...action.payload.newData,
        thumbnailLoc: state.videoList[key].thumbnailLoc,
        visible: state.videoList[key].visible,
      };

      return { ...state, videoList: { ...state.videoList, [key]: newObj } };
    }
    case SAVE_VIDEO_NOTES_SUCCESS: {
      const videoObj = state.videoList[action.payload.videoId];
      if (!videoObj) return state;
      const assessmentObj = getAssessmentFromVideo(
        state.videoList,
        action.payload.videoId,
        action.payload.assessmentId,
      );
      if (assessmentObj) {
        assessmentObj.data[action.payload.notesKey] = action.payload.notes;
      }
      return {
        ...state,
        videoList: {
          ...state.videoList,
          [action.payload.videoId]: {
            ...videoObj,
            [action.payload.notesKey]: action.payload.notes,
          },
        },
      };
    }
    case GET_JOINT_DATA_SUCCESS: {
      const videoObj = state.videoList[action.payload.videoId];
      return {
        ...state,
        videoList: {
          ...state.videoList,
          [action.payload.videoId]: {
            ...videoObj,
            videoJsons: action.payload.data,
            chunk: action.payload.data,
          },
        },
        refresh: !state.refresh,
      };
    }
    case UPDATE_ADDITIONAL_INFO: {
      const assessmentObj = getAssessmentFromVideo(
        state.videoList,
        action.payload.videoId,
        action.payload.assessmentId,
      );
      if (assessmentObj) {
        // const assessmentObj = state.videoList[action.payload.videoId]['tasks'][0]['assessments'].filter(x => x.id == action.payload.assessmentId)[0];
        assessmentObj.posture_assessments[
          state.selectedPosture
        ].riskAssessment.updateAdditionalInfo(
          action.payload.newInfo.type,
          action.payload.newInfo.bodyGroup,
          action.payload.newInfo.newValue,
          action.payload.newInfo.units,
        );
      }
      return { ...state, refresh: !state.refresh };
    }
    case DELETE_VIDEO_ASSESSMENT: {
      return {
        ...state,
        videoList: {
          ...state.videoList,
          [action.payload.videoId]: {
            ...state.videoList[action.payload.videoId],
            tasks: state.videoList[action.payload.videoId].tasks.map(
              (task: any) => ({
                ...task,
                assessments: task.assessments.filter(
                  (assessment: any) =>
                    assessment.id !== parseInt(action.payload.assessmentId, 10),
                ),
              }),
            ),
          },
        },
      };
    }
    case DELETE_VIDEO_CLIP: {
      return {
        ...state,
        videoList: {
          ...state.videoList,
          [action.payload.videoId]: {
            ...state.videoList[action.payload.videoId],
            tasks: state.videoList[action.payload.videoId].tasks.filter(
              (task: any) =>
                task.clip.id !== parseInt(action.payload.clipId, 10),
            ),
          },
        },
      };
    }
    case SET_PRIMARY_VIDEO_ASSESSMENT: {
      return {
        ...state,
        videoList: {
          ...state.videoList,
          [action.payload.videoId]: {
            ...state.videoList[action.payload.videoId],
            tasks: state.videoList[action.payload.videoId].tasks.map(
              (task: any) => ({
                ...task,
                assessments: task.assessments.map((assessment: any) => {
                  if (
                    assessment.id === parseInt(action.payload.assessmentId, 10)
                  ) {
                    return {
                      ...assessment,
                      is_primary: true,
                    };
                  }
                  return {
                    ...assessment,
                    is_primary: false,
                  };
                }),
              }),
            ),
          },
        },
      };
    }
    case UPDATE_ASSESSMENT_METADATA: {
      return {
        ...state,
        videoList: {
          ...state.videoList,
          [action.payload.videoId]: {
            ...state.videoList[action.payload.videoId],
            // Task level
            tasks: state.videoList[action.payload.videoId].tasks.map(
              (task: any) => ({
                ...task,
                assessments: task.assessments.map((assessment: any) => {
                  if (
                    assessment.id !== parseInt(action.payload.assessmentId, 10)
                  ) {
                    return assessment;
                  }
                  return {
                    ...assessment,
                    data: {
                      ...assessment.data,
                      assessmentMetadata: action.payload.newInfo,
                    },
                  };
                }),
              }),
            ),
          },
        },
      };
    }
    case UPDATE_ASSESSMENT_ROI_RESULTS: {
      return {
        ...state,
        videoList: {
          ...state.videoList,
          [action.payload.videoId]: {
            ...state.videoList[action.payload.videoId],
            // Task level
            tasks: state.videoList[action.payload.videoId].tasks.map(
              (task: any) => ({
                ...task,
                assessments: task.assessments.map((assessment: any) => {
                  if (
                    assessment.id !== parseInt(action.payload.assessmentId, 10)
                  ) {
                    return assessment;
                  }
                  return {
                    ...assessment,
                    roi_results: action.payload.results,
                  };
                }),
              }),
            ),
          },
        },
      };
    }
    case GENERATE_REPORT_REQUEST: {
      if (action.payload.reportType === "worksheet") {
        return { ...state, worksheetLoading: true };
      }
      return { ...state, reportLoading: true };
    }
    case GENERATE_REPORT_SUCCESS: {
      return { ...state, reportLoading: false, worksheetLoading: false };
    }
    case GENERATE_REPORT_ERROR: {
      return { ...state, reportLoading: false, worksheetLoading: false };
    }
    case GENERATE_CSV_REQUEST: {
      return { ...state, csvLoading: true };
    }
    case GENERATE_CSV_SUCCESS: {
      return { ...state, csvLoading: false };
    }
    case GENERATE_CSV_ERROR: {
      return { ...state, csvLoading: false };
    }
    case DOWNLOAD_VIDEO_REQUEST: {
      return { ...state, videoDownloading: true };
    }
    case DOWNLOAD_VIDEO_SUCCESS: {
      return { ...state, videoDownloading: false };
    }
    case DOWNLOAD_VIDEO_ERROR: {
      return { ...state, videoDownloading: false };
    }
    case DOWNLOAD_RISK_JOINTS_CSV_ERROR: {
      return { ...state, riskJointsCsvDownloading: false };
    }
    case DOWNLOAD_RISK_JOINTS_CSV_SUCCESS: {
      return { ...state, riskJointsCsvDownloading: false };
    }
    case DOWNLOAD_RISK_JOINTS_CSV_REQUEST: {
      return { ...state, riskJointsCsvDownloading: true };
    }
    case UPDATE_RISK_COMPONENTS: {
      const assessmentObj = getAssessmentFromVideo(
        state.videoList,
        action.payload.videoId,
        action.payload.assessmentId,
      );
      assessmentObj.posture_assessments[
        state.selectedPosture
      ].riskAssessment.updateRiskComponents(
        action.payload.newInfo.bodyGroup,
        action.payload.newInfo.type,
        action.payload.newInfo.newValue,
        action.payload.newInfo.units,
      );
      return { ...state, refresh: !state.refresh };
    }
    case SUBMIT_VIDEO_FEEDBACK: {
      return { ...state };
    }
    case COMPARE_VIDEOS_REQUEST: {
      return { ...state, compareLoading: true };
    }
    case COMPARE_VIDEOS_ERROR: {
      return { ...state, compareLoading: false };
    }
    case COMPARE_VIDEOS_SUCCESS: {
      console.log("COMPARE_VIDEOS_SUCCESS-->", action.payload);
      return {
        ...state,
        compareLoading: false,
        compareVideoIds: action.payload.videoIds,
        assessmentIds: action.payload.assessmentIds,
        compareVideoData: action.payload.data,
      };
    }
    case SET_PAGE_METADATA: {
      const newPayload = { ...action.payload };
      Object.keys(newPayload).forEach((key) => {
        if (newPayload[key] === undefined) {
          delete newPayload[key];
        }
      });

      return {
        ...state,
        pageMetadata: {
          ...state.pageMetadata,
          ...newPayload,
        },
      };
    }
    case SET_ALL_INVISIBLE: {
      const newVideoList1: any = {};
      Object.keys(state.videoList).forEach((key) => {
        newVideoList1[key] = state.videoList[key];
        newVideoList1[key].visible = false;
      });
      return {
        ...state,
        videoList: newVideoList1,
      };
    }
    case SET_VIDEOS_VISIBILITY: {
      const newVideoList1: any = {};
      Object.keys(state.videoList).forEach((key) => {
        newVideoList1[key] = state.videoList[key];
        if (action.payload.videoIds.includes(key)) {
          newVideoList1[key].visible = action.payload.visible;
        }
      });
      return {
        ...state,
        videoList: newVideoList1,
      };
    }
    case SET_RECOMMENDATION_GENERATION_LOADING: {
      return {
        ...state,
        recommendationLoading: action.payload.recommendationLoading,
      };
    }
    case TOGGLE_ASSESSMENTS_LIMIT_MODAL: {
      return {
        ...state,
        assessmentsLimitModal: !state.assessmentsLimitModal,
      };
    }
    case ADD_VIDEO_JOINT_URLS: {
      const { videoId } = action.payload;
      const { jointUrls } = action.payload;
      return {
        ...state,
        videoList: {
          ...state.videoList,
          [videoId]: {
            ...state.videoList[videoId],
            jointUrls,
          },
        },
      };
    }
    case GET_LINKS: {
      return {
        ...state,
        linksLoading: true,
      };
    }
    case GET_LINKS_SUCCESS: {
      return {
        ...state,
        linksList: action.payload.linksList,
        linksLoading: false,
      };
    }
    case UPDATE_LINK: {
      return {
        ...state,
        linksLoading: true,
      };
    }
    case DELETE_LINK: {
      return {
        ...state,
        linksLoading: true,
      };
    }
    default:
      return { ...state };
  }
};
