import { AnyAction } from "@reduxjs/toolkit";
import {
  ADD_NOTIFICATION_RECORD,
  ADD_NOTIFICATION_RECORD_SUCCESS,
  DELETE_NOTIFICATION_RECORD,
  START_NOTIFICATION_LISTENER,
  SET_LISTENER_ACTIVE,
  REMOVE_NOTIFICATION_LISTENER,
} from "@kernel-store/actions";

export const addNotificationRecord = (
  notification: any,
  key: string,
): AnyAction => ({
  type: ADD_NOTIFICATION_RECORD,
  payload: { notification, key },
});

export const setListenerActive = (): AnyAction => ({
  type: SET_LISTENER_ACTIVE,
  payload: {},
});

export const addNotificationRecordSuccess = (userId: string): AnyAction => ({
  type: ADD_NOTIFICATION_RECORD_SUCCESS,
  payload: { userId },
});

export const deleteNotificationRecord = (
  notificationId: string,
): AnyAction => ({
  type: DELETE_NOTIFICATION_RECORD,
  payload: { notificationId },
});

export const startNotificationListener = (): AnyAction => ({
  type: START_NOTIFICATION_LISTENER,
  payload: {},
});

export const removeNotificationListener = (): AnyAction => ({
  type: REMOVE_NOTIFICATION_LISTENER,
  payload: {},
});
