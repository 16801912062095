export const filterVideosByJobId = (
  videoList: any[],
  jobFieldId: string,
  jobOptionId: number,
): any[] => {
  const jobVideos: any[] = [];
  Object.keys(videoList).forEach((videoKey: any) => {
    let videoAssignedThisJob = null;
    if (jobOptionId === 0) {
      // If Job unassigned(no metadata set for job field), then return when jobOptionId == 0
      videoAssignedThisJob =
        videoList[videoKey].metadata.filter(
          (x: any) => x.field_id === jobFieldId,
        ).length !== 1;
    } else {
      videoAssignedThisJob =
        videoList[videoKey].metadata.filter(
          (x: any) => x.option_id === jobOptionId,
        ).length === 1;
    }

    if (videoAssignedThisJob) {
      jobVideos.push(videoList[videoKey]);
    }
  });

  return jobVideos;
};

export const getAssessmentFromVideo = (
  videoList: any,
  videoKey: string,
  assessmentId: string,
): any | undefined => {
  if (!videoList) return undefined;
  if (!(videoKey in videoList)) return undefined;
  const videoObj = videoList[videoKey];
  if (!("tasks" in videoObj)) return undefined;

  let assessment;
  videoObj.tasks.some((task: any) =>
    task.assessments.some((a: any) => {
      if (a?.id === parseInt(assessmentId, 10)) {
        assessment = a;
        return true;
      }
      return false;
    }),
  );

  return assessment;
};

export const getNumPeople = (
  videoList: any,
  videoKey: string,
  assessmentId: string,
  clipId: number,
): number | null => {
  let task: any = {};
  if (clipId) {
    [task] = videoList[videoKey].tasks.filter((x: any) => x.clip.id === clipId);
  } else if (assessmentId) {
    const assessmentObj = getAssessmentFromVideo(
      videoList,
      videoKey,
      assessmentId,
    );
    [task] = videoList[videoKey].tasks.filter(
      (x: any) => x.clip.id === assessmentObj?.clip_id,
    );
  } else {
    [task] = videoList[videoKey].tasks;
  }
  if (task && task.assessments && task.assessments[0]) {
    let numPeople = 1;
    if (task.assessments[0].data.numPeople) {
      numPeople = task.assessments[0].data.numPeople;
    } else if (
      task.assessments[0].data.assessmentMetadata &&
      task.assessments[0].data.assessmentMetadata.numPeople
    ) {
      numPeople = task.assessments[0].data.assessmentMetadata.numPeople;
    }
    return numPeople;
  }
  return null;
};
