import config from "../configs/niosh.json";

import { IRiskScoreInfo } from "./interfaces";
import { strToArr, interp } from "./NioshUtils";

const { constants, Units, CouplingStatus, DurationStatus } = config;

const {
  FM_FREQUENCIES,
  FM_SHORT_DURATION,
  FM_MODERATE_DURATION,
  FM_LONG_DURATION,
  FM_SHORT_DURATION_VERTICAL_CUTOFF,
  FM_MODERATE_DURATION_VERTICAL_CUTOFF,
  FM_LONG_DURATION_VERTICAL_CUTOFF,
} = constants;

export interface IAdditionalInput {
  subjectHeight: number;
  averageLoad: number;
  coupling: number;
  shortRest: number;
  liftingDuration: number;
  frequency: number;
  [x: string]: any;
}

export interface INioshMetadata {
  numLifts: number;
  [x: string]: any;
}

export class Niosh {
  initialized: boolean;

  assessmentResult: any;

  riskComponents: any;

  additionalInputs: IAdditionalInput;

  nioshMetadata: INioshMetadata;

  constructor(oldObject: Niosh | null) {
    this.initialized = true;
    if (!oldObject) {
      this.assessmentResult = {};
      this.riskComponents = {};
      this.additionalInputs = Niosh.getAdditionalInputs();
      this.nioshMetadata = { numLifts: 0 };
      return;
    }
    this.assessmentResult = oldObject.assessmentResult;
    this.additionalInputs = oldObject.additionalInputs;
    this.riskComponents = oldObject.riskComponents;
    this.nioshMetadata = oldObject.nioshMetadata;

    this.computeAssessment();
  }

  static getAdditionalInputs = (): IAdditionalInput => {
    const inputs: IAdditionalInput = {
      subjectHeight: 6.0,
      averageLoad: -1,
      coupling: CouplingStatus.GOOD.Number, // NIOSH coupling score
      shortRest: -1.0, // rest in between lifts, in seconds. If rest < 0 then
      // we attempt to estimate it from the video
      liftingDuration: DurationStatus.MODERATE.Number, // Length of a single lifting session, bucketed
      frequency: 4,
    };
    return inputs;
  };

  getAdditionalInput = (
    info: string,
    units: number = Units.STANDARD,
  ): number | undefined => {
    if (info === "subjectHeight") {
      return this.lengthConvert(this.additionalInputs.subjectHeight, units);
    }
    if (info === "averageLoad") {
      return this.weightConvert(this.additionalInputs.averageLoad, units);
    }
    return this.additionalInputs[info];
  };

  getAssessmentResult = (
    info: string,
    units: number = Units.STANDARD,
  ): number | undefined => {
    if (info === "rwl") {
      if (units === Units.METRIC) {
        return Number(this.assessmentResult[info]) * 0.453592;
      }
    }

    return this.assessmentResult[info];
  };

  getRiskComponent = (liftNum: string | number, info: string): number => {
    if (info === "asymmetryAngle") {
      return parseFloat(
        Number(this.riskComponents[liftNum].asymmetryAngle).toFixed(2),
      );
    }
    return parseFloat(
      Number(this.lengthConvert(this.riskComponents[liftNum][info])).toFixed(2),
    );
  };

  lengthConvert = (
    val: number,
    units: number = Units.STANDARD,
  ): number | undefined => {
    if (units === Units.STANDARD) {
      return val;
    }

    if (units === Units.METRIC) {
      return 0.3048 * val;
    }
    return undefined;
  };

  weightConvert = (
    val: number,
    units: number = Units.STANDARD,
  ): number | undefined => {
    if (units === Units.STANDARD) {
      return val;
    }

    if (units === Units.METRIC) {
      return 0.453592 * val;
    }

    return undefined;
  };

  updateAdditionalInfo = (
    typeOfInput: string,
    bodyGroup: any,
    newValue: number,
    units: number = Units.STANDARD,
  ): void => {
    if (typeOfInput === "startEnd") {
      this.nioshMetadata.startEnd = newValue;
      return;
    }
    if (units === Units.METRIC) {
      if (typeOfInput === "averageLoad") {
        this.additionalInputs.averageLoad = (1 / 0.453592) * newValue;
      } else {
        this.additionalInputs[typeOfInput] = newValue;
      }
    } else {
      this.additionalInputs[typeOfInput] = newValue;
    }
    this.computeAssessment();
  };

  updateRiskComponents = (
    typeOfInput: string | number,
    bodyGroup: string,
    newValue: number,
    units: number = Units.STANDARD,
  ): void => {
    if (units === Units.METRIC) {
      if (bodyGroup === "asymmetryAngle") {
        this.riskComponents[typeOfInput].asymmetryAngle = newValue;
      } else {
        this.riskComponents[typeOfInput][bodyGroup] = (1 / 0.3048) * newValue;
      }
    } else {
      this.riskComponents[typeOfInput][bodyGroup] = newValue;
    }
    this.computeAssessment();
  };

  static interpolateFreqTable = (
    frequency: number,
    table: any[],
    cutoff: number,
  ): number => {
    if (frequency < 0) {
      throw new Error("Frequency cannot be negative");
    }
    if (frequency >= cutoff) {
      return 0.0;
    }
    for (let i = 1; i < FM_FREQUENCIES.length; i++) {
      if (i === 0) {
        if (frequency <= FM_FREQUENCIES[i]) {
          return table[i - 1];
        }
      } else if (i === FM_FREQUENCIES.length - 1) {
        if (frequency >= FM_FREQUENCIES[i]) {
          return 0.0;
        }
      } else if (
        frequency >= FM_FREQUENCIES[i - 1] &&
        frequency <= FM_FREQUENCIES[i]
      ) {
        // TODO: Carry over interp
        return interp(
          frequency,
          FM_FREQUENCIES[i - 1],
          FM_FREQUENCIES[i],
          table[i - 1],
          table[i],
        );
      }
    }
    return 0.0;
  };

  getFrequency = (): number => {
    const liftDuration = this.computeLiftDurationHelper();
    if (typeof this.additionalInputs.frequency !== "undefined") {
      return this.additionalInputs.frequency;
    }
    if (this.additionalInputs.shortRest < 0) {
      return 4;
    }
    return (
      Math.round(
        (1.0 / ((liftDuration + this.additionalInputs.shortRest) / 60)) * 10000,
      ) / 10000
    );
  };

  computeFreqHelper = (verticalStart: number): number => {
    const frequency = this.getFrequency();
    if (this.additionalInputs.liftingDuration === DurationStatus.SHORT.Number) {
      const cutoff =
        verticalStart < 30 ? FM_SHORT_DURATION_VERTICAL_CUTOFF : 15;
      const fm = Niosh.interpolateFreqTable(
        frequency,
        FM_SHORT_DURATION,
        cutoff,
      );
      return fm;
    }

    if (
      this.additionalInputs.liftingDuration === DurationStatus.MODERATE.Number
    ) {
      const cutoff =
        verticalStart < 30 ? FM_MODERATE_DURATION_VERTICAL_CUTOFF : 15;
      const fm = Niosh.interpolateFreqTable(
        frequency,
        FM_MODERATE_DURATION,
        cutoff,
      );
      return fm;
    }

    if (this.additionalInputs.liftingDuration === DurationStatus.LONG.Number) {
      const cutoff = verticalStart < 30 ? FM_LONG_DURATION_VERTICAL_CUTOFF : 15;
      const fm = Niosh.interpolateFreqTable(
        frequency,
        FM_LONG_DURATION,
        cutoff,
      );
      return fm;
    }

    throw new Error(
      `Frequency cannot be calculated because missed config DurationStatus === ${this.additionalInputs.liftingDuration}`,
    );
  };

  computeLiftDurationHelper = (): number => {
    let duration = 0;
    for (let i = 0; i < this.nioshMetadata.numLifts; i++) {
      duration +=
        this.nioshMetadata.startEnd[String(i)][1] -
        this.nioshMetadata.startEnd[String(i)][0];
    }
    return duration / this.nioshMetadata.numLifts;
  };

  computeLiftRestHelper = (): number => {
    if (this.nioshMetadata.numLifts === 1) {
      return 0;
    }

    let rest = 0;
    for (let i = 1; i < this.nioshMetadata.numLifts; i++) {
      const time =
        this.nioshMetadata.startEnd[String(i)][0] -
        this.nioshMetadata.startEnd[String(i - 1)][1];
      if (time < 0) {
        throw new Error("Lift i starts before lift i-1");
      }

      rest += time;
    }
    return rest / (this.nioshMetadata.numLifts - 1);
  };

  computeCouplingHelper = (v: number): number => {
    if (v < 30) {
      if (this.additionalInputs.coupling === CouplingStatus.GOOD.Number) {
        return 1.0;
      }
      if (this.additionalInputs.coupling === CouplingStatus.FAIR.Number) {
        return 0.95;
      }
      if (this.additionalInputs.coupling === CouplingStatus.POOR.Number) {
        return 0.9;
      }
    } else {
      if (this.additionalInputs.coupling === CouplingStatus.GOOD.Number) {
        return 1.0;
      }
      if (this.additionalInputs.coupling === CouplingStatus.FAIR.Number) {
        return 1.0;
      }
      if (this.additionalInputs.coupling === CouplingStatus.POOR.Number) {
        return 0.9;
      }
    }
    throw new Error(
      `Coupling cannot be calculated because missed config CouplingStatus === ${this.additionalInputs.coupling}`,
    );
  };

  static getRiskScoreInfo = (
    component: string,
    score: number,
  ): IRiskScoreInfo => {
    const range = Niosh.computeRange(config.ComponentValues, component, score);

    const ranges = (config as any).ComponentValues
      ? (config as any).ComponentValues[component]
      : undefined;
    if (!ranges || !ranges[range]) {
      throw new Error(
        `Niosh.ComponentValues[${component}][${range}] doesn't exist in config`,
      );
    }

    return {
      Score: score,
      Color: ranges[range].Color,
      Text: ranges[range].Text,
      ShortText: ranges[range].ShortText,
    };
  };

  static computeRange = (
    map: any,
    component: string | number,
    score: number,
  ): string => {
    let key: string | undefined;
    Object.keys(map[component]).some((rangeKey: any) => {
      const array = strToArr(rangeKey);
      if (Number.isNaN(array[0]) && score <= array[1]) {
        key = rangeKey;
        return true;
      }
      if (Number.isNaN(array[1]) && score >= array[0]) {
        key = rangeKey;
        return true;
      }
      if (score >= array[0] && score < array[1]) {
        key = rangeKey;
        return true;
      }
      return false;
    });
    if (key === undefined) {
      throw new Error(`No range found: ${component}`);
    }
    return key;
  };

  computeAssessment = (): void => {
    const lc = 51;
    let rwl = 0;
    let h = 0;
    let v = 0;
    let d = 0;
    let a = 0;
    for (let i = 0; i < this.nioshMetadata.numLifts; i++) {
      const id = String(i);

      h += Math.max(this.riskComponents[id].horizontalStart * 12.0, 10.0);
      v += this.riskComponents[id].verticalStart * 12.0;
      const dTemp =
        Math.abs(
          this.riskComponents[id].verticalStart -
            this.riskComponents[id].verticalEnd,
        ) * 12;
      d += Math.min(Math.max(10, dTemp), 70);
      a += this.riskComponents[id].asymmetryAngle;
      if (a > 135) {
        // TODO handle this
      }
    }

    a /= this.nioshMetadata.numLifts;
    v /= this.nioshMetadata.numLifts;
    h /= this.nioshMetadata.numLifts;
    d /= this.nioshMetadata.numLifts;

    const vm = Math.min(Math.max(1 - 0.0075 * Math.abs(v - 30), 0.0), 1.0);
    const hm = h > 25 ? 0.0 : Math.min(Math.max(10.0 / h, 0.0), 1.0);
    const dm = Math.min(Math.max(0.82 + 1.8 / d, 0.0), 1.0);
    const am = Math.min(Math.max(1 - 0.0032 * a, 0.0), 1.0);

    if (
      typeof this.additionalInputs.frequency === "undefined" &&
      this.additionalInputs.shortRest < 0 &&
      this.nioshMetadata.numLifts > 1
    ) {
      this.additionalInputs.shortRest = this.computeLiftRestHelper();
    }

    const fm = this.computeFreqHelper(v);
    const cm = this.computeCouplingHelper(v);
    rwl = lc * hm * vm * dm * am * fm * cm;
    this.assessmentResult.rwl = rwl;
    if (rwl === 0 || this.additionalInputs.averageLoad < 0) {
      this.assessmentResult.li = Niosh.getRiskScoreInfo("li", -1);
    } else {
      this.assessmentResult.li = Niosh.getRiskScoreInfo(
        "li",
        this.additionalInputs.averageLoad / rwl,
      );
    }

    this.assessmentResult.fm = Niosh.getRiskScoreInfo("fm", fm);
    this.assessmentResult.am = Niosh.getRiskScoreInfo("am", am);
    this.assessmentResult.dm = Niosh.getRiskScoreInfo("dm", dm);
    this.assessmentResult.hm = Niosh.getRiskScoreInfo("hm", hm);
    this.assessmentResult.vm = Niosh.getRiskScoreInfo("vm", vm);
  };
}
