import { AnyAction } from "@reduxjs/toolkit";
import { TumekeJSModule, EAdapterNames } from "@kernel";
import { IStorageAdapter } from "@kernel-adapters/storage";
import { NotificationManager } from "@kernel-helpers/react-notifications";
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_STATUS,
  REGISTER_USER_ERROR,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  SET_DECYPTED_AES_KEYS_REQUEST,
  SET_DECYPTED_AES_KEYS_SUCCESS,
  ASK_FOR_PASSWORD_SHOW_MODAL,
  CANCEL_ASK_PASSWORD,
  ENTER_PASSWORD_ERROR,
  VIRGIL_INIT_ERROR,
  SET_COMPANY_METADATA_SUCCESS,
  SET_DECRYPTING_KEYS,
  FORGOT_PASSWORD_CONFIRM,
  FORGOT_PASSWORD_CONFIRM_SUCCESS,
  SET_MULTIFACTOR_STATUS,
  ENTER_MULTIFACTOR_CODE,
  SET_USER_ROLE_SUCCESS,
  INCREASE_COMPANY_USER_COUNT,
  DECREASE_COMPANY_USER_COUNT,
  UPDATE_COMPANY_SETTINGS,
  UPDATE_COMPANY_SETTINGS_SUCCESS,
  UPDATE_COMPANY_BASELINE_TASK_SUCCESS,
  DELETE_COMPANY_BASELINE_TASK_SUCCESS,
} from "@kernel-store/actions";

export enum ERegisterStatus {
  Default = "",
  Init = "init",
  CheckCompany = "checkcompany",
  CongitoRegister = "cognitoregister",
  CongitoLogin = "cognitologin",
  VirginInit = "virgilinit",
  VirgilCreateUser = "evirgilcreateuser",
  CreateUser = "createuser",
  CreateCompany = "createcompany",
  JoinCompany = "joincompany",
  AddingToGroup = "addingtogroup",
  Success = "success",
  Error = "error",
}

export type AuthReduxState = {
  user: any;
  company: any;
  multifactorStatus: string;
  multifactorDestination: string;
  forgotPasswordStatus: string;
  fpConfirmSuccess: boolean;
  fpRequestSuccess: boolean;
  decryptingKeys: boolean;
  resetPasswordCode: string;
  loading: boolean;
  error: string;
  userRole: string;
  passwordModalVisible: boolean;
  passwordModalError: string;
  refresh: boolean;
  resetPasswordSuccess: boolean;
  treeFieldNamePlural: string | null;
  treeFieldName: string | null;
  registerStatus: ERegisterStatus;
  companySettingsLoading: boolean;
};

export const INIT_STATE: AuthReduxState = {
  user: null,
  company: {},
  multifactorStatus: "OFF",
  multifactorDestination: "",
  forgotPasswordStatus: "INITIAL",
  fpConfirmSuccess: false,
  fpRequestSuccess: false,
  decryptingKeys: false,
  resetPasswordCode: "",
  loading: false,
  error: "",
  userRole: "",
  passwordModalVisible: false,
  passwordModalError: "",
  refresh: false, // used to refresh the "Verfiying identity.." text on submit page
  resetPasswordSuccess: false,
  treeFieldNamePlural: null,
  treeFieldName: null,
  registerStatus: ERegisterStatus.Default,
  companySettingsLoading: false,
};

const getUnvisited = (metadata: any[]): any[] => {
  const arr = metadata.filter((m) => !m.visited);
  return arr;
};

const visit = (node: any, newMetadata: any[], metadata: any[]): void => {
  if (node.visited) {
    return;
  }
  const newNode = node;
  newNode.temp = true;
  if (node.parent_field_id != null) {
    const parentNode = metadata.filter((m) => m.id === node.parent_field_id)[0];
    visit(parentNode, newMetadata, metadata);
  }
  newNode.temp = false;
  newNode.visited = true;
  newMetadata.push(node);
};

const sortMetadataArray = (metadata: any[]): any[] => {
  const newMetadata = [];
  newMetadata.push(
    metadata.filter(
      (m) => m.name === "Assessment Version" || m.name === "Attempt Number",
    )[0],
  );
  newMetadata[0].visited = true;
  let unvisited = getUnvisited(metadata);
  while (unvisited.length > 0) {
    const node = unvisited[0];
    visit(node, newMetadata, metadata);
    unvisited = getUnvisited(metadata);
  }
  return newMetadata;
};

export default (
  state: AuthReduxState = INIT_STATE,
  action: AnyAction = { type: undefined },
): AuthReduxState => {
  switch (action.type) {
    case LOGIN_USER:
      console.log("in log in reducer");
      return { ...state, loading: true, error: "" };
    case ENTER_MULTIFACTOR_CODE:
      return { ...state, loading: true, error: "" };
    case SET_USER_ROLE_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
      };
    case LOGIN_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        user: action.payload.user,
        company: action.payload.company,
        error: "",
      };
    }
    case SET_MULTIFACTOR_STATUS: {
      return {
        ...state,
        multifactorStatus: action.payload.status,
        multifactorDestination: action.payload.destination,
        loading: action.payload.status === "SENT_CODE" ? false : state.loading,
      };
    }
    case LOGIN_USER_ERROR: {
      const stogareAdapter = TumekeJSModule.get(
        EAdapterNames.Storage,
      ) as IStorageAdapter;
      stogareAdapter.removeItem("email");
      stogareAdapter.removeItem("password");
      NotificationManager.warning(
        action.payload.message,
        "Login error",
        5000,
        null,
        null,
        "",
      );

      return { ...state, loading: false, error: action.payload.message };
    }
    case FORGOT_PASSWORD: {
      return { ...state, loading: true, fpRequestSuccess: false, error: "" };
    }
    case FORGOT_PASSWORD_SUCCESS: {
      console.log("RESET PAS", action.payload.forgotPasswordStatus);
      return {
        ...state,
        loading: false,
        forgotPasswordStatus: action.payload.forgotPasswordStatus,
        error: "",
      };
    }
    case FORGOT_PASSWORD_ERROR: {
      return {
        ...state,
        loading: false,
        fpRequestSuccess: false,
        fpConfirmSuccess: false,
        error: action.payload.message,
      };
    }
    case FORGOT_PASSWORD_CONFIRM: {
      return { ...state, loading: true, fpConfirmSuccess: false, error: "" };
    }
    case FORGOT_PASSWORD_CONFIRM_SUCCESS: {
      return { ...state, loading: false, fpConfirmSuccess: true, error: "" };
    }

    case RESET_PASSWORD: {
      return { ...state, loading: true, error: "" };
    }
    case RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
        resetPasswordSuccess: true,
        error: "",
      };
    }
    case RESET_PASSWORD_ERROR: {
      return { ...state, loading: false, error: action.payload.message };
    }
    case REGISTER_USER: {
      return {
        ...state,
        loading: true,
        error: "",
        registerStatus: ERegisterStatus.Init,
      };
    }
    case REGISTER_USER_SUCCESS: {
      const result = {
        ...state,
        loading: false,
        error: "",
        registerStatus: ERegisterStatus.Success,
      };
      if (action.payload.user) {
        result.user = action.payload.user;
      }
      let companyObj: any = null;
      if (action.payload.company) {
        let { metadata } = action.payload.company;
        metadata = metadata.map((field: any) => {
          field.options.sort((a: any, b: any) => a.id - b.id);
          return field;
        });
        companyObj = {
          ...action.payload.company,
          ...metadata,
        };
        result.company = companyObj;
      }

      return result;
    }
    case REGISTER_USER_ERROR: {
      return {
        ...state,
        loading: false,
        // user: "",
        error: action.payload.message,
        registerStatus: ERegisterStatus.Error,
      };
    }
    case REGISTER_USER_STATUS: {
      const { status } = action.payload;
      return {
        ...state,
        registerStatus: status,
      };
    }
    case VIRGIL_INIT_ERROR: {
      return {
        ...state,
        user: {
          ...state.user,
          virgilError: true,
        },
        refresh: !state.refresh,
      };
    }
    case ENTER_PASSWORD_ERROR: {
      return { ...state, passwordModalError: action.payload.errorMsg };
    }
    case SET_DECRYPTING_KEYS: {
      return { ...state, decryptingKeys: action.payload.decryptingKeys };
    }
    case ASK_FOR_PASSWORD_SHOW_MODAL: {
      return { ...state, passwordModalVisible: true };
    }
    case CANCEL_ASK_PASSWORD: {
      return { ...state, passwordModalVisible: false };
    }
    case SET_DECYPTED_AES_KEYS_REQUEST: {
      return { ...state };
    }
    case SET_COMPANY_METADATA_SUCCESS: {
      const metadataSorted = sortMetadataArray(action.payload.metadataObj);
      metadataSorted.forEach((field) => {
        field.options.sort((a: any, b: any) => {
          if (field.name === "Assessment Version") {
            return a.id - b.id;
          }
          if (a.name > b.name) {
            return 1;
          }
          if (b.name > a.name) {
            return -1;
          }
          return 0;
        });
      });
      console.log("[FIELD] Updating");
      return {
        ...state,
        company: {
          ...state.company,
          metadata: metadataSorted,
        },
      };
    }
    case INCREASE_COMPANY_USER_COUNT: {
      return {
        ...state,
        company: {
          ...state.company,
          user_count: state.company.user_count + action.payload.count,
        },
      };
    }
    case DECREASE_COMPANY_USER_COUNT: {
      return {
        ...state,
        company: {
          ...state.company,
          user_count:
            state.company.user_count > 0
              ? state.company.user_count - action.payload.count
              : 0,
        },
      };
    }
    case SET_DECYPTED_AES_KEYS_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          decryptedAESKeys: action.payload.aesKey,
        },
      };
    }
    case UPDATE_COMPANY_SETTINGS: {
      return {
        ...state,
        companySettingsLoading: true,
      };
    }
    case UPDATE_COMPANY_SETTINGS_SUCCESS: {
      if (action.payload.companyData) {
        return {
          ...state,
          company: {
            ...state.company,
            ...action.payload.companyData,
          },
          companySettingsLoading: false,
        };
      }
      return {
        ...state,
        companySettingsLoading: false,
      };
    }
    case UPDATE_COMPANY_BASELINE_TASK_SUCCESS: {
      let isBaselineTask = false;
      const baselineTasks = (state.company.baseline_tasks || []).map(
        (baselineTask: any) => {
          if (baselineTask.id === action.payload.id) {
            isBaselineTask = true;
            return action.payload;
          }
          return baselineTask;
        },
      );
      if (!isBaselineTask) {
        baselineTasks.push(action.payload);
      }
      return {
        ...state,
        company: {
          ...state.company,
          baseline_tasks: baselineTasks,
        },
      };
    }
    case DELETE_COMPANY_BASELINE_TASK_SUCCESS: {
      const baselineTasks = (state.company.baseline_tasks || []).filter(
        (baselineTask: any) => {
          if (baselineTask.id === action.payload.id) {
            return false;
          }
          return true;
        },
      );
      return {
        ...state,
        company: {
          ...state.company,
          baseline_tasks: baselineTasks,
        },
      };
    }
    case LOGOUT_USER: {
      console.log("logout reducer");
      return {
        ...INIT_STATE,
        user: null,
        company: null,
        treeFieldNamePlural: null,
        treeFieldName: null,
      };
    }
    default:
      return { ...state };
  }
};
