/* eslint-disable @typescript-eslint/no-use-before-define */
import { all, fork, put, takeEvery, select, call } from "redux-saga/effects";

import { TumekeJSModule, EAdapterNames } from "@kernel";
import { IStorageAdapter } from "@kernel-adapters/storage";
import {
  SAVE_INTRO,
  SAVE_SOLUTION,
  SAVE_INJURY,
  ADD_INJURY,
  DELETE_INJURY,
  ADD_SOLUTION,
  DELETE_SOLUTION,
  SAVE_ROI_RESULTS,
} from "@kernel-store/actions";
import { ReduxState } from "@kernel-store/reducers";
import { NotificationManager } from "@kernel-helpers/react-notifications";
import { updateAssessmentROI } from "@kernel-helpers/DatabaseHelpers";
import { updateAssessmentROIResults } from "../videos/actions";

import { computeROI } from "./actions";

export function* startSaveIntroWatch() {
  yield takeEvery(SAVE_INTRO, computeROISagaIntro);
}

export function* startSaveInjuryWatch() {
  yield takeEvery(SAVE_INJURY, computeROISagaInjury);
}

export function* startAddInjuryWatch() {
  yield takeEvery(ADD_INJURY, computeROISagaInjury);
}

export function* startDeleteInjuryWatch() {
  yield takeEvery(DELETE_INJURY, computeROISagaInjury);
}

export function* startSaveSolutionWatch() {
  yield takeEvery(SAVE_SOLUTION, computeROISagaSolution);
}

export function* startAddSolutionWatch() {
  yield takeEvery(ADD_SOLUTION, computeROISagaSolution);
}

export function* startDeleteSolutionWatch() {
  yield takeEvery(DELETE_SOLUTION, computeROISagaSolution);
}

export function* saveROIResultWatch() {
  yield takeEvery(SAVE_ROI_RESULTS, saveROIResultHelper);
}

function* computeROISagaIntro() {
  yield put(computeROI());
  const state: ReduxState = yield select();
  const stogareAdapter = TumekeJSModule.get(
    EAdapterNames.Storage,
  ) as IStorageAdapter;
  yield call(stogareAdapter.setItem, "intro", JSON.stringify(state.roi.intro));
}

function* computeROISagaInjury() {
  yield put(computeROI());
  const state: ReduxState = yield select();
  const stogareAdapter = TumekeJSModule.get(
    EAdapterNames.Storage,
  ) as IStorageAdapter;
  yield call(
    stogareAdapter.setItem,
    "injuries",
    JSON.stringify({
      injuries: state.roi.injuries,
    }),
  );
}

function* computeROISagaSolution() {
  yield put(computeROI());
  const state: ReduxState = yield select();
  console.log("setting item");
  const stogareAdapter = TumekeJSModule.get(
    EAdapterNames.Storage,
  ) as IStorageAdapter;
  yield call(
    stogareAdapter.setItem,
    "solutions",
    JSON.stringify({
      solutions: state.roi.solutions,
    }),
  );
}

function* saveROIResultHelper({ payload }: any) {
  const { videoId, assessmentId } = payload;
  const state: ReduxState = yield select();
  try {
    const newRoiResuls: any = {
      solutions: [...state.roi.solutions],
      injuries: [...state.roi.injuries],
      results: [...state.roi.results],
      intro: { ...state.roi.intro },
    };
    yield call(updateAssessmentROI, assessmentId, newRoiResuls);
    yield put(updateAssessmentROIResults(videoId, assessmentId, newRoiResuls));
  } catch (e: any) {
    NotificationManager.warning(
      "Error",
      e.response?.data?.message || "Unknown server error. Please try again",
      3000,
      null,
      null,
      "",
    );
  }
}

export default function* rootSaga() {
  yield all([
    fork(startSaveIntroWatch),
    fork(startSaveInjuryWatch),
    fork(startAddInjuryWatch),
    fork(startDeleteInjuryWatch),
    fork(startSaveSolutionWatch),
    fork(startAddSolutionWatch),
    fork(startDeleteSolutionWatch),
    fork(saveROIResultWatch),
  ]);
}
