/* eslint-disable @typescript-eslint/no-use-before-define */
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { NotificationManager } from "@kernel-helpers/react-notifications";
import { getTempPasswordsData } from "@kernel-helpers/DatabaseHelpers";

import {
  GET_TEMP_PASSWORDS_DATA_REQUEST,
  GET_TEMP_PASSWORDS_DATA_ERROR,
  GET_TEMP_PASSWORDS_DATA_SUCCESS,
} from "@kernel-store/actions";
import {
  getTempPasswordsDataSuccess,
  getTempPasswordsDataError,
} from "./actions";

export function* watchGetTempPasswordsDataRequest() {
  yield takeEvery(
    GET_TEMP_PASSWORDS_DATA_REQUEST,
    getTempPasswordsDataRequestSaga,
  );
}

export function* watchGetTempPasswordsDataError() {
  yield takeEvery(GET_TEMP_PASSWORDS_DATA_ERROR, getTempPasswordsDataErrorSaga);
}

export function* watchGetTempPasswordsDataSuccess() {
  yield takeEvery(
    GET_TEMP_PASSWORDS_DATA_SUCCESS,
    getTempPasswordsDataSuccessSaga,
  );
}
function* getTempPasswordsDataErrorSaga() {
  yield 0;
  NotificationManager.warning(
    "Error getting data. Please take a screenshot and contact support.",
    "Error Getting Data",
    5000,
    null,
    null,
    "",
  );
}

function* getTempPasswordsDataSuccessSaga() {
  // const state: ReduxState = yield select();
  // console.log("[GET_USER_LIST] temppasswordsSuccess", state);
  // yield put(
  //   getVideoListRequest(
  //     state.authUser.user.uid,
  //     state.authUser.user.companyId,
  //     state.authUser.user.groupId,
  //   ),
  // );
}

function* getTempPasswordsDataRequestSaga({ payload }: any) {
  const { tempPasswordsByFieldParam } = payload;
  let tempPasswordsData = null;
  try {
    tempPasswordsData = (yield call(
      getTempPasswordsData,
      tempPasswordsByFieldParam,
    )) as unknown as any;
  } catch (e) {
    yield put(getTempPasswordsDataError());
    return;
  }
  yield put(getTempPasswordsDataSuccess(tempPasswordsData));
}

export default function* rootSaga() {
  yield all([
    fork(watchGetTempPasswordsDataRequest),
    fork(watchGetTempPasswordsDataError),
    fork(watchGetTempPasswordsDataSuccess),
  ]);
}
