import { AnyAction } from "@reduxjs/toolkit";
import {
  ACCEPT_USER_INTO_COMPANY_REQUEST,
  ACCEPT_USER_INTO_COMPANY_SUCCESS,
  INITIALIZE_REQUEST_LIST_REQUEST,
  INITIALIZE_REQUEST_LIST_SUCCESS,
  INITIALIZE_GROUPS_LIST_REQUEST,
  INITIALIZE_GROUPS_LIST_SUCCESS,
  INITIALIZE_GROUPS_LIST_ERROR,
  CREATE_NEW_GROUP_REQUEST,
  CREATE_NEW_GROUP_SUCCESS,
  UPDATE_GROUP_REQUEST,
  UPDATE_GROUP_REQUEST_SUCCESS,
  DELETE_GROUP_REQUEST,
  DELETE_GROUP_SUCCESS,
  MOVE_USER_TO_GROUP_REQUEST,
  MOVE_USER_TO_GROUP_SUCCESS,
  ADD_USER_TO_GROUP_REQUEST,
  ADD_USER_TO_GROUP_SUCCESS,
  DENY_USER_REQUEST,
  DENY_USER_SUCCESS,
  SET_ADMIN_PROFILE_LOADING,
  DELETE_USERS_REQUEST,
  DELETE_USERS_SUCCESS,
  DELETE_USERS_ERROR,
  DELETE_COMPANY_REQUEST,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_ERROR,
} from "../actions";

export type AdminProfileReduxState = {
  usersToAccept: any[];
  loading: boolean;
  groupsLoading: boolean;
  groupsLoaded: boolean;
  error: string;
  userGroups: any[];
};

export const INIT_STATE: AdminProfileReduxState = {
  usersToAccept: [],
  loading: false,
  groupsLoading: false,
  groupsLoaded: false,
  error: "",
  userGroups: [],
};

export default (
  state: AdminProfileReduxState = INIT_STATE,
  action: AnyAction = { type: undefined },
): AdminProfileReduxState => {
  switch (action.type) {
    case ACCEPT_USER_INTO_COMPANY_REQUEST:
      return { ...state, loading: true };
    case INITIALIZE_REQUEST_LIST_REQUEST:
      return { ...state };
    case INITIALIZE_REQUEST_LIST_SUCCESS:
      return { ...state, usersToAccept: action.payload.requestList };
    case ACCEPT_USER_INTO_COMPANY_SUCCESS:
      return {
        ...state,
        usersToAccept: state.usersToAccept.filter(
          (user) => user.id !== action.payload.userId,
        ),
      };
    case SET_ADMIN_PROFILE_LOADING:
      console.log("[ADMIN] in loading reducer");
      return {
        ...state,
        loading: action.payload.val,
      };
    case INITIALIZE_GROUPS_LIST_REQUEST:
      return { ...state, groupsLoading: true };
    case INITIALIZE_GROUPS_LIST_SUCCESS:
      return {
        ...state,
        userGroups: action.payload.userGroups,
        groupsLoading: false,
        groupsLoaded: true,
      };
    case INITIALIZE_GROUPS_LIST_ERROR:
      return { ...state, groupsLoading: false, groupsLoaded: true };
    case CREATE_NEW_GROUP_REQUEST:
      return { ...state, loading: true };
    case CREATE_NEW_GROUP_SUCCESS:
      return {
        ...state,
        userGroups: action.payload.userGroups,
        loading: false,
      };
    case UPDATE_GROUP_REQUEST:
      return { ...state, loading: true };
    case UPDATE_GROUP_REQUEST_SUCCESS:
      return {
        ...state,
        userGroups: action.payload.userGroups,
        loading: false,
      };
    case DELETE_GROUP_REQUEST:
      return { ...state, loading: true };
    case DELETE_GROUP_SUCCESS:
      return {
        ...state,
        userGroups: state.userGroups.filter(
          (group) => group.key !== action.payload.groupId,
        ),
        loading: false,
      };
    case MOVE_USER_TO_GROUP_REQUEST:
      return state;

    case MOVE_USER_TO_GROUP_SUCCESS: {
      let userDoc: any;

      const userGroupsNew = state.userGroups
        .map((groupObj) => {
          if (groupObj.key === action.payload.fromGroup) {
            return {
              ...groupObj,
              userData: groupObj.userData.filter((user: any) => {
                if (user.id === action.payload.id) {
                  userDoc = user;
                }
                return user.id !== action.payload.id;
              }),
            };
          }
          return groupObj;
        })
        .map((groupObj) => {
          if (groupObj.key === action.payload.toGroup) {
            return {
              ...groupObj,
              userData: [...groupObj.userData, userDoc],
            };
          }
          return groupObj;
        });

      return { ...state, userGroups: userGroupsNew };
    }
    case ADD_USER_TO_GROUP_REQUEST:
      return { ...state, loading: true };
    case ADD_USER_TO_GROUP_SUCCESS:
      return {
        ...state,
        userGroups: action.payload.userGroups,
        loading: false,
      };
    case DENY_USER_REQUEST:
      return { ...state, loading: true };
    case DENY_USER_SUCCESS:
      return {
        ...state,
        usersToAccept: state.usersToAccept.filter(
          (user) => user.id !== action.payload.userId,
        ),
        loading: false,
      };
    case DELETE_USERS_REQUEST: {
      return { ...state, loading: true };
    }
    case DELETE_USERS_SUCCESS: {
      const userGroupsNew = state.userGroups.map((groupObj) => {
        if (groupObj.key === action.payload.fromGroup) {
          return {
            ...groupObj,
            userData: groupObj.userData.map((user: any) =>
              action.payload.userIds.includes(user.id)
                ? { ...user, role: "none" }
                : user,
            ),
          };
        }
        return groupObj;
      });
      return { ...state, loading: false, error: "", userGroups: userGroupsNew };
    }
    case DELETE_USERS_ERROR: {
      return { ...state, loading: false, error: action.payload.error };
    }
    case DELETE_COMPANY_REQUEST: {
      return { ...state, loading: true };
    }
    case DELETE_COMPANY_SUCCESS: {
      return { ...state, loading: false, error: "" };
    }
    case DELETE_COMPANY_ERROR: {
      return { ...state, loading: false, error: action.payload.error };
    }
    default:
      return { ...state };
  }
};
