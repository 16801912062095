import { AnyAction } from "@reduxjs/toolkit";
import {
  ADD_SOLUTION,
  DELETE_SOLUTION,
  ADD_INJURY,
  DELETE_INJURY,
  SAVE_INTRO,
  SAVE_INJURY,
  SAVE_SOLUTION,
  COMPUTE_ROI,
  CLEAR_ROI_CALCULATOR,
  SAVE_ROI_RESULTS,
  RESTORE_ROI_RESULTS,
} from "@kernel-store/actions";

export const addEmptySolution = (): AnyAction => ({
  type: ADD_SOLUTION,
  payload: {},
});

export const deleteSolution = (id: string): AnyAction => ({
  type: DELETE_SOLUTION,
  payload: { id },
});

export const addEmptyInjury = (): AnyAction => ({
  type: ADD_INJURY,
  payload: {},
});

export const deleteInjury = (id: string): AnyAction => ({
  type: DELETE_INJURY,
  payload: { id },
});

export const saveIntro = (key: string, value: string): AnyAction => ({
  type: SAVE_INTRO,
  payload: { key, value },
});

export const saveInjury = (
  index: number,
  key: string,
  value: string,
): AnyAction => ({
  type: SAVE_INJURY,
  payload: { index, key, value },
});

export const saveSolution = (
  index: number,
  key: string,
  value: string,
): AnyAction => ({
  type: SAVE_SOLUTION,
  payload: { index, key, value },
});

export const computeROI = (): AnyAction => ({
  type: COMPUTE_ROI,
  payload: {},
});

export const clearCalculator = (): AnyAction => ({
  type: CLEAR_ROI_CALCULATOR,
  payload: {},
});

export const saveROIResults = (
  videoId: string,
  assessmentId: number,
): AnyAction => ({
  type: SAVE_ROI_RESULTS,
  payload: { videoId, assessmentId },
});

export const restoreROIResults = (results: any): AnyAction => ({
  type: RESTORE_ROI_RESULTS,
  payload: {
    solutions: results.solutions,
    injuries: results.injuries,
    results: results.results,
    intro: results.intro,
  },
});
