/* eslint-disable @typescript-eslint/no-use-before-define */
import moment from "moment-timezone";
import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";
import { NotificationManager } from "@kernel-helpers/react-notifications";

import {
  getDashboardData,
  getDashboardFilters,
  generateDashboardViewOnlyLink,
  getDashboardDataExternal,
  getVideosCSVRequest
} from "@kernel-helpers/DatabaseHelpers";

import {
  GET_DASHBOARD_DATA_REQUEST,
  GET_DASHBOARD_DATA_ERROR,
  GET_DASHBOARD_FILTERS_REQUEST,
  GET_DASHBOARD_FILTERS_ERROR,
  SET_DASHBOARD_FILTER_FIELD,
  SET_DASHBOARD_FILTER_FIELDS,
  GET_DASHBOARD_DATA_SUCCESS,
  CLEAR_DASHBOARD_FILTERS,
  GET_DASHBOARD_DATA_EXTERNAL,
  EXPORT_BULK_CSV_REQUEST
} from "@kernel-store/actions";

import { getVideoListRequest } from "@kernel-store/videos/actions";
import { ReduxState } from "@kernel-store/reducers";
import {
  getDashboardDataSuccess,
  getDashboardDataError,
  getDashboardFiltersSuccess,
  getDashboardFiltersError,
  setDashboardAggregationParams,
  setDashboardDataShortKey,
  setDashboardFiltersExternal,
} from "./actions";
import { loginUserSuccess } from "../auth/actions";

import { EMPTY_FILTER } from "./reducer";

export function processFilterObject(filterObject: any) {
  if (filterObject === undefined) {
    return undefined;
  }
  const filterObjectModified = JSON.parse(JSON.stringify(EMPTY_FILTER));
  filterObject.metadata_array.forEach((metadataObj: any) => {
    filterObjectModified.metadata_array.push({
      field_id: metadataObj.field_id,
      option_ids: metadataObj.values.map((x: any) => x.value),
    });
  });

  filterObjectModified.assessment_types = filterObject.assessment_types.map(
    (x: any) => x.value,
  );
  filterObjectModified.assessors = filterObject.assessors.map(
    (x: any) => x.value,
  );
  filterObjectModified.groups = filterObject.groups.map((x: any) => x.value);
  filterObjectModified.subject1_risk_levels =
    filterObject.subject1_risk_levels.map((x: any) => x.value);
  if (filterObject.start_date && typeof filterObject.start_date === "string") {
    // eslint-disable-next-line no-param-reassign
    filterObject.start_date = moment(filterObject.start_date);
  }
  if (filterObject.end_date && typeof filterObject.end_date === "string") {
    // eslint-disable-next-line no-param-reassign
    filterObject.end_date = moment(filterObject.end_date);
  }
  if (filterObject.start_date !== null && filterObject.start_date.isValid())
    filterObjectModified.start_date =
      filterObject.start_date.format("MM/DD/YYYY");
  if (filterObject.end_date !== null && filterObject.end_date.isValid())
    filterObjectModified.end_date = filterObject.end_date.format("MM/DD/YYYY");

  return filterObjectModified;
}

export function unprocessFilterObject(
  filterObject: any,
  dashboardFilters: any,
) {
  if (filterObject === undefined) {
    return undefined;
  }
  // jank deep copy
  const filterObjectModified = JSON.parse(JSON.stringify(EMPTY_FILTER));
  filterObject.metadata_array.forEach((metadataObj: any) => {
    filterObjectModified.metadata_array.push({
      field_id: metadataObj.field_id,
      values: metadataObj.option_ids.map((x: any, i: number) => {
        let metadataValue = null;
        dashboardFilters.metadataOptionList?.some((l: any) => {
          if (l[1] === metadataObj.field_id) {
            return l[3]?.some((a: any) => {
              if (a?.value === x) {
                metadataValue = a;
                return true;
              }
              return false;
            });
          }
          return false;
        });
        if (metadataValue !== null) {
          return metadataValue;
        }
        return {
          label: `Metadata ${x}`,
          value: x,
          key: i,
          parent_option_id: null,
        };
      }),
      groups: [],
    });
  });

  filterObjectModified.assessment_types = filterObject.assessment_types.map(
    (x: any, i: number) => {
      let assessmentType = null;
      dashboardFilters.assessmentTypeOptionList?.some((l: any) =>
        l?.some((assessmentTypes: any) => {
          if (Array.isArray(assessmentTypes)) {
            const isAssessment = assessmentTypes.find((a) => a.value === x);
            if (isAssessment) {
              assessmentType = isAssessment;
            }
            return !!isAssessment;
          }
          if (assessmentTypes?.value === x) {
            assessmentType = assessmentTypes;
            return true;
          }
          return false;
        }),
      );
      if (assessmentType !== null) {
        return assessmentType;
      }
      return {
        label: `Assessment type ${x}`,
        value: x,
        key: i,
      };
    },
  );
  filterObjectModified.assessors = filterObject.assessors.map(
    (x: any, i: number) => {
      let assessor = null;
      dashboardFilters.assessorsOptionList?.some((l: any) =>
        l?.some((assessors: any) => {
          if (Array.isArray(assessors)) {
            const isAssessor = assessors.find((a) => a.value === x);
            if (isAssessor) {
              assessor = isAssessor;
            }
            return isAssessor || false;
          }
          if (assessors?.value === x) {
            assessor = assessors;
            return true;
          }
          return false;
        }),
      );
      if (assessor !== null) {
        return assessor;
      }
      return {
        label: `Assessor ${x}`,
        value: x,
        key: i,
      };
    },
  );
  filterObjectModified.groups = filterObject.groups.map((x: any, i: number) => {
    let group = null;
    dashboardFilters.groupOptionList?.some((l: any) =>
      l?.some((groups: any) => {
        if (Array.isArray(groups)) {
          const isGroup = groups.find((g) => g.value === x);
          if (isGroup) {
            group = isGroup;
          }
          return isGroup || false;
        }
        if (groups?.value === x) {
          group = groups;
          return true;
        }
        return false;
      }),
    );
    if (group !== null) {
      return group;
    }
    return {
      label: `Group ${x}`,
      value: x,
      key: i,
    };
  });
  filterObjectModified.subject1_risk_levels =
    filterObject.subject1_risk_levels.map((x: any, i: number) => ({
      label: x,
      value: x,
      key: i,
    }));

  if (filterObject.start_date)
    filterObjectModified.start_date = moment(filterObject.start_date);
  if (filterObject.end_date)
    filterObjectModified.end_date = moment(filterObject.end_date);

  return filterObjectModified;
}

export function* watchGetDashboardDataRequest() {
  yield takeEvery(GET_DASHBOARD_DATA_REQUEST, getDashboardDataRequestSaga);
}

export function* watchGetDashboardDataError() {
  yield takeEvery(GET_DASHBOARD_DATA_ERROR, getDashboardDataErrorSaga);
}

export function* watchGetDashboardFiltersRequest() {
  yield takeEvery(
    GET_DASHBOARD_FILTERS_REQUEST,
    getDashboardFiltersRequestSaga,
  );
}

export function* watchgetDashboardFiltersError() {
  yield takeEvery(GET_DASHBOARD_FILTERS_ERROR, getDashboardFiltersErrorSaga);
}

export function* watchSetDashboardFilterField() {
  yield takeEvery(SET_DASHBOARD_FILTER_FIELD, getDashboardDataRequestSaga);
}

export function* watchSetDashboardFilterFields() {
  yield takeEvery(SET_DASHBOARD_FILTER_FIELDS, getDashboardDataRequestSaga);
}

export function* watchClearDashboardFilters() {
  yield takeEvery(CLEAR_DASHBOARD_FILTERS, getDashboardDataRequestSaga);
}

export function* watchGetDashboardDataSuccess() {
  yield takeEvery(GET_DASHBOARD_DATA_SUCCESS, getDashboardDataSuccessSaga);
}

export function* watchGetDashboardDataExternal() {
  yield takeEvery(GET_DASHBOARD_DATA_EXTERNAL, getDashboardDataExternalSaga);
}

export function* watchExportCSVBulkRequest() {
  yield takeEvery(EXPORT_BULK_CSV_REQUEST, exportCSVBulkRequestSaga);
}

function* getDashboardDataErrorSaga() {
  yield 0;
  NotificationManager.warning(
    "Error getting data. Please take a screenshot and contact support.",
    "Error Getting Data",
    5000,
    null,
    null,
    "",
  );
}

function* getDashboardDataSuccessSaga() {
  const state: ReduxState = yield select();
  console.log("[GET_VIDEO_LIST] dashboardSuccess");
  if (!state.authUser.user?.role) {
    return;
  }
  yield put(
    getVideoListRequest(
      state.authUser.user.uid,
      state.authUser.user.companyId,
      state.authUser.user.groupId,
    ),
  );
}

function* getDashboardFiltersErrorSaga() {
  yield 0;
  NotificationManager.warning(
    "Error getting filters. Please take a screenshot and contact support.",
    "Error Getting Filters",
    5000,
    null,
    null,
    "",
  );
}

function* getDashboardDataRequestSaga({ payload }: any) {
  const {
    assessmentsByFieldParam,
    dateGranularityParam,
    aggType,
    isExternal,
    ttl,
  } = payload;
  const state: ReduxState = yield select();
  let fieldParam = assessmentsByFieldParam;
  let granularityParam = dateGranularityParam;
  let dashboardAggType = aggType;
  let { filterObject } = state.dashboard;
  if (filterObject === undefined) {
    filterObject = (yield call(getDashboardFilters)) as unknown as any;
  }
  const filterObjectModified = processFilterObject(filterObject);
  const clientTimezone =
    Intl.DateTimeFormat().resolvedOptions().timeZone || moment.tz.guess();
  if (fieldParam === null || fieldParam === undefined || fieldParam === "") {
    fieldParam = state.dashboard.dashboardFieldParam;
  }
  if (
    granularityParam === null ||
    granularityParam === undefined ||
    granularityParam === ""
  ) {
    granularityParam = state.dashboard.dashboardGranularity;
  }
  if (
    dashboardAggType === null ||
    dashboardAggType === undefined ||
    dashboardAggType === ""
  ) {
    dashboardAggType = state.dashboard.dashboardAggType;
  }
  yield put(
    setDashboardAggregationParams(
      fieldParam,
      granularityParam,
      dashboardAggType,
    ),
  );
  if (isExternal) {
    try {
      const result = (yield call(generateDashboardViewOnlyLink, ttl, {
        filter_object: filterObjectModified,
        assessments_by_field_param: fieldParam,
        date_granularity_param: granularityParam,
        client_timezone: clientTimezone,
        agg_type: dashboardAggType,
      })) as unknown as any; // TODO(znoland): filters go here
      yield put(setDashboardDataShortKey(result.shortKey));
    } catch (e) {
      yield put(setDashboardDataShortKey(""));
    }
  } else {
    let dashboardData = null;
    try {
      dashboardData = (yield call(
        getDashboardData,
        filterObjectModified,
        fieldParam,
        granularityParam,
        clientTimezone,
        dashboardAggType,
      )) as unknown as any; // TODO(znoland): filters go here
    } catch (e) {
      yield put(getDashboardDataError());
      return;
    }
    yield put(getDashboardDataSuccess(dashboardData));
  }
}

function* getDashboardDataExternalSaga({ payload }: any) {
  const { shortKey } = payload;
  try {
    const data = (yield call(
      getDashboardDataExternal,
      shortKey,
    )) as unknown as any;
    if (data.companyData) {
      yield put(
        loginUserSuccess(
          {},
          {
            ...data.companyData,
            metadata: [{ id: 0, name: "Assessment Version", options: [] }],
          },
        ),
      );
    }
    if (data.dashboardData) {
      yield put(getDashboardDataSuccess(data.dashboardData));
    }
    if (data.dashboardFilters) {
      yield put(getDashboardFiltersSuccess(data.dashboardFilters));
    }
    if (data.filtersData) {
      yield put(
        setDashboardAggregationParams(
          data.filtersData.assessments_by_field_param,
          data.filtersData.date_granularity_param,
          data.filtersData.agg_type,
        ),
      );
      const filterObject = unprocessFilterObject(
        data.filtersData.filter_object,
        data.dashboardFilters,
      );
      yield put(setDashboardFiltersExternal(filterObject));
    }
  } catch (e) {
    yield put(getDashboardDataError());
  }
}

function* getDashboardFiltersRequestSaga() {
  // const {  } = payload;
  let dashboardFilters = null;
  try {
    dashboardFilters = (yield call(getDashboardFilters)) as unknown as any; // TODO(znoland): filters go here
  } catch (e) {
    yield put(getDashboardFiltersError());
    return;
  }

  yield put(getDashboardFiltersSuccess(dashboardFilters));
}

function* exportCSVBulkRequestSaga() {
  const state: ReduxState = yield select();
  const filterObject = processFilterObject(state.dashboard.filterObject);

  const { videosLoading } = state.videos;

  if (videosLoading) {
    return;
  }

  let pageSize = 0;
  let pageOffset = 0;
  let search = "";

  try {
    yield call(
      getVideosCSVRequest,
      filterObject,
      "",
      "",
      Intl.DateTimeFormat().resolvedOptions().timeZone || moment.tz.guess(),
      pageSize,
      pageOffset,
      search,
    )
  } catch (e) {
    console.log(e);
    return;
  }
  
}

export default function* rootSaga() {
  yield all([
    fork(watchGetDashboardDataRequest),
    fork(watchGetDashboardDataError),
    fork(watchGetDashboardFiltersRequest),
    fork(watchgetDashboardFiltersError),
    fork(watchSetDashboardFilterField),
    fork(watchSetDashboardFilterFields),
    fork(watchGetDashboardDataSuccess),
    fork(watchClearDashboardFilters),
    fork(watchGetDashboardDataExternal),
    fork(watchExportCSVBulkRequest)
  ]);
}
